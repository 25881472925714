import React, { useEffect, useState, useRef } from "react";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBDataTable, MDBIcon, MDBSelect } from "mdbreact";
import apiGatewayCall from "../../../helpers/apiGatewayCall";
import config from "../../../content_types";
import LoadingIcon from "../../../components/LoadingIcon";
import setPageTitle from "../../../helpers/setPageTitle";
import DatabaseSyncSelectQueryForm from "./DatabaseSyncSelectQueryForm";
import sortArrayOfObjectsByKey from "../../../helpers/sortArrayOfObjectsByKey";
import DatabaseSyncConfirmation from "./DatabaseSyncConfirmation";
import DatabaseSyncStatus from "./DatabaseSyncStatus";
import dummyData from "./dummyUserData";
import DatabaseSyncUninvitedUsers from "./DatabaseSyncUninvitedUsers";

const DatabaseSyncHome = () => {
  setPageTitle("Admin App -> User Directory sync");

  const useDummyData = false;

  const [availableQueries, setAvailableQueries] = useState(null);
  const [legacyDatabaseUsers, setLegacyDatabaseUsers] = useState(null);
  const [allRoles, setAllRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSourceQuery, setSelectedSourceQuery] = useState("");
  const [legacyDatabaseUsersRows, setLegacyDatabaseUsersRows] = useState(null);
  const [started, setStarted] = useState(false);
  const [uninvitedUsers, setUninvitedUsers] = useState(null);

  const [usersToSync, setUsersToSync] = useState(null);

  const [syncByDefault, setSyncByDefault] = useState(false);
  const [inviteByDefault, setInviteByDefault] = useState(false);

  const selectAllCheckboxHandler = (target) => {
    let { checked, id } = target;

    if (id === "syncAllCheckbox") setSyncByDefault(checked);
    if (id === "inviteAllCheckbox") setInviteByDefault(checked);

    setLegacyDatabaseUsers(() => {
      let _users = [];

      legacyDatabaseUsers.forEach((user) => {
        if (id === "syncAllCheckbox") user.includeInSync = checked;
        if (id === "inviteAllCheckbox") user.sendInviteEmail = checked;
        _users.push(user);
      });
      setLegacyDatabaseUsersRows(generateTableRows(_users));
      return _users;
    });
  };

  const syncAllCheckbox = (
    <>
      <input
        className="form-check-input float-right"
        type="checkbox"
        value=""
        id="syncAllCheckbox"
        onClick={(e) => selectAllCheckboxHandler(e.target)}
      ></input>
      <label className="form-check-label float-right pr-1" htmlFor="syncAllCheckbox">
        Sync All
      </label>
    </>
  );

  const inviteAllCheckbox = (
    <>
      <input
        className="form-check-input float-right"
        type="checkbox"
        value=""
        id="inviteAllCheckbox"
        onClick={(e) => selectAllCheckboxHandler(e.target)}
      ></input>
      <label className="form-check-label float-right pr-4" htmlFor="inviteAllCheckbox">
        Invite All
      </label>
    </>
  );

  useEffect(() => {
    getAvailableQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupDummyData = () => {
    setLegacyDatabaseUsers(dummyData.users);
    setLegacyDatabaseUsersRows(generateTableRows(dummyData.users));
  };

  const allRolesRef = useRef(allRoles);
  useEffect(() => {
    allRolesRef.current = allRoles;
  }, [allRoles]);

  const getAvailableQueries = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/database-sync/", "get").then((response) => {
      setAvailableQueries(response.availableQueries);
      setUninvitedUsers(response.uninvitedUsers);
      setAllRoles(response.allRoles);
      setLoading(false);
    });
    if (useDummyData) {
      setupDummyData();
    }
  };

  const onSelectQueryFormSubmit = async (e = null) => {
    if (e) e.preventDefault();
    if (selectedSourceQuery !== "") {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/user-management/database-sync/fetch-user-data", "post", {
        body: { query: selectedSourceQuery },
      }).then((response) => {
        setLoading(false);
        setLegacyDatabaseUsers(() => {
          let _users = [];
          response.users.forEach((user) => {
            syncByDefault ? (user.includeInSync = true) : (user.includeInSync = false);
            inviteByDefault ? (user.sendInviteEmail = true) : (user.sendInviteEmail = false);
            _users.push(user);
          });
          setLegacyDatabaseUsersRows(generateTableRows(_users));
          return _users;
        });
      });
    }
  };

  const generateTableRows = (users) => {
    let _data = {
      columns: [
        { label: "empId", field: "userId" },
        { label: "Name", field: "fullName" },
        { label: "Email address", field: "email" },
        { label: "In user directory", field: "isExistingUserDirectoryUser", sort: "disabled" },
        { label: "Role", field: "role", sort: "disabled" },
        { label: "Send email", field: "sendInviteEmail", sort: "disabled" },
        { label: "Sync", field: "includeInSync", sort: "disabled" },
      ],
      rows: [],
    };
    // _data.rows.push({
    //   userId: "",
    //   fullName: "",
    //   email: "",
    //   isExistingUserDirectoryUser: "",
    //   role: "",
    //   sendInviteEmail: (
    //     <div className="custom-control custom-checkbox">
    //       <input
    //         type="checkbox"
    //         className="custom-control-input"
    //         onChange={(e) => onSendInviteAllChange(e)}
    //         id="send-invite-all-checkbox"
    //         defaultChecked={inviteByDefault}
    //       />
    //       <label className="custom-control-label" htmlFor="send-invite-all-checkbox"></label>
    //     </div>
    //   ),
    //   includeInSync: (
    //     <div className="custom-control custom-checkbox">
    //       <input
    //         type="checkbox"
    //         className="custom-control-input"
    //         onChange={(e) => onIncludeInSyncAllChange(e)}
    //         id="include-in-sync-all-checkbox"
    //         defaultChecked={syncByDefault}
    //       />
    //       <label className="custom-control-label" htmlFor="include-in-sync-all-checkbox"></label>
    //     </div>
    //   ),
    // });
    users.forEach((user) => {
      const userId = user.EmployeeId_legacy_emp_id;
      const includeInSyncId = "input-include-in-sync-" + userId;
      const sendInviteEmailId = "input-send-invite-email-" + userId;

      let [isExistingUserDirectoryUser] = [user.t1ud_uuid !== "" ? "check" : "times"];

      let rolesOptions = [];
      allRolesRef.current.forEach((allRole) => {
        let roleOption = { text: allRole.label, value: allRole.uuid };
        if (user.defaultRoles.includes(allRole.uuid)) {
          roleOption.checked = true;
        }
        rolesOptions.push(roleOption);
      });

      _data.rows.push({
        userId: userId,
        fullName: user.fullName,
        email: user.email,
        isExistingUserDirectoryUser: <MDBIcon icon={isExistingUserDirectoryUser} />,
        role: (
          <MDBSelect
            multiple
            options={rolesOptions}
            getValue={(e) => onUserChange(e, userId, "role")}
            className="my-0"
          />
        ),
        includeInSync: (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={includeInSyncId}
              onChange={(e) => onUserChange(e, userId, "includeInSync")}
              value="includeInSync"
              checked={user.includeInSync}
            />
            <label className="custom-control-label" htmlFor={includeInSyncId}></label>
          </div>
        ),
        sendInviteEmail: (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={sendInviteEmailId}
              onChange={(e) => onUserChange(e, userId, "sendInviteEmail")}
              value="sendInviteEmail"
              checked={user.sendInviteEmail}
            />
            <label className="custom-control-label" htmlFor={sendInviteEmailId}></label>
          </div>
        ),
      });
    });
    _data.rows = sortArrayOfObjectsByKey(_data.rows, "userId");
    return _data;
  };

  // const onSendInviteAllChange = (e) => {
  //   setLegacyDatabaseUsers((oldLegacyDatabaseUsers) => {
  //     let _users = [];
  //     oldLegacyDatabaseUsers.forEach((user) => {
  //       user.sendInvite = e.target.checked;
  //       _users.push(user);
  //     });
  //     setLegacyDatabaseUsersRows(generateTableRows(_users))
  //     return _users;
  //   });
  // };
  // const onIncludeInSyncAllChange = (e) => {
  //   setLegacyDatabaseUsers((oldLegacyDatabaseUsers) => {
  //     let _users = [];
  //     oldLegacyDatabaseUsers.forEach((user) => {
  //       user.includeInSync = e.target.checked;
  //       _users.push(user);
  //     });
  //     setLegacyDatabaseUsersRows(generateTableRows(_users))
  //     return _users;
  //   });
  // };

  const onUserChange = (e, userId, field = null) => {
    if (field === "role") {
      setLegacyDatabaseUsers((oldLegacyDatabaseUsers) => {
        let _users = [];
        oldLegacyDatabaseUsers.forEach((user) => {
          if (user.userId === userId) {
            user.roles = e;
            user.roleSetManually = true;
          }
          _users.push(user);
        });
        return _users;
      });
    } else {
      if (e.target.type === "select-one") {
        const inputValue = e.target.value;
        const inputName = e.target.name;
        if (inputName === "select-role") {
          setLegacyDatabaseUsers((oldLegacyDatabaseUsers) => {
            let _users = [];
            oldLegacyDatabaseUsers.forEach((user) => {
              if (user.userId === userId) {
                user.roles = [];
                user.roles.push(inputValue);
                user.roleSetManually = true;
              }
              _users.push(user);
            });
            return _users;
          });
        }
      }
      if (e.target.type === "checkbox") {
        const checked = e.target.checked;
        if (e.target.value === "includeInSync") {
          setLegacyDatabaseUsers((oldLegacyDatabaseUsers) => {
            let _users = [];
            oldLegacyDatabaseUsers.forEach((user) => {
              if (user.EmployeeId_legacy_emp_id === userId) {
                user.includeInSync = checked;
              }
              _users.push(user);
            });
            setLegacyDatabaseUsersRows(generateTableRows(_users));
            return _users;
          });
        }

        if (e.target.value === "sendInviteEmail") {
          setLegacyDatabaseUsers((oldLegacyDatabaseUsers) => {
            let _users = [];
            oldLegacyDatabaseUsers.forEach((user) => {
              if (user.EmployeeId_legacy_emp_id === userId) {
                user.sendInviteEmail = checked;
              }
              _users.push(user);
            });
            setLegacyDatabaseUsersRows(generateTableRows(_users));
            return _users;
          });
        }
      }
    }
  };

  return (
    <div>
      <h1 className="page-title">
        Admin App <MDBIcon icon="arrow-right" size="sm" /> User Directory sync
      </h1>

      {availableQueries && (
        <DatabaseSyncSelectQueryForm
          availableQueries={availableQueries}
          onSelectQueryFormSubmit={onSelectQueryFormSubmit}
          setSelectedSourceQuery={setSelectedSourceQuery}
        />
      )}

      {legacyDatabaseUsersRows && allRolesRef.current.length && (
        <MDBCard>
          <MDBCardHeader>
            Values from Admin App {syncAllCheckbox} {inviteAllCheckbox}
          </MDBCardHeader>
          <MDBCardBody>
            <MDBDataTable
              displayEntries={false}
              striped
              searching={false}
              noBottomColumns
              data={legacyDatabaseUsersRows}
              info={false}
              paging={false}
              className="user-sync-users-table"
            />
          </MDBCardBody>
        </MDBCard>
      )}

      {legacyDatabaseUsers && (
        <DatabaseSyncConfirmation
          legacyDatabaseUsers={legacyDatabaseUsers}
          setLegacyDatabaseUsers={setLegacyDatabaseUsers}
          setStarted={setStarted}
          setLegacyDatabaseUsersRows={setLegacyDatabaseUsersRows}
          generateTableRows={generateTableRows}
          usersToSync={usersToSync}
          setUsersToSync={setUsersToSync}
        />
      )}

      {started && <DatabaseSyncStatus usersToSync={usersToSync} />}

      {uninvitedUsers && <DatabaseSyncUninvitedUsers users={uninvitedUsers} />}

      {loading && <LoadingIcon />}
    </div>
  );
};

export default DatabaseSyncHome;

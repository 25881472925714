import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import React from "react";

const MediaPulseSyncStatus = ({ responses, complete }) => {
  let title = "Sync in progress";
  if (complete) title = "Sync complete";
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>{title}</MDBCardTitle>
        <ul className="list-unstyled">
          {responses.map((response) => {
            console.log(response);
            let returnContent;
            if ("mpResponse" in response) {
              let responseText =
                response.mpResponse.user.email +
                " was added with the MediaPulse resource code " +
                response.mpResponse.user.mpResourceCode +
                " and added to the default Scheduling Group " +
                response.mpResponse.user.defaultSchedulingGroup +
                ".";
              if ("legacyAgentHash" in response.mpResponse.user) {
                responseText = responseText + " Legacy agent hash: " + response.mpResponse.user.legacyAgentHash + ".";
              } else {
                responseText = responseText + " No legacy agent hash was found for this user.";
              }
              returnContent = <li key={response.mpResponse.user.mpResourceCode}>{responseText}</li>;
            } else {
              let responseText = response.user.email + " was updated in MediaPulse.";
              returnContent = <li key={response.user.uuid}>{responseText}</li>;
            }
            return returnContent;
          })}
        </ul>
        <p>{responses.length} users processed.</p>
      </MDBCardBody>
    </MDBCard>
  );
};

export default MediaPulseSyncStatus;

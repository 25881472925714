import React, { useState, useEffect } from "react";
import moment from "moment";
import { MDBBtn, MDBCol, MDBDataTable, MDBIcon, MDBRow } from "mdbreact";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import LoadingIcon from "../../components/LoadingIcon";
import setPageTitle from "../../helpers/setPageTitle";
import titleCase from "../../helpers/titleCase";

const ConfigurationSetsHome = () => {
  setPageTitle("Configuration sets");

  const [configurationSets, setConfigurationSets] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getConfigurationSets();
  }, []);

  const getConfigurationSets = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/configuration-sets", "get")
      .then((response) => {
        setLoading(false);

        // This is to test pagination - basically loop over response.configurationSets over and over until it reaches a ridiculous number of entries
        // setConfigurationSets(() => {
        //   let _configurationSets = [];
        //   let count = 0;
        //   while (count < 200) {
        //     _configurationSets = _configurationSets.concat(response.configurationSets);
        //     count++;
        //   }
        //   return _configurationSets;
        // });

        setConfigurationSets(response.configurationSets);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  let tableData = {
    columns: [
      { label: "Name", field: "label" },
      { label: "GUID", field: "uuid" },
      { label: "Created", field: "createDateTime" },
      { label: "Status", field: "status" },
      // { label: "", field: "actions", sort: "disabled" },
    ],
    rows: [],
  };
  configurationSets &&
    configurationSets.forEach((configurationSet) => {
      let _row = { ...configurationSet };

      _row.clickEvent = (e) => {
        window.open(_row.url, e.ctrlKey ? "_blank" : "_self");
      };

      if (_row.status !== undefined) _row.status = titleCase(_row.status);
      if (_row.createDateTime !== undefined)
        _row.createDateTime = moment(_row.createDateTime).format("YYYY-MM-DD HH:mm:ss");
      tableData.rows.push(_row);
    });

  const newButton = (
    <MDBBtn tag="a" href="/configuration-sets/new" className="ml-0 mb-4">
      New configuration set
    </MDBBtn>
  );

  return (
    <article className="page page-configurationSets">
      <MDBRow>
        <MDBCol size="11">
          <h1 className="page-title">Configuration sets</h1>
        </MDBCol>
        <MDBCol size="1">
          <MDBIcon
            icon="sync"
            spin={loading}
            onClick={(e) => getConfigurationSets()}
            className="clickable float-right"
            size="1x"
          />
        </MDBCol>
      </MDBRow>
      {newButton}
      {configurationSets ? (
        <section className="section configurationSet-listing">
          <MDBDataTable
            data={tableData}
            hover
            noBottomColumns
            displayEntries={false}
            paging={true}
            barReverse
            noRecordsFoundLabel="No configuration sets found."
            className="clickable"
          />
        </section>
      ) : (
        <p>Loading configuration sets...</p>
      )}
      {newButton}
      {loading && <LoadingIcon />}
    </article>
  );
};

export default ConfigurationSetsHome;

import React, { useState } from "react";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBDataTable,
} from "mdbreact";
import showNotification from "../../helpers/showNotification";

const WorkflowComponentPrototypesNewModal = ({ saveNewRecord }) => {
  const [newRecordModalOpen, setNewRecordModalOpen] = useState(false);
  const [newRecord, setNewRecord] = useState({ name: "", description: "", stateMachineARN: "", inputs: {} });
  const onNewRecordClick = () => {
    toggleNewRecordModal();
  };
  const toggleNewRecordModal = () => {
    setNewRecordModalOpen(!newRecordModalOpen);
  };
  const onNewRecordSubmit = (e) => {
    e.preventDefault();
    const validateNewRecord = (newRecord) => {
      if (newRecord.name !== "" && newRecord.inputs) {
        return true;
      } else {
        return false;
      }
    };

    if (validateNewRecord(newRecord)) {
      saveNewRecord(newRecord);
      toggleNewRecordModal();
    } else {
      showNotification("Error", "Please make sure you have entered a name and at least one input", "warning");
    }
  };

  const saveNewInput = (e) => {
    e.preventDefault();
    const newInputName = document.getElementById("new-input-name").value;
    const newInputValue = document.getElementById("new-input-value").value;
    if (newInputName !== "") {
      let newNewRecord = Object.assign({}, newRecord);
      newNewRecord.inputs[newInputName] = { value: newInputValue };
      setNewRecord(newNewRecord);
      document.getElementById("new-input-name").value = "";
      document.getElementById("new-input-value").value = "";
    }
  };

  const deleteInput = (inputName) => {
    let newNewRecord = Object.assign({}, newRecord);
    delete newNewRecord.inputs[inputName];
    setNewRecord(newNewRecord);
  };

  let newRecordInputs;
  if (newRecord.inputs) {
    let newRecordInputsData = {
      columns: [
        { label: "Name", field: "name" },
        { label: "Value", field: "value" },
        { label: "", field: "actions" },
      ],
      rows: [],
    };
    Object.keys(newRecord.inputs).forEach((inputName) => {
      newRecordInputsData.rows.push({
        name: inputName,
        value: newRecord.inputs[inputName].value,
        actions: <MDBIcon icon="times" className="clickable float-right" onClick={(e) => deleteInput(inputName)} />,
      });
    });
    newRecordInputs = (
      <MDBDataTable
        data={newRecordInputsData}
        displayEntries={false}
        info={false}
        noBottomColumns={true}
        paging={false}
        searching={false}
        sortable={false}
        noRecordsFoundLabel="Please add at least one input"
        hover
      />
    );
  }

  return (
    <>
      <MDBBtn color="primary" onClick={(e) => onNewRecordClick()}>
        <MDBIcon icon="plus-circle" className="mr-2" /> Add new
      </MDBBtn>
      <MDBModal isOpen={newRecordModalOpen} toggle={toggleNewRecordModal}>
        <MDBModalHeader>New component prototype</MDBModalHeader>
        <form onSubmit={(e) => onNewRecordSubmit(e)}>
          <MDBModalBody>
            <MDBInput
              label="Name"
              valueDefault=""
              getValue={(e) =>
                setNewRecord((oldNewRecord) => {
                  oldNewRecord.name = e;
                  return oldNewRecord;
                })
              }
            />
            <MDBInput
              label="Description"
              valueDefault=""
              getValue={(e) =>
                setNewRecord((oldNewRecord) => {
                  oldNewRecord.description = e;
                  return oldNewRecord;
                })
              }
            />
            <MDBInput
              label="State machine ARN"
              valueDefault=""
              getValue={(e) =>
                setNewRecord((oldNewRecord) => {
                  oldNewRecord.stateMachineARN = e;
                  return oldNewRecord;
                })
              }
            />
            <fieldset>
              <legend>Inputs</legend>
              <form onSubmit={(e) => saveNewInput(e)}>
                <MDBRow>
                  <MDBCol size="4">
                    <MDBInput label="Input name" id="new-input-name" valueDefault="" />
                  </MDBCol>
                  <MDBCol size="5">
                    <MDBInput label="Input default value" id="new-input-value" valueDefault="" />
                  </MDBCol>
                  <MDBCol size="3">
                    <MDBBtn type="submit" onClick={(e) => saveNewInput(e)} className="mb-0">
                      Add
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </form>
              {newRecordInputs}
            </fieldset>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              onClick={(e) => {
                setNewRecord({});
                toggleNewRecordModal();
              }}
              color="secondary"
            >
              Cancel
            </MDBBtn>
            <MDBBtn type="submit" onClick={(e) => onNewRecordSubmit(e)}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </>
  );
};

export default WorkflowComponentPrototypesNewModal;

import React, { useEffect, useState, Fragment } from "react";
import { Auth, API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import config from "../../content_types";
import { MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBSelect, DatePicker } from "mdbreact";
import LoadingIcon from "../../components/LoadingIcon";
import SearchResults from "./SearchResults";
import setPageTitle from "../../helpers/setPageTitle";
import showNotification from "../../helpers/showNotification";
import moment from "moment";
import queryString from "query-string";

const ProductionEmailTracking = ({ match }) => {
  setPageTitle("Delivery tracking");

  let _searchCriteriaField = "";
  let _searchCriteriaValue = "";
  let query = useLocation();
  query = queryString.parse(query.search);
  if (query.searchCriteriaField !== undefined) _searchCriteriaField = query.searchCriteriaField;
  if (query.searchCriteriaValue !== undefined) {
    _searchCriteriaValue = query.searchCriteriaValue;
    setPageTitle("Delivery tracking results for " + _searchCriteriaValue);
  }

  const [loadingContent, setLoadingContent] = useState(null);

  const [searchCriteriaFields, setSearchCriteriaFields] = useState([]);
  const [searchCriteriaField, setSearchCriteriaField] = useState(_searchCriteriaField);
  const [searchCriteriaValue, setSearchCriteriaValue] = useState(_searchCriteriaValue);

  const [searchFromDate, setSearchFromDate] = useState(null);
  const [searchToDate, setSearchToDate] = useState(null);

  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsField, setSearchResultsField] = useState(null);
  const [searchResultsValue, setSearchResultsValue] = useState(null);

  useEffect(() => {
    generateSearchCriteriaField();
    if (_searchCriteriaValue !== '') fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateSearchCriteriaField = () => {
    let fieldsToAdd = [];
    const fields = [
      {
        value: "JobItemId",
        text: "Job Item ID",
        ref: "JobItemId",
      },
      {
        value: "JobId",
        text: "Job ID",
        ref: "JobId",
      },
      {
        value: "email_address",
        text: "Recipient email address",
      },
      {
        value: "hermes2_hash",
        text: "Hermes 2 hash",
      },
    ];
    fields.forEach((field) => {
      let fieldToAdd = field;
      if (_searchCriteriaField === field.value) {
        fieldToAdd.checked = true;
      }
      fieldsToAdd.push(fieldToAdd);
    });
    setSearchCriteriaFields(fieldsToAdd);
  };

  const fetchData = async () => {
    setLoadingContent(<LoadingIcon />);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
      },
    };

    const getJobItemIdResults = async (apiUrl) => {
      await API.get(config.api_name, apiUrl, requestOptions).then((response) => {
        if (response.statusCode === 202) {
          getJobItemIdResults(apiUrl);
        } else if (response.statusCode === 200 || response.statusCode === 404) {
          setSearchResultsField(searchCriteriaField);
          setSearchResultsValue(searchCriteriaValue);
          setSearchResults(response);
          setLoadingContent(null);
        }
      });
    };

    if (searchCriteriaField === "JobItemId") {
      if (validateJobItemIdForm() === true) {
        let apiUrl = "/production-email-monitoring/job-item-id?job_item_id=" + searchCriteriaValue;
        await API.get(config.api_name, apiUrl, requestOptions).then(async (response) => {
          let apiUrl = "/production-email-monitoring/job-item-id-results?inputInstanceId=" + response.inputInstanceId;
          getJobItemIdResults(apiUrl);
        });
      } else {
        setLoadingContent(null);
        showNotification("", "Please make sure you've entered a valid job item ID.", "warning");
      }
    } else if (searchCriteriaField === "JobId") {
      if (validateJobItemIdForm() === true) {
        let apiUrl = "/production-email-monitoring/job-id?job_id=" + searchCriteriaValue;
        await API.get(config.api_name, apiUrl, requestOptions).then(async (response) => {
          let apiUrl = "/production-email-monitoring/job-id-results?inputInstanceId=" + response.inputInstanceId;
          getJobItemIdResults(apiUrl);
        });
      } else {
        setLoadingContent(null);
        showNotification("", "Please make sure you've entered a valid job item ID.", "warning");
      }
    } else {
      let apiUrl = "/production-email-monitoring?" + searchCriteriaField + "=" + searchCriteriaValue;
      if (searchFromDate !== null) {
        apiUrl = apiUrl + "&start_timestamp=" + Math.floor(moment(searchFromDate, "x").startOf("day") / 1000);
      }
      if (searchToDate) {
        apiUrl = apiUrl + "&end_timestamp=" + Math.floor(moment(searchToDate, "x").endOf("day") / 1000);
      }
      await API.get(config.api_name, apiUrl, requestOptions).then((response) => {
        setSearchResultsField(searchCriteriaField);
        setSearchResultsValue(searchCriteriaValue);
        setSearchResults(response);
        setLoadingContent(null);
      });
    }
  };

  const onSearchFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === true) {
      fetchData();
    } else {
      showNotification("", "Please enter something to search.", "warning");
    }
  };

  const validateForm = () => {
    if (searchCriteriaValue !== "") {
      return true;
    } else {
      return false;
    }
  };

  const validateJobItemIdForm = () => {
    if (searchCriteriaValue !== "" && /^-{0,1}\d+$/.test(searchCriteriaValue)) {
      return true;
    } else {
      return false;
    }
  };

  const onSearchCriteriaFieldChange = (e) => {
    setSearchCriteriaValue("");
    setSearchCriteriaField(e);
  };

  const searchForm = (
    <form onSubmit={(e) => onSearchFormSubmit(e)}>
      <MDBRow>
        <MDBCol>
          <MDBSelect
            label="Pick a field to search"
            options={searchCriteriaFields}
            getValue={(e) => onSearchCriteriaFieldChange(e[0])}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            label="Search term"
            onChange={(e) => setSearchCriteriaValue(e.target.value)}
            valueDefault={searchCriteriaValue}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <DatePicker
            className="datepicker-container"
            clearable={true}
            id="search-from-date"
            getValue={(e) => setSearchFromDate(e)}
            autoOk={true}
            emptyLabel="From date"
            valueDefault={null}
          />
        </MDBCol>
        <MDBCol>
          <DatePicker
            className="datepicker-container"
            clearable={true}
            id="search-to-date"
            getValue={(e) => setSearchToDate(e)}
            autoOk={true}
            emptyLabel="To date"
            valueDefault={null}
          />
        </MDBCol>
      </MDBRow>
      <MDBBtn type="submit" onClick={(e) => onSearchFormSubmit(e)}>
        Go
      </MDBBtn>
    </form>
  );

  return (
    <Fragment>
      {loadingContent}
      <h1 className="page-title">Delivery tracking</h1>
      <MDBCard className="mb-4">
        <MDBCardBody>{searchForm}</MDBCardBody>
      </MDBCard>
      <SearchResults
        results={searchResults}
        searchResultsField={searchResultsField}
        searchResultsValue={searchResultsValue}
      />
    </Fragment>
  );
};

export default ProductionEmailTracking;

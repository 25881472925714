import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import React from "react";

const ConfigurationSetPreview = ({ configurationSet }) => {
  let apiUrl;
  "uuid" in configurationSet
    ? (apiUrl = "/" + configurationSet.schema.label.replaceAll(" ", "") + "/" + configurationSet.uuid)
    : (apiUrl = "");
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag="h2" className="h4">
          API preview
        </MDBCardTitle>
        <pre>{apiUrl}</pre>
        {Object.keys(configurationSet.values).length !== 0 && (
          <pre>{JSON.stringify(configurationSet.values, null, 2)}</pre>
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConfigurationSetPreview;

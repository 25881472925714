import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBSelect
} from "mdbreact";
import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";

const WorkflowLocationsNewModal = ({
  newModalOpen,
  triggerNewModal,
  newModalFields,
  canCreate,
  fetchData
}) => {
  useEffect(() => {
    buildFields();
    // eslint-disable-next-line
  }, [newModalFields]);

  const [fieldObjects, setFieldObjects] = useState([]);
  const [formValues, setFormValues] = useState({});

  const handleFormChange = (fieldName, e) => {
    let newFormValues = Object.assign(formValues, formValues);
    newFormValues[fieldName] = e;
    setFormValues(newFormValues);
  };

  const validateForm = () => {
    let requiredFields = [];
    let invalidFields = [];
    // eslint-disable-next-line
    for (const field in newModalFields) {
      if (newModalFields.hasOwnProperty(field)) {
        if (newModalFields[field].required === true) {
          requiredFields.push({
            field: newModalFields[field].field,
            label: newModalFields[field].label
          });
        }
      }
    }
    requiredFields.forEach(requiredField => {
      if (formValues[requiredField.field] === "") {
        invalidFields.push(requiredField.label);
      }
    });

    if (invalidFields.length === 0) {
      return true;
    } else {
      let invalidFieldsString = invalidFields.join(", ");
      showNotification(
        "Error",
        "Please make sure the following fields are completed: " +
          invalidFieldsString,
        "warning"
      );
    }
  };

  const formatFormValues = () => {
    let formattedFormValues = Object.assign(formValues, formValues);

    let contentValue = "{";
    contentValue =
      contentValue + '"path":"' + formValues.input_content_path + '"';
    contentValue = contentValue + ",";
    contentValue =
      contentValue + '"type":"' + formValues.input_content_type + '"';
    contentValue = contentValue + ",";
    contentValue =
      contentValue + '"region":"' + formValues.input_content_region + '"';
    contentValue = contentValue + ",";
    contentValue =
      contentValue + '"direction":"' + formValues.input_content_direction + '"';
    contentValue = contentValue + "}";

    formattedFormValues.content = contentValue;

    [
      "input_content_path",
      "input_content_type",
      "input_content_region",
      "input_content_direction"
    ].forEach(e => delete formattedFormValues[e]);

    return formattedFormValues;
  };
  const handleFormSubmit = async e => {
    e.preventDefault();

    if (validateForm() === true) {
      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
          user_name: jwt.accessToken.payload.username
        },
        body: formatFormValues()
      };
      await API.put(
        config.api_name,
        config.workflow_locations.create.api_url,
        requestOptions
      ).then(response => {
        if (response.statusCode === 201) {
          afterSuccessfulSubmit();
        } else {
          alert("Not authorised!");
          window.location.href = "/";
        }
      });
    }
  };
  const afterSuccessfulSubmit = () => {
    triggerNewModal();
    fetchData();
  };

  const buildContentDropdowns = () => {
    let contentDropdowns = [];
    const contentTypeValues = [
      {
        text: "None",
        value: "",
        checked: true
      },
      {
        text: "AWS S3 bucket",
        value: "s3bucket"
      },
      {
        text: "UNC",
        value: "unc"
      }
    ];
    handleFormChange("input_content_type", contentTypeValues[0].value);
    contentDropdowns.push(
      <div key="input_content_type">
        <MDBSelect
          options={contentTypeValues}
          label="Type"
          className="input-select"
          getValue={e => handleFormChange("input_content_type", e[0], false)}
          required={true}
        />
      </div>
    );
    handleFormChange("input_content_path", "");
    contentDropdowns.push(
      <div key="input_content_path">
        <MDBInput
          type="text"
          id="input_content_path"
          label="Path"
          valueDefault=""
          onChange={e =>
            handleFormChange("input_content_path", e.target.value, false)
          }
          className="input-text"
          required={true}
        />
      </div>
    );
    const contentRegionValues = [
      { text: "n/a", value: "", checked: true },
      { text: "Europe (London)", value: "eu-west-2" },
      { text: "US East (Ohio)", value: "us-east-2" },
      { text: "US East (N. Virginia)", value: "us-east-1" },
      { text: "US West (N. California)", value: "us-west-1" },
      { text: "US West (Oregon)", value: "us-west-2" },
      { text: "Asia Pacific (Hong Kong)***", value: "ap-east-1" },
      { text: "Asia Pacific (Mumbai)", value: "ap-south-1" },
      { text: "Asia Pacific (Osaka-Local)", value: "ap-northeast-3" },
      { text: "Asia Pacific (Seoul)", value: "ap-northeast-2" },
      { text: "Asia Pacific (Singapore)", value: "ap-southeast-1" },
      { text: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
      { text: "Asia Pacific (Tokyo)", value: "ap-northeast-1" },
      { text: "Canada (Central)", value: "ca-central-1" },
      { text: "China (Beijing)", value: "cn-north-1" },
      { text: "China (Ningxia)", value: "cn-northwest-1" },
      { text: "Europe (Frankfurt)", value: "eu-central-1" },
      { text: "Europe (Ireland)", value: "eu-west-1" },
      { text: "Europe (Paris)", value: "eu-west-3" },
      { text: "Europe (Stockholm)", value: "eu-north-1" },
      { text: "South America (São Paulo)", value: "sa-east-1" },
      { text: "Middle East (Bahrain)", value: "me-south-1" }
    ];
    handleFormChange("input_content_region", contentRegionValues[0].value);
    contentDropdowns.push(
      <div key="input_content_region">
        <MDBSelect
          options={contentRegionValues}
          label="Region"
          className="input-select"
          getValue={e => handleFormChange("input_content_region", e[0], false)}
          required={false}
        />
      </div>
    );
    const contentDirectionValues = [
      {
        text: "Source",
        value: "source",
        checked: true
      },
      {
        text: "Destination",
        value: "destination"
      }
    ];
    handleFormChange(
      "input_content_direction",
      contentDirectionValues[0].value
    );
    contentDropdowns.push(
      <div key="input_content_direction">
        <MDBSelect
          options={contentDirectionValues}
          label="Direction"
          className="input-select"
          getValue={e =>
            handleFormChange("input_content_direction", e[0], false)
          }
          required={false}
        />
      </div>
    );
    return contentDropdowns;
  };

  const buildFields = () => {
    let workflowFormItems = [];
    // eslint-disable-next-line
    for (const field in newModalFields) {
      if (newModalFields.hasOwnProperty(field)) {
        const fieldName = newModalFields[field];
        let fieldObject = "";
        const fieldRequired = fieldName.required;
        let fieldLabel = null;
        fieldRequired === true
          ? (fieldLabel = fieldName.label + " (required)")
          : (fieldLabel = fieldName.label);

        let fieldKey = fieldName.field;
        if (fieldKey === "content") {
          const contentDropdowns = buildContentDropdowns();
          workflowFormItems.push(...contentDropdowns);
        } else {
          if (fieldName.values === "") {
            handleFormChange(fieldKey, fieldName.default);
            fieldObject = (
              <div key={fieldKey}>
                <MDBInput
                  type="text"
                  id={fieldKey}
                  label={fieldLabel}
                  valueDefault={fieldName.default}
                  onChange={e =>
                    handleFormChange(
                      fieldName.field,
                      e.target.value,
                      fieldRequired
                    )
                  }
                  className="input-text"
                  required={fieldRequired}
                />
              </div>
            );
            workflowFormItems.push(fieldObject);
          } else {
            let values = [];
            const defaultValue = Object.values(fieldName.default)[0];
            handleFormChange(fieldKey, defaultValue);
            fieldName.values.forEach(option => {
              if (Object.values(option)[1] !== null) {
                const value = Object.values(option)[0];
                const text = Object.values(option)[1].toString();
                let newValue = {
                  text: text + " (" + value + ")",
                  value: value
                };
                if (defaultValue !== "0") {
                  if (value === defaultValue) {
                    newValue.checked = true;
                  }
                }
                values.push(newValue);
              }
            });
            fieldObject = (
              <div key={fieldKey}>
                <MDBSelect
                  options={values}
                  label={fieldLabel}
                  className="input-select"
                  getValue={e =>
                    handleFormChange(fieldName.field, e[0], fieldRequired)
                  }
                  required={fieldRequired}
                />
              </div>
            );
            workflowFormItems.push(fieldObject);
          }
        }
        setFieldObjects(workflowFormItems);
      }
    }
  };

  let modalContent = "";

  modalContent = (
    <MDBModal
      isOpen={newModalOpen}
      toggle={triggerNewModal}
      className="full-height"
      size="lg"
    >
      <MDBModalHeader>New location</MDBModalHeader>
      <form onSubmit={e => handleFormSubmit(e)}>
        <MDBModalBody>{fieldObjects}</MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={triggerNewModal}>
            Cancel
          </MDBBtn>
          <MDBBtn
            type="submit"
            onClick={e => handleFormSubmit(e)}
            color="primary"
          >
            Save
          </MDBBtn>
        </MDBModalFooter>
      </form>
      {/* {modalContent} */}
    </MDBModal>
  );
  return modalContent;
};

export default WorkflowLocationsNewModal;

import React, { useState } from 'react';

import config from '../config';

import {
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
} from 'mdbreact';
import Clock from 'react-live-clock';
import { useCookies } from 'react-cookie';
import LaunchUploader from './LaunchUploader';

const Header = ({ isAuthenticated, handleLogout, userFullName }) => {
  const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['canUseUploader']);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MDBNavbar color='default-color' dark expand='md' fixed='top'>
      <MDBNavbarBrand href='/'>
        <img src={process.env.PUBLIC_URL + '/512-white-no-bg.png'} alt='Take 1' height='30' />
        {config.environmentName !== '' && config.environmentName !== 'production' ? (
          <MDBBadge pill color='primary' className='ml-4 environment-badge'>
            {config.environmentName}
          </MDBBadge>
        ) : (
          ''
        )}
      </MDBNavbarBrand>
      <MDBNavbarToggler onClick={toggle} />
      <MDBCollapse id='navbarCollapse3' isOpen={isOpen} navbar>
        <MDBNavbarNav right>
          {isAuthenticated ? (
            <MDBNavItem>
              <Clock format={'YYYY-MM-DD HH:mm'} ticking={true} className='text-monospace header-clock nav-link' />
            </MDBNavItem>
          ) : (
            ' '
          )}
          {isAuthenticated && (
            <>
              {cookies.canUseUploader === 'true' && <LaunchUploader />}
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret color='transparent'>
                    <MDBIcon icon='user' className='mr-2' />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu right basic>
                    {userFullName && (
                      <MDBDropdownItem header className='text-left px-2'>
                        {userFullName}
                      </MDBDropdownItem>
                    )}
                    {/* {userFullName && <MDBDropdownItem className="no-cursor">{userFullName}</MDBDropdownItem>} */}
                    <MDBDropdownItem href='/profile/'>Profile</MDBDropdownItem>
                    <MDBDropdownItem href='#!' onClick={handleLogout}>
                      Log out
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </>
          )}
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};

export default Header;

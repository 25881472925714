import { MDBAutocomplete, MDBCard, MDBCardBody, MDBInput } from "mdbreact";
import React from "react";

const SchemaInformation = ({
  familyNames,
  subcategoryNames,
  schema,
  updateSchemaData,
  currentSchemaFamilyName,
  currentSchemaSubcategoryName,
  canEdit,
}) => {
  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        {familyNames && (
          <MDBAutocomplete
            data={familyNames}
            label="Schema family"
            id="schemaFamily"
            getValue={(e) => updateSchemaData("schemaFamily", e)}
            value={currentSchemaFamilyName}
            disabled={!canEdit}
          />
        )}
        {subcategoryNames && (
          <MDBAutocomplete
            data={subcategoryNames}
            label="Schema subcategory"
            id="schemaSubcategory"
            getValue={(e) => updateSchemaData("schemaSubcategory", e)}
            value={currentSchemaSubcategoryName}
            disabled={!canEdit}
          />
        )}
        <MDBInput
          label="Schema name"
          getValue={(e) => updateSchemaData("name", e)}
          value={schema.name}
          disabled={!canEdit}
        />

        <div className="form-group">
          <label htmlFor="schema-status">Schema status</label>
          <select
            className="form-control"
            id="schema-status"
            onChange={(e) => updateSchemaData("status", e.target.value)}
            defaultValue={schema.status}
            disabled={!canEdit}
          >
            <option value="in-development">In development</option>
            <option value="preview">Preview</option>
            <option value="production">Production</option>
          </select>
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaInformation;

import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from "mdbreact";
import AssemblyItemForm from "./AssemblyItemForm";
import DespatchItemForm from "./DespatchItemForm";
import DeleteWorkflowButton from "./DeleteWorkflowButton";

const WorkflowItemModal = ({
  workflowModalOpen,
  workflowModalToggle,
  workflowItem,
  fetchData
}) => {
  const [templateId, setTemplateId] = useState(null);
  if (workflowItem) {
    if (templateId === null && workflowItem.type !== "DI") {
      setTemplateId(
        workflowItem.main_table_data[workflowItem.template_table_id_field]
      );
    }

    const saveClick = async (requestBody = {}) => {
      const id = workflowItem.id;
      const type = workflowItem.type;

      if (type === "AI") {
        requestBody.id = id;
        requestBody.type = type;
      } else if (type === "DI") {
        requestBody.id = id;
        requestBody.type = type;
      } else {
        requestBody = {
          type: type,
          id: id,
          templateId: templateId
        };
      }

      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken
        },
        body: requestBody
      };
      await API.post("take-1-console", "/workflow-items/", requestOptions).then(
        response => {
          if (response.statusCode === 200) {
            afterSuccessfulSubmit();
          }
        }
      );
    };

    const afterSuccessfulSubmit = () => {
      workflowModalToggle();
      fetchData();
    };

    let modalContent = "";
    if (workflowItem.type === "AI") {
      modalContent = (
        <AssemblyItemForm
          workflowItem={workflowItem}
          saveClick={saveClick}
          workflowModalToggle={workflowModalToggle}
          afterSuccessfulSubmit={afterSuccessfulSubmit}
        />
      );
    } else if (workflowItem.type === "DI") {
      modalContent = (
        <DespatchItemForm
          workflowItem={workflowItem}
          saveClick={saveClick}
          workflowModalToggle={workflowModalToggle}
          afterSuccessfulSubmit={afterSuccessfulSubmit}
        />
      );
    } else {
      modalContent = (
        <>
          <MDBModalBody>
            {" "}
            <form>
              <div className="form-group">
                <label htmlFor="template">Template</label>

                <select
                  className="browser-default custom-select"
                  defaultValue={
                    workflowItem.main_table_data[
                      workflowItem.template_table_id_field
                    ]
                  }
                  onChange={e => setTemplateId(e.target.value)}
                >
                  {workflowItem.templates_data.map(template => {
                    return (
                      <option
                        key={template[workflowItem.template_table_id_field]}
                        value={template[workflowItem.template_table_id_field]}
                      >
                        {template[workflowItem.template_table_id_field]}:{" "}
                        {template.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
          </MDBModalBody>
          <MDBModalFooter>
            <DeleteWorkflowButton
              workflowItem={workflowItem}
              afterSuccessfulSubmit={afterSuccessfulSubmit}
            />
            <MDBBtn color="secondary" onClick={workflowModalToggle}>
              Cancel
            </MDBBtn>
            <MDBBtn onClick={e => saveClick()} color="primary">
              Save
            </MDBBtn>
          </MDBModalFooter>
        </>
      );
    }
    return (
      <MDBModal
        isOpen={workflowModalOpen}
        toggle={workflowModalToggle}
        size="lg"
        className="overflow-fix"
      >
        <MDBModalHeader>{workflowItem.title} </MDBModalHeader>
        {modalContent}{" "}
      </MDBModal>
    );
  } else {
    return false;
  }
};

export default WorkflowItemModal;

import React from 'react'
import QRCode from 'qrcode.react'

const QrCode = ({value, link}) => {

    const downloadQR = (elementId) => {
        const canvas = document.getElementById(elementId);
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = elementId + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);      
    }

    let QRContent = <QRCode value={value} level='M' id='qr-code' className='qr-code' />

    if(link === true){
        QRContent = <a onClick={e=>downloadQR('qr-code')} href="#!">{QRContent}</a>
    }

    return QRContent

}

export default QrCode

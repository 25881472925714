import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBDataTable, MDBInput, MDBRow } from "mdbreact";
import React from "react";
import { useState } from "react";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import showNotification from "../../helpers/showNotification";
import config from "../../content_types";
import slugify from "slugify";

const UserPropertySetupPropertyTypes = ({ propertyTypes, getProperties }) => {
  const [newPropertyTypeValue, setNewPropertyTypeValue] = useState("");
  const [newPropertyTypeLabel, setNewPropertyTypeLabel] = useState("");

  const onNewPropertyTypeSubmit = async (e) => {
    e.preventDefault();
    if (newPropertyTypeValue !== "" && newPropertyTypeLabel !== "") {
      await apiGatewayCall(config.api_name, "/user-management/property-types/", "post", {
        body: { value: newPropertyTypeValue, label: newPropertyTypeLabel },
      })
        .then((response) => {
          showNotification("Success", "Property type created", "success");
          setNewPropertyTypeValue("");
          setNewPropertyTypeLabel("");
        })
        .catch((e) => console.log(e));
      getProperties();
    } else {
      showNotification(
        "Error",
        "Please make sure you have entered a label and a slug for your new property type.",
        "warning"
      );
    }
  };

  let tableData = {
    columns: [
      { label: "Label", field: "label" },
      { label: "Slug", field: "value" },
    ],
    rows: [],
  };
  propertyTypes.forEach((propertyType) => {
    tableData.rows.push(propertyType);
  });

  return (
    <MDBCard>
      <MDBCardBody>
        <h2 className="h3">Property types</h2>
        <MDBDataTable
          data={tableData}
          hover
          noBottomColumns
          displayEntries={false}
          paging={false}
          searching={false}
          noRecordsFoundLabel="No property types available"
          small
          borderless
          sortable={false}
        />
        <MDBCard>
          <MDBCardBody>
            <h3 className="h4">Add new</h3>
            <form onSubmit={(e) => onNewPropertyTypeSubmit(e)}>
              <MDBRow>
                <MDBCol size="5">
                  <MDBInput
                    type="text"
                    getValue={(e) => {
                      const slugifiedLabel = slugify(e, { replacement: "_", lower: true });
                      setNewPropertyTypeLabel(e);
                      setNewPropertyTypeValue(slugifiedLabel);
                    }}
                    label="Label"
                    value={newPropertyTypeLabel}
                  />
                </MDBCol>
                <MDBCol size="5">
                  <MDBInput type="text" getValue={setNewPropertyTypeValue} label="Slug" value={newPropertyTypeValue} />
                </MDBCol>
                <MDBCol size="2">
                  <MDBBtn type="submit" onClick={(e) => onNewPropertyTypeSubmit(e)} className="float-right m-0">
                    Save
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCardBody>
    </MDBCard>
  );
};

export default UserPropertySetupPropertyTypes;

import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import config from "../../content_types";
import LoadingIcon from "../../components/LoadingIcon";
import WorkflowMonitoringListingTable from "./WorkflowMonitoringListingTable";
import setPageTitle from "../../helpers/setPageTitle";
import { MDBSelect, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBIcon, MDBBtn } from "mdbreact";
import querystring from "querystring";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import showNotification from "../../helpers/showNotification";

import WorkflowMonitoringSummary from "./WorkflowMonitoringSummary";

const WorkflowMonitoringListing = () => {
  setPageTitle("Workflow monitoring");
  const [loading, setLoading] = useState(false);
  const [shortenedRecords, setShortenedRecords] = useState([]);
  const [connections, setConnections] = useState([]);
  const [lastInputInstanceId, setLastInputInstanceId] = useState(null);
  const [loadingContent, setLoadingContent] = useState(null);

  const [fromDateTime, setFromDateTime] = useState('');
  const [toDateTime, setToDateTime] = useState('');
  const [connectionFilter, setConnectionFilter] = useState('');

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    if (
      (fromDateTime === '' && toDateTime === '') ||
      (fromDateTime === '' && toDateTime !== '') ||
      (fromDateTime !== '' && toDateTime === '') ||
      toDateTime > fromDateTime
    ) {
      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
        },
      };
      let apiUrl = "/workflow-instances";

      const queryString = buildQueryString();
      if (queryString !== {}) {
        apiUrl = apiUrl + "?" + queryString;
      }
      setLoading(true);
      setLoadingContent(<LoadingIcon fullHeight={false} />);
      await API.get(config.api_name, apiUrl, requestOptions).then((response) => {
        if (response.statusCode === 200) {
          const records = JSON.parse(response.body);
          if (connections.length === 0) {
            setConnections(connectionsOptions(response.connections));
          }

          setShortenedRecords(shortenRecords(records));

          if (response.last_evaluated_key === null) {
            setLastInputInstanceId(null);
          } else {
            setLastInputInstanceId(response.last_evaluated_key);
          }

          setLoading(false);
          setLoadingContent(null);
        } else {
          alert("Not authorised!");
          window.location.href = "/";
        }
      });
    } else {
      showNotification(
        "Error",
        'Please make sure your "To" date and time is after your "From" date and time.',
        "warning"
      );
    }
  };

  const buildQueryString = () => {
    let queryString = {};

    if (connectionFilter !== '' && connectionFilter !== undefined)
      queryString.connectionKey = connectionFilter.toString();

    if (fromDateTime !== '' && fromDateTime !== undefined) {
      let formattedFromDateTime = new Date(fromDateTime.getTime() - (fromDateTime.getTimezoneOffset() * 60000))
      queryString.fromDateTime = formattedFromDateTime.toISOString();
    }

    if (toDateTime !== '' && toDateTime !== undefined) {
      let formattedToDateTime = new Date(toDateTime.getTime() - (toDateTime.getTimezoneOffset() * 60000))
      queryString.toDateTime = formattedToDateTime.toISOString();
    }
    
    queryString = querystring.stringify(queryString);
    return queryString;
  };

  const connectionsOptions = (connectionsData) => {
    let newConnectionsData = [];

    newConnectionsData.push({
      text: "All connections",
      value: '',
    });

    connectionsData.forEach((connection) => {
      let newConnectionData = {
        text: connection.name + " (" + connection.key + ")",
        value: connection.key,
      };
      if (connection.key === connectionFilter) newConnectionData.checked = true;
      newConnectionsData.push(newConnectionData);
    });
    return newConnectionsData;
  };

  const shortenRecords = (records) => {
    let count = 0;
    let newShortenedRecords = [];
    const visible_fields = ["inputInstanceId", "connectionKey", "reg", "startedAt", "status"];
    if (records) {
      records.map((record) => {
        newShortenedRecords[count] = {};
        visible_fields.forEach((field) => {
          if (field === "status") {
            const statusClassName = "status-" + record[field].toLowerCase();
            newShortenedRecords[count][field] = (
              <span className={statusClassName}>{record[field].replace("_", " ").toLowerCase()}</span>
            );
          } else {
            if (typeof record[field] === "object") {
              newShortenedRecords[count][field] = JSON.stringify(record[field]);
            } else {
              newShortenedRecords[count][field] = record[field];
            }
          }
        });
        count++;
        return true;
      });
    }
    return newShortenedRecords;
  };

  const sortRecords = (records) => {
    return records.sort((a, b) => a.startedAt.localeCompare(b.startedAt)).reverse();
  };

  const onListingClick = async (e, recordId) => {
    const detailUrl = "/workflow-monitoring/instance/" + recordId;
    window.open(detailUrl, "_blank");
  };

  const submitSearchForm = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const getMoreRecords = async (startingInstanceId) => {
    if (lastInputInstanceId !== null) {
      setLoading(true);
      setLoadingContent(<LoadingIcon fullHeight={false} />);
      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
        },
      };
      const exclusiveStartingKey = encodeURIComponent(JSON.stringify(lastInputInstanceId));
      let apiUrl = "/workflow-instances?startingInstanceId=" + exclusiveStartingKey;

      const queryString = buildQueryString();
      if (queryString !== {}) {
        apiUrl = apiUrl + "&" + queryString;
      }

      await API.get(config.api_name, apiUrl, requestOptions)
        .then((response) => {
          setLoading(false);
          setLoadingContent(null);
          if (response.body !== undefined) {
            if (response.last_evaluated_key === null) {
              setLastInputInstanceId(null);
            } else {
              setLastInputInstanceId(response.last_evaluated_key);
            }
            const records = JSON.parse(response.body);
            const newShortenedRecords = shortenedRecords.concat(shortenRecords(records));
            setShortenedRecords(sortRecords(newShortenedRecords));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const reloadSearchForm = (e) => {
    e.preventDefault();
    fetchData();
  };

  let listingContent = null;

  let pageContent = null;
  if (shortenedRecords && connections) {
    listingContent = (
      <WorkflowMonitoringListingTable
        records={shortenedRecords}
        onListingClick={onListingClick}
        idField="inputInstanceId"
        getMoreRecords={getMoreRecords}
      />
    );
    pageContent = (
      <Fragment>
        <h1 className="page-title">Workflow monitoring</h1>
        <WorkflowMonitoringSummary />
        <MDBCard>
          {loadingContent}
          <MDBCardBody>
            <MDBIcon
              icon="sync"
              className="float-right clickable mb-4"
              spin={loading === true ? true : false}
              onClick={(e) => reloadSearchForm(e)}
            />
            <MDBCard>
              <MDBCardBody>
                <form onSubmit={(e) => submitSearchForm(e)} className="workflow-monitoring-search-form">
                  <MDBRow>
                    <MDBCol size="3">
                      <div className="date-from-date">
                        <DatePicker
                          selected={fromDateTime}
                          onChange={(date) => setFromDateTime(date)}
                          showTimeSelect
                          dateFormat="MMMM d, yyyy HH:mm 'UTC'"
                          placeholderText="From"
                          className="form-control form-control-sm ml-0 my-1"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol size="3">
                      <div className="date-to-date">
                        <DatePicker
                          selected={toDateTime}
                          onChange={(date) => setToDateTime(date)}
                          showTimeSelect
                          dateFormat="MMMM d, yyyy HH:mm 'UTC'"
                          placeholderText="To"
                          className="form-control form-control-sm ml-0 my-1"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol size="4">
                      <div className="connections-filter">
                        <MDBSelect
                          label="Connection"
                          options={connections}
                          getValue={(e) => setConnectionFilter(e)}
                          multiple
                        />
                      </div>
                    </MDBCol>
                    <MDBCol size="2">
                      <MDBBtn type="submit" onClick={(e) => submitSearchForm(e)}>
                        Search
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </MDBCardBody>
            </MDBCard>
            <div className="workflow-monitoring-listing">{listingContent}</div>
          </MDBCardBody>
        </MDBCard>
      </Fragment>
    );
  }
  return pageContent;
};

export default withRouter(WorkflowMonitoringListing);

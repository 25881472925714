import { MDBCard, MDBCardBody, MDBInput } from "mdbreact";
import CopyIcon from "../../../components/CopyIcon";
import moment from "moment";
import React from "react";

const ConfigurationSetMoreInformation = ({ configurationSet }) => {
  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <MDBInput disabled value={configurationSet.owner.fullName} label="Configuration set owner" />
        <div className="md-form copyable-input">
          <p className="form-control">
            <CopyIcon text={configurationSet.uuid} />
          </p>
          <label className="active disabled">Configuration set GUID</label>
        </div>
        <MDBInput
          disabled
          value={moment(configurationSet.createDateTime).format("YYYY-MM-DD HH:mm:ss")}
          label="Created"
        />
        {"modifiedDateTime" in configurationSet && (
          <MDBInput
            disabled
            value={moment(configurationSet.modifiedDateTime).format("YYYY-MM-DD HH:mm:ss")}
            label="Last modified"
          />
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConfigurationSetMoreInformation;

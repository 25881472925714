import React from "react";

const DespatchInstruction = ({
  row,
  id,
  showViewButton,
  workflowViewClick
}) => {
  let valueCount = 0;

  const rowValues = Object.values(row).map(value => {
    const valueId = "value-" + valueCount;
    valueCount++;
    return <td key={valueId}>{value}</td>;
  });

  let despatchInstruction = "";

  showViewButton
    ? (despatchInstruction = (
        <tr className="clickable" onClick={e => workflowViewClick("DI", id)}>
          {rowValues}
        </tr>
      ))
    : (despatchInstruction = <tr>{rowValues}</tr>);

  return despatchInstruction;
};

export default DespatchInstruction;

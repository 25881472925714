import React from "react";
import {
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead
} from "mdbreact";
import DespatchInstruction from "./DespatchInstruction";

const Despatch = ({
  despatchInstructions,
  despatchInstructionsFriendly,
  showViewButton,
  workflowViewClick,
  canWrite,
  showAddButton
}) => {
  // First format our data

  let fixedCount = 0;
  let fixedData = despatchInstructionsFriendly;
  const fieldsToRemove = [
    "despatch_instruction_template",
    "document_format",
    "document_margin_properties",
    "document_page_properties",
    "document_table_properties",
    "file_conversion",
    "file_name_type",
    "grouping_method",
    "platform",
    "release_condition",
    "render",
    "serviceTemplateId"
  ];

  for (var row of fixedData) {
    if (row.despatch_instruction_template) {
      fixedData[fixedCount].despatchInstructionTemplateId =
        row.despatch_instruction_template.despatchInstructionTemplateId +
        ": " +
        row.despatch_instruction_template.name;

      fixedData[fixedCount].documentFormatId =
        row.documentFormatId + ": " + row.document_format.name;

      fixedData[fixedCount].fileNameTypeId =
        row.fileNameTypeId + ": " + row.file_name_type.name;

      fixedData[fixedCount].groupingMethodId =
        row.groupingMethodId + ": " + row.grouping_method.name;

      fixedData[fixedCount].platformId =
        row.platformId + ": " + row.platform.platformName;

      fixedData[fixedCount].releaseConditionId =
        row.releaseConditionId + ": " + row.release_condition.name;

      if (row.documentMarginPropertiesOverrideId !== 0) {
        fixedData[fixedCount].documentMarginPropertiesOverrideId =
          row.documentMarginPropertiesOverrideId +
          ": " +
          row.document_margin_properties.name;
      }
      if (row.documentPagePropertiesOverrideId !== 0) {
        fixedData[fixedCount].documentPagePropertiesOverrideId =
          row.documentPagePropertiesOverrideId +
          ": " +
          row.document_page_properties.name;
      }
      if (row.documentTablePropertiesOverrideId !== 0) {
        fixedData[fixedCount].documentTablePropertiesOverrideId =
          row.documentTablePropertiesOverrideId +
          ": " +
          row.document_table_properties.name;
      }
      if (row.fileConversionLocationOverrideId !== 0) {
        fixedData[fixedCount].fileConversionLocationOverrideId =
          row.fileConversionLocationOverrideId +
          ": " +
          row.file_conversion.notes;
      }

      if (row.renderId !== 0) {
        fixedData[fixedCount].renderId = row.renderId + ": " + row.render.name;
      }

      for (var fieldToRemove of fieldsToRemove) {
        delete fixedData[fixedCount][fieldToRemove];
      }
    }

    fixedCount++;
  }

  // Make headers for our table from fixedData

  const headers = Object.keys(fixedData[0]).map(field => {
    return <th key={field}>{field}</th>;
  });

  const rows = [];
  let rowCount = 0;
  for (row of fixedData) {
    rows.push(
      <DespatchInstruction
        row={row}
        key={rowCount}
        id={
          despatchInstructionsFriendly[rowCount]
            .serviceTemplateDespatchInstructionId
        }
        showViewButton={showViewButton}
        workflowViewClick={workflowViewClick}
        canWrite={canWrite}
        showAddButton={showAddButton}
      />
    );
    rowCount++;
  }

  let despatchContent = (
    <MDBCard>
      <MDBCardBody>
        <MDBCardText tag="div">
          <MDBTable responsive>
            <MDBTableHead>
              <tr>{headers}</tr>
            </MDBTableHead>
            <MDBTableBody>{rows}</MDBTableBody>
          </MDBTable>
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
  return despatchContent;
};

export default Despatch;

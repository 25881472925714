import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBIcon
} from "mdbreact";

const CaptureInstructionTemplateDetailsModal = ({
  detailModalOpen,
  triggerDetailsModal,
  serviceTemplate,
  canWrite
}) => {
  useEffect(() => {
    updateModalContent();
    // eslint-disable-next-line
  }, [serviceTemplate]);

  const [title, setTitle] = useState("");
  const [serviceTemplateContent, setServiceTemplateContent] = useState("");
  const [editUrl, setEditUrl] = useState("");

  const updateModalContent = () => {
    if (serviceTemplate) {
      setTitle(serviceTemplate.originName);

      const editUrl =
        "/capture-instruction-templates/" +
        serviceTemplate.captureInstructionTemplateId;
      setEditUrl(editUrl);

      const mainDetails = () => {
        let ingredientSetContent = "";
        if (serviceTemplate.ingredientSetDetails) {
          let ingredientSetDetails = serviceTemplate.ingredientSetDetails;
          switch (ingredientSetDetails.editType) {
            case 1:
              ingredientSetDetails.editTypeName = "Pre";
              break;
            case 2:
              ingredientSetDetails.editTypeName = "Post";
              break;

            default:
              ingredientSetDetails.editTypeName = "Pre and Post";
              break;
          }
          ingredientSetContent = (
            <MDBCard>
              <MDBCardHeader>
                Ingredient set:{" "}
                {serviceTemplate.ingredientSetDetails.displayName}
              </MDBCardHeader>
              <MDBCardBody>
                <MDBTable small responsive hover>
                  <MDBTableHead>
                    <tr>
                      <th>ID</th>
                      <th>Structure set</th>
                      <th>Clipboard type</th>
                      <th>Style set</th>
                      <th>Subjective instruction set</th>
                      <th>Speaker role set</th>
                      <th>Footage type set</th>
                      <th>CSS override set</th>
                      <th>Clip set</th>
                      <th>Edit type</th>
                      <th>Temporary shot log data filename</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    <tr>
                      <td>{ingredientSetDetails.ingredientSetId}</td>
                      <td>
                        {ingredientSetDetails.templateStructureSetId} (
                        {ingredientSetDetails.templateStructureSetName})
                      </td>
                      <td>
                        {ingredientSetDetails.templateClipboardTypeId} (
                        {ingredientSetDetails.templateClipboardTypeName})
                      </td>
                      <td>
                        {ingredientSetDetails.styleSetId} (
                        {ingredientSetDetails.styleSetName})
                      </td>
                      <td>
                        {ingredientSetDetails.subjectiveInstructionSetId} (
                        {ingredientSetDetails.subjectiveInstructionSetName})
                      </td>
                      <td>
                        {ingredientSetDetails.templateSpeakerRoleSetId} (
                        {ingredientSetDetails.templateSpeakerRoleSetName})
                      </td>
                      <td>
                        {ingredientSetDetails.templateFootageTypeSetId} (
                        {ingredientSetDetails.templateFootageTypeSetName})
                      </td>
                      <td>
                        {ingredientSetDetails.templateCSSOverrideSetId} (
                        {ingredientSetDetails.templateCSSOverrideSetName})
                      </td>
                      <td>
                        {ingredientSetDetails.templateClipSetId} (
                        {ingredientSetDetails.templateClipSetName})
                      </td>
                      <td>
                        {ingredientSetDetails.editType} (
                        {ingredientSetDetails.editTypeName})
                      </td>
                      <td>
                        {ingredientSetDetails.temporaryShotLogDataFilename}
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
          );
        }
        let table = (
          <>
            <MDBTable small>
              <MDBTableHead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Origin</th>
                  <th>Ingredient set ID</th>
                  <th>Job skill ID</th>
                  <th>Default "from" language</th>
                  <th>Default "to" language</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr>
                  <td>{serviceTemplate.captureInstructionTemplateId}</td>
                  <td>{serviceTemplate.originName}</td>
                  <td>{serviceTemplate.configOriginId}</td>
                  <td>
                    {serviceTemplate.ingredientSetId} (
                    {serviceTemplate.ingredientSetDetails.displayName})
                  </td>
                  <td>{serviceTemplate.originJobSkillId}</td>
                  <td>{serviceTemplate.defaultFromLanguageId}</td>
                  <td>{serviceTemplate.defaultToLanguageId}</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
            {ingredientSetContent}
          </>
        );
        setServiceTemplateContent(table);
      };

      mainDetails();
    }
  };

  return (
    <MDBModal
      isOpen={detailModalOpen}
      toggle={triggerDetailsModal}
      size="fluid"
      className="listing-detail-modal"
    >
      <MDBModalHeader toggle={triggerDetailsModal} tag="h2">
        {title}
      </MDBModalHeader>
      <MDBModalBody>{serviceTemplateContent}</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={triggerDetailsModal}>
          Close
        </MDBBtn>
        {canWrite ? (
          <MDBBtn color="primary" tag={Link} to={editUrl}>
            <MDBIcon icon="edit" className="mr-2" /> Edit
          </MDBBtn>
        ) : (
          ""
        )}
      </MDBModalFooter>
    </MDBModal>
  );
};

export default CaptureInstructionTemplateDetailsModal;

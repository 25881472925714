import {
  MDBBtn,
  MDBCol,
  MDBDataTable,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React from "react";
import { useState } from "react";

const SchemasImportExisting = ({ existingSchemas, setSchema }) => {
  const [selectedExistingSchemaId, setSelectedExistingSchemaId] = useState(null);
  const [selectedExistingSchema, setSelectedExistingSchema] = useState(null);
  const [existingSchemaModalOpen, setExistingSchemaModalOpen] = useState(false);
  const [inputsToImport, setInputsToImport] = useState([]);

  const onSelectExistingSchema = (e) => {
    e.preventDefault();
    if (selectedExistingSchemaId !== "0") {
      existingSchemas.forEach((existingSchema) => {
        if (existingSchema.uuid === selectedExistingSchemaId) {
          setSelectedExistingSchema(existingSchema);
          setExistingSchemaModalOpen(true);
        }
      });
    }
  };
  const toggleExistingSchemaModal = () => {
    setExistingSchemaModalOpen(!existingSchemaModalOpen);
  };

  const importExistingSchema = () => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      inputsToImport.forEach((inputToImport) => {
        _schema.inputs.push(inputToImport);
      });
      return _schema;
    });
    setExistingSchemaModalOpen(false);
  };

  let tableData = {
    columns: [
      { label: "Label/content", field: "label" },
      { label: "Type", field: "type" },
      { label: "", field: "actions", sort: "disabled" },
    ],
    rows: [],
  };
  selectedExistingSchema &&
    selectedExistingSchema.inputs.forEach((input) => {
      let _row = { ...input };
      "label" in input.inputInstance
        ? (_row.label = input.inputInstance.label)
        : (_row.label = input.inputInstance.content);
      _row.type = input.inputTemplate.label;
      _row.actions = (
        <MDBInput
          type="checkbox"
          value={input.inputInstance.id}
          id={"checkbox-import-input-" + input.inputInstance.id}
          onChange={(e) => {
            setInputsToImport(() => {
              let _inputsToImport = [...inputsToImport];
              if (e.target.checked) _inputsToImport.push(input);
              else
                _inputsToImport = _inputsToImport.filter(
                  (inputToImport) => inputToImport.inputInstance.id !== input.inputInstance.id
                );
              return _inputsToImport;
            });
          }}
        />
      );
      tableData.rows.push(_row);
    });

  return (
    <>
      <h3 className="h4 mb-4">Import an existing schema</h3>
      <MDBRow>
        <MDBCol size="9">
          <select
            onChange={(e) => setSelectedExistingSchemaId(e.target.value)}
            className="browser-default custom-select"
          >
            <option value="0">Select an existing schema to import</option>
            {existingSchemas.map((existingSchema) => {
              return (
                <option key={existingSchema.uuid} value={existingSchema.uuid}>
                  {existingSchema.name}
                </option>
              );
            })}
          </select>
        </MDBCol>
        <MDBCol size="3">
          <MDBBtn className="float-right mt-0" onClick={(e) => onSelectExistingSchema(e)}>
            Add
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      {selectedExistingSchema && (
        <MDBModal isOpen={existingSchemaModalOpen} toggle={toggleExistingSchemaModal} size="lg">
          <MDBModalHeader>Import from {selectedExistingSchema.name}</MDBModalHeader>
          <MDBModalBody>
            <MDBDataTable
              data={tableData}
              hover
              noBottomColumns
              displayEntries={false}
              paging={false}
              striped
              searching={false}
            />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={(e) => setExistingSchemaModalOpen(false)}>
              Cancel
            </MDBBtn>
            <MDBBtn onClick={(e) => importExistingSchema(e)}>Go</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      )}
    </>
  );
};

export default SchemasImportExisting;

import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSelectInput
} from "mdbreact";
import { Auth, API } from "aws-amplify";
import LoadingIcon from "../../components/LoadingIcon";
import { Redirect, Link } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";

const ServiceTemplatesNew = ({ match }) => {
  setPageTitle("New capture instruction template");
  const [loading, setLoading] = useState(false);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [newServiceTemplateId, setNewServiceTemplateId] = useState(null);
  const [formValues, setFormValues] = useState([]);

  const [originName, setOriginName] = useState("");
  const [configOriginIds, setConfigOriginIds] = useState([]);
  const [configOriginId, setConfigOriginId] = useState("");
  const [ingredientSetIds, setIngredientSetIds] = useState([]);
  const [ingredientSetId, setIngredientSetId] = useState("");
  const [originJobSkillId, setOriginJobSkillId] = useState("");
  const [defaultFromLanguageIds, setDefaultFromLanguageIds] = useState([]);
  const [defaultFromLanguageId, setDefaultFromLanguageId] = useState("");
  const [defaultToLanguageIds, setDefaultToLanguageIds] = useState([]);
  const [defaultToLanguageId, setDefaultToLanguageId] = useState("");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    const response = await API.get(
      config.api_name,
      config.capture_instruction_templates.edit.api_url + "/2",
      requestOptions
    );
    if (response) {
      if (response.statusCode === 200) {
        setConfigOriginIds(response.template_form_values.configOriginId);
        setIngredientSetIds(response.template_form_values.ingredientSetId);
        setDefaultFromLanguageIds(
          response.template_form_values.defaultFromLanguageId
        );
        setDefaultToLanguageIds(
          response.template_form_values.defaultToLanguageId
        );

        setConfigOriginId(
          response.template_form_values.configOriginId[0].configOriginId
        );
        setIngredientSetId(
          response.template_form_values.ingredientSetId[0].ingredientSetId
        );
        setDefaultFromLanguageId(
          response.template_form_values.defaultFromLanguageId[0]
            .configLanguageId
        );
        setDefaultToLanguageId(
          response.template_form_values.defaultToLanguageId[0].configLanguageId
        );

        setFormValues(response.template_form_values);

        setLoading(false);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      },
      body: {
        originName: originName,
        configOriginId: configOriginId,
        ingredientSetId: ingredientSetId,
        originJobSkillId: originJobSkillId,
        defaultFromLanguageId: defaultFromLanguageId,
        defaultToLanguageId: defaultToLanguageId
      }
    };
    await API.put(
      "take-1-console",
      "/capture-instruction-templates/",
      requestOptions
    ).then(response => {
      if (response.statusCode === 201) {
        afterSuccessfulSubmit(response);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const afterSuccessfulSubmit = response => {
    setLoading(false);
    setNewServiceTemplateId(response.new_capture_instruction_template);
    setRedirectToEdit(true);
  };

  let editForm = "";

  if (loading) {
    editForm = <LoadingIcon />;
  } else {
    if (redirectToEdit === true) {
      const newUrl = "/capture-instruction-templates/" + newServiceTemplateId;
      editForm = <Redirect to={newUrl} />;
    } else {
      editForm = (
        <>
          <MDBRow>
            <MDBCol>
              <h1 className="page-title">New capture instruction template</h1>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol sm="12" xl="6">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <MDBInput
                    type="text"
                    value={originName}
                    id="originName"
                    label="originName"
                    required
                    onChange={e => setOriginName(e.target.value)}
                  />
                </div>

                {configOriginIds.length ? (
                  <MDBSelect
                    label="Config origin ID"
                    getValue={setConfigOriginId}
                  >
                    <MDBSelectInput selected="Config origin ID" />
                    <MDBSelectOptions>
                      {formValues.configOriginId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.configOriginId}
                            key={option.configOriginId}
                          >
                            {option.originDescription}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {ingredientSetIds.length ? (
                  <MDBSelect
                    label="Ingredient set"
                    getValue={setIngredientSetId}
                  >
                    <MDBSelectInput selected="Ingredient set" />
                    <MDBSelectOptions>
                      {formValues.ingredientSetId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.ingredientSetId}
                            key={option.ingredientSetId}
                          >
                            {option.displayName}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                <div className="form-group">
                  <MDBInput
                    type="text"
                    value={originJobSkillId}
                    id="Origin job skill ID"
                    label="Origin job skill ID"
                    required
                    onChange={e => setOriginJobSkillId(e.target.value)}
                  />
                </div>

                {defaultFromLanguageIds.length ? (
                  <MDBSelect
                    label="Default from language"
                    getValue={setDefaultFromLanguageId}
                  >
                    <MDBSelectInput selected="Default from language" />
                    <MDBSelectOptions>
                      {formValues.defaultFromLanguageId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.configLanguageId}
                            key={option.configLanguageId}
                          >
                            {option.languageDescription}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                {defaultToLanguageIds.length ? (
                  <MDBSelect
                    label="Default to language"
                    getValue={setDefaultToLanguageId}
                  >
                    <MDBSelectInput selected="Default to language" />
                    <MDBSelectOptions>
                      {formValues.defaultToLanguageId.map(option => {
                        return (
                          <MDBSelectOption
                            value={option.configLanguageId}
                            key={option.configLanguageId}
                          >
                            {option.languageDescription}
                          </MDBSelectOption>
                        );
                      })}
                    </MDBSelectOptions>
                  </MDBSelect>
                ) : (
                  ""
                )}

                <MDBBtn tag={Link} to="/capture-instruction-templates">
                  Back
                </MDBBtn>
                <MDBBtn color="primary" type="submit" className="float-right">
                  Submit
                </MDBBtn>
              </form>
            </MDBCol>
          </MDBRow>
        </>
      );
    }
  }
  return <>{editForm}</>;
};

export default ServiceTemplatesNew;

import React, { useState, useEffect } from "react";
import apiGatewayCall from "../../../helpers/apiGatewayCall";
import config from "../../../content_types";
import showNotification from "../../../helpers/showNotification";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle } from "mdbreact";
import { v4 as uuidv4 } from "uuid";

const DatabaseSyncStatus = ({ usersToSync }) => {
  const [progress, setProgress] = useState("");
  const [updates, setUpdates] = useState([]);

  let createdUsers = [];

  useEffect(() => {
    syncUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const syncUsers = async () => {
    setProgress("Running sync");
    for (let index = 0; index < usersToSync.length; index++) {
      const userToSync = usersToSync[index];

      if (userToSync.mp_LegacyAgentHash_mp_legacy_agent_hash === "") {
        addToUpdates(
          <p className="text-danger" key={uuidv4().toString()}>
            {userToSync.email} does not have a Legacgy Agent Hash, so will not be loaded.
          </p>
        );
      } else {
        userToSync.isExistingUserDirectoryUser
          ? updateUserInUserDirectory(userToSync, usersToSync.length - 1 === index)
          : addUserToUserDirectory(userToSync, usersToSync.length - 1 === index);
      }
    }
  };

  const addUserToUserDirectory = async (userToSync, isFinalUserToSync) => {
    addToUpdates(<p key={uuidv4().toString()}>Adding {userToSync.email} to the user directory.</p>);
    await apiGatewayCall(config.api_name, "/user-management/users", "put", { body: userToSync })
      .then((response) => {
        addToUpdates(
          <p key={uuidv4().toString()}>
            Created {userToSync.email} (Cognito user ID: {response.username})
          </p>
        );
        updateLegacyDb(userToSync.userId, response.username, userToSync.email);
        createdUsers.push(response.username);
        if (isFinalUserToSync) {
          completeSync();
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.error === "UsernameExistsException") {
          addToUpdates(<p>A user with the email address {userToSync.email} is already in the user directory.</p>);
        } else if (e.errorType === "UserNotFoundException") {
          addToUpdates(<p>{userToSync.email}'s existing Cognito ID does not exist in the User Pool.</p>);
        } else {
          showNotification("Access denied", "You do not have permission to create users.", "warning");
        }
      });
  };

  const updateUserInUserDirectory = async (userToSync, isFinalUserToSync) => {
    createdUsers.push(userToSync.userDirectoryId);
    addToUpdates(
      <p key={uuidv4().toString()}>
        {userToSync.email} is already in the user directory. Updating {userToSync.userDirectoryId}...
      </p>
    );
    await apiGatewayCall(config.api_name, "/user-management/users/" + userToSync.userDirectoryId, "post", {
      body: {
        username: userToSync.userDirectoryId,
        email: userToSync.email,
        fullName: userToSync.fullName,
        phoneNumber: userToSync.phoneNumber,
        roles: userToSync.roles,
        dateCreated: userToSync.createdDate,
        properties: userToSync.properties,
      },
    })
      .then((response) => {
        addToUpdates(
          <p key={uuidv4().toString()}>
            Updated {userToSync.email} (Cognito user ID: {userToSync.userDirectoryId})
          </p>
        );

        setTimeout(() => {
          updateLegacyDb(userToSync.userId, userToSync.userDirectoryId, userToSync.email);
        }, 500);

        createdUsers.push(userToSync.userDirectoryId);
        if (isFinalUserToSync) {
          completeSync();
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.error === "UsernameExistsException") {
          addToUpdates(<p>A user with the email address {userToSync.email} is already in the user directory.</p>);
        } else if (e.errorType === "UserNotFoundException") {
          addToUpdates(<p>{userToSync.email}'s existing Cognito ID does not exist in the User Pool.</p>);
        } else {
          showNotification("Access denied", "You do not have permission to create users.", "warning");
        }
      });
  };

  const completeSync = () => {
    addToUpdates(<pre key={uuidv4().toString()}>{JSON.stringify(createdUsers)}</pre>);
    setProgress("Sync complete");
  };

  const updateLegacyDb = async (userId, username, email) => {
    await apiGatewayCall(config.api_name, "/user-management/database-sync/update-legacy-database/", "post", {
      body: {
        userId: userId,
        cognitoUsername: username,
      },
    })
      .then((response) => {
        addToUpdates(
          <p key={uuidv4().toString()}>
            Updated {email}'s record in the legacy database with t1ud_uuid {response.username}.
          </p>
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addToUpdates = (update) => {
    setUpdates((oldUpdates) => {
      let _updates = [...oldUpdates];
      _updates.push(update);
      return _updates;
    });
  };
  return (
    <MDBCard>
      <MDBCardHeader>
        <MDBCardTitle>{progress}</MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>{updates}</MDBCardBody>
    </MDBCard>
  );
};

export default DatabaseSyncStatus;

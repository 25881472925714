import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";

import { MDBRow, MDBCol } from "mdbreact";
import RecordsToolbar from "../../components/RecordsToolbar";
import RecordsListing from "../../components/RecordsListing";
import CaptureInstructionTemplateDetailsModal from "../../components/CaptureInstructionTemplateDetailModal/CaptureInstructionTemplateDetailsModal";
import LoadingIcon from "../../components/LoadingIcon";

const CaptureInstructionTemplatesListing = props => {
  setPageTitle("Capture instruction templates");
  const [allRecords, setAllRecords] = useState([]);
  const [shortenedRecords, setShortenedRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [
    currentCaptureInstructionTemplate,
    setCurrentCaptureInstructionTemplate
  ] = useState("");

  const [canWrite, setCanWrite] = useState(false);

  let content_config = config[props.content];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };
    await API.get(
      config.api_name,
      content_config.listing.api_url,
      requestOptions
    ).then(response => {
      if (response.statusCode === 200) {
        setAllRecords(response.capture_instruction_templates);
        setShortenedRecords(
          shortenRecords(response.capture_instruction_templates)
        );
        setCanWrite(response.can_write);
        setLoading(false);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const shortenRecords = records => {
    let count = 0;
    let shortenedRecords = [];
    const visible_fields = content_config.listing.visible_fields;
    records.map(record => {
      shortenedRecords[count] = {};
      visible_fields.forEach(field => {
        shortenedRecords[count][field] = record[field];
      });
      count++;
      return true;
    });
    return shortenedRecords;
  };

  const triggerDetailsModal = async (e, recordId) => {
    const resetDetailsModalContent = () => {
      setCurrentCaptureInstructionTemplate("");
    };
    if (detailModalOpen === true) {
      setDetailModalOpen(false);
      resetDetailsModalContent();
    } else {
      setDetailModalOpen(true);
      e.preventDefault();

      setLoading(true);

      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
          user_name: jwt.accessToken.payload.username
        }
      };
      await API.get(
        config.api_name,
        content_config.listing.api_url + "/" + recordId + "?full=0",
        requestOptions
      ).then(response => {
        setLoading(false);
        response && response.template
          ? setCurrentCaptureInstructionTemplate(response.template)
          : setCurrentCaptureInstructionTemplate({});
      });
    }
  };

  let recordsListing = "";

  if (loading) {
    recordsListing = <LoadingIcon />;
  } else {
    if (allRecords) {
      recordsListing = (
        <>
          <MDBRow>
            <MDBCol>
              <RecordsToolbar
                refreshRecords={fetchData}
                newRecordUrl={content_config.listing.create_url}
                canWrite={canWrite}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <RecordsListing
                records={shortenedRecords}
                openDetailModal={triggerDetailsModal}
                idField={content_config.listing.id_field}
              />
            </MDBCol>
          </MDBRow>
          <CaptureInstructionTemplateDetailsModal
            detailModalOpen={detailModalOpen}
            triggerDetailsModal={triggerDetailsModal}
            serviceTemplate={currentCaptureInstructionTemplate}
            canWrite={canWrite}
          />
        </>
      );
    }
  }

  return (
    <Fragment>
      <MDBRow>
        <MDBCol>
          <h1 className="page-title">{content_config.title}</h1>
        </MDBCol>
      </MDBRow>
      {recordsListing}
    </Fragment>
  );
};

export default withRouter(CaptureInstructionTemplatesListing);

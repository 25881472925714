import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import setPageTitle from "../../helpers/setPageTitle";
import config from "../../content_types";
import { MDBInput, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBDataTable, MDBRow, MDBCol } from "mdbreact";
import showNotification from "../../helpers/showNotification";
import apiGatewayCall from "../../helpers/apiGatewayCall";

const WorkflowComponentsEdit = ({ content_type, match, setLoading }) => {
  const recordId = match.params.id;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const [redirectToHome, setRedirectToHome] = useState(false);

  const [component, setComponent] = useState(null);

  const [canEdit, setCanEdit] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/components/" + recordId, "get")
      .then((response) => {
        setComponent(response.component);
        setPageTitle("Editing " + response.component.name);
        setCanEdit(response.can_edit);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification("Access denied", "You do not have permission to view components.", "warning");
      });
  };
  const validateForm = () => {
    let requiredFields = [
      {
        name: "name",
        label: "Name",
      },
    ];
    let invalidFields = [];
    requiredFields.forEach((requiredField) => {
      if (component[requiredField.name] === "") {
        invalidFields.push(requiredField.label);
      }
    });

    if (invalidFields.length === 0) {
      return true;
    } else {
      let invalidFieldsString = invalidFields.join(", ");
      showNotification(
        "Error",
        "Please make sure the following fields are completed: " + invalidFieldsString,
        "warning"
      );
    }
  };

  const handleFormChange = (fieldName, e) => {
    setComponent((oldComponent) => {
      if (e === true) {
        e = 1;
      } else if (e === false) {
        e = 0;
      }
      oldComponent[fieldName] = e;
      return oldComponent;
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      let options = {
        body: component,
      };
      await apiGatewayCall(
        config.api_name,
        "/workflow-configuration/components/" + component.componentId,
        "post",
        options
      )
        .then((response) => {
          showNotification("Success", component.name + " was successfully updated", "success");
          fetchData();
        })
        .catch(() => {
          showNotification("Access denied", "You do not have permission to edit components.", "warning");
        });
    }
  };

  const saveNewInput = (e) => {
    e.preventDefault();
    const newInputName = document.getElementById("new-input-name").value;
    const newInputValue = document.getElementById("new-input-value").value;
    if (newInputName !== "") {
      let newComponent = Object.assign({}, component);
      newComponent.inputs[newInputName] = { value: newInputValue };
      setComponent(newComponent);
      document.getElementById("new-input-name").value = "";
      document.getElementById("new-input-value").value = "";
    }
  };

  const deleteInput = (inputName) => {
    let newComponent = Object.assign({}, component);
    delete newComponent.inputs[inputName];
    setComponent(newComponent);
  };

  let componentInputs;

  let formContent = [];
  if (component) {
    if (component.inputs) {
      let componentInputsData = {
        columns: [
          { label: "Name", field: "name" },
          { label: "Value", field: "value" },
          { label: "", field: "actions" },
        ],
        rows: [],
      };
      Object.keys(component.inputs).forEach((inputName) => {
        componentInputsData.rows.push({
          name: inputName,
          value: component.inputs[inputName].value,
          actions: <MDBIcon icon="times" className="clickable float-right" onClick={(e) => deleteInput(inputName)} />,
        });
      });
      componentInputs = (
        <MDBDataTable
          data={componentInputsData}
          displayEntries={false}
          info={false}
          noBottomColumns={true}
          paging={false}
          searching={false}
          sortable={false}
          noRecordsFoundLabel="Please add at least one input"
          hover
        />
      );
    }
    formContent.push(
      <MDBInput label="Name" valueDefault={component.name} getValue={(e) => handleFormChange("name", e)} />
    );
    formContent.push(
      <MDBInput
        label="Description"
        valueDefault={component.description}
        getValue={(e) => handleFormChange("description", e)}
      />
    );
    formContent.push(
      <p>
        <label>State machine ARN</label>
        <br />
        <span className="component-state-machine">
          <a
            href={
              "https://eu-west-2.console.aws.amazon.com/states/home?region=eu-west-2#/statemachines/view/" +
              component.stateMachineARN
            }
            target="_blank"
            rel="nofollow noreferrer"
          >
            {component.stateMachineARN}
          </a>
        </span>
      </p>
    );
    formContent.push(
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>Inputs</MDBCardTitle>
          <p>Inputs from the selected prototype are shown below. Overwrite them using the form below.</p>
          <form onSubmit={(e) => saveNewInput(e)}>
            <MDBRow>
              <MDBCol size="4">
                <MDBInput label="Input name" id="new-input-name" valueDefault="" />
              </MDBCol>
              <MDBCol size="5">
                <MDBInput label="Input value" id="new-input-value" valueDefault="" />
              </MDBCol>
              <MDBCol size="3">
                <MDBBtn type="submit" onClick={(e) => saveNewInput(e)} className="mb-0">
                  Set
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
          {componentInputs}
        </MDBCardBody>
      </MDBCard>
    );
  }

  let pageContent;
  if (redirectToHome) {
    pageContent = <Redirect to="/workflow-configuration/components" />;
  } else {
    if (component) {
      pageContent = (
        <>
          <h1 className="page-title">{component.name}</h1>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            {formContent}
            <MDBBtn color="secondary" onClick={(e) => setRedirectToHome(true)}>
              Back
            </MDBBtn>
            {canEdit === true ? (
              <MDBBtn type="submit" onClick={(e) => handleFormSubmit(e)} color="primary">
                Save
              </MDBBtn>
            ) : null}
          </form>
        </>
      );
    } else {
      pageContent = <p></p>;
    }
  }

  return pageContent;
};

export default withRouter(WorkflowComponentsEdit);

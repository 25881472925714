import { MDBCard, MDBCardBody } from "mdbreact";
import React from "react";

const ConfigurationSetDependants = ({ dependants }) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <p>This configuration set is used in:</p>
        <ul className="list-unstyled mb-0">
          {dependants.map((dependant) => (
            <li key={dependant.uuid}>
              <a href={dependant.url}>{dependant.name}</a>
            </li>
          ))}
        </ul>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConfigurationSetDependants;

import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useCookies } from "react-cookie";
import showNotification from "../helpers/showNotification";
import config from "../config";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBNavItem,
  MDBNavLink
} from "mdbreact";

const LaunchUploader = () => {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(["user_name", "access_token", ""]);
  const [modalOpen, setModalOpen] = useState(false);
  const [tickboxChecked, setTickboxChecked] = useState(false);
  const [invalidFeedback, setInvalidFeedback] = useState(false);
  const [_stateCookies, set_StateCookies] = useState(null);
  const [formState, setFormState] = useState({
    mp_job_id: "",
    mp_cust_id: "",
    create_media_order: false,
    service_template_id: ""
  });

  const onUploadClick = e => {
    e.preventDefault();
    setFormState(() => ({
      mp_job_id: "",
      mp_cust_id: "",
      create_media_order: false,
      service_template_id: ""
    }));
    setTickboxChecked(false);
    setModalOpen(() => modalOpen => !modalOpen);
  };

  useEffect(() => {
    //set up cookies on component load for window load later on...
    (async () => {
      try {
        await getUserName()
          .then(async username => {
            if (username) {
              setCookie("user_name", username, { domain: config.cookieDomain });
              const accessToken = await getAccessToken();
              if (accessToken) {
                setCookie("access_token", accessToken, { domain: config.cookieDomain });
                set_StateCookies(c => ({ ...c, accessToken: accessToken, username: username }));
              } else {
                onSessionExpired();
              }
            } else {
              onSessionExpired();
            }
          })
          .catch(e => console.log(e));
      } catch (error) {
        console.log("Error getting username", error);
      }
    })();
  }, [setCookie]);

  const getAccessToken = async () => {
    let jwtToken;
    try {
      await Auth.currentSession()
        .then(currentSession => (jwtToken = currentSession.accessToken.jwtToken))
        .catch(e => console.log(e));
      return jwtToken;
    } catch (error) {
      console.log("Error getting current token", error);
    }
  };

  const getUserName = async () => {
    let username;
    try {
      await Auth.currentUserInfo()
        .then(user => {
          if (user) {
            username = user.username;
          }
        })
        .catch(e => console.log(e));
      return username;
    } catch (error) {
      console.log("Error getting current user Info", error);
    }
  };

  const onSessionExpired = () => {
    showNotification(
      "No session",
      <>
        <p>Your session has expired.</p>
        <MDBBtn
          color="secondary"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Please log in to continue
        </MDBBtn>
      </>,
      "danger"
    );
  };

  const openUploaderWindow = async () => {
    // we need to pre-open a new tab before making the API call
    // as if window.open happens too deep into the callback stucture the
    // browser might block it as a popup.
    let { username, accessToken } = _stateCookies;
    let uploaderWindow = window.open("", "_blank");
    uploaderWindow.document.write(
      '<style type="text/css"> @import url("https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap"); .loader, .loader:before, .loader:after { border-radius: 50%; width: 2.5em; height: 2.5em; -webkit-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation: load7 1.8s infinite ease-in-out; animation: load7 1.8s infinite ease-in-out; } .loader { color: #542675; font-size: 10px; margin: 80px auto; position: relative; text-indent: -9999em; -webkit-transform: translateZ(0); -ms-transform: translateZ(0); transform: translateZ(0); -webkit-animation-delay: -0.16s; animation-delay: -0.16s; } .loader:before, .loader:after { content: ""; position: absolute; top: 0; } .loader:before { left: -3.5em; -webkit-animation-delay: -0.32s; animation-delay: -0.32s; } .loader:after { left: 3.5em; } @-webkit-keyframes load7 { 0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em; } 40% { box-shadow: 0 2.5em 0 0; } } @keyframes load7 { 0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em; } 40% { box-shadow: 0 2.5em 0 0; } } .uploader-transition-text{ margin: 0; text-align: center; font-family: "Libre Franklin", sans-serif; } .uploader-transition{position: absolute; top:45%; left: 50%; transform: translateY(-50%) translateX(-50%);}  </style> <div class="uploader-transition"> <div class="loader">Loading...</div> <p class="uploader-transition-text">Loading Uploader</p> </div>'
    );

    // API call to request an upload invitation
    await fetch(`${config.uploaderApiUrl}requestUploadInviteT1Console/`, {
      //await fetch("https://development-uploader-api.t1sys.net/requestUploadInviteT1Console/", {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        // reg can contain whatever you want at the moment...
        reg: {
          ...formState,
          access_token: accessToken,
          user_name: username
        }
      })
    })
      .then(response => {
        response
          .json()
          .then(data => {
            console.log("DATA", data);
            const token = data.token;
            const passcode = data.auth.passcode;
            uploaderWindow.location.href = `${config.uploaderUrl}invitation/t1consolebridge/${token}/${passcode}`;
          })
          .catch(reason => {
            showNotification(
              "Uploader Form Submission Failure",
              <>
                <p>
                  There was a problem with your submission. Please check your details and try again.
                </p>
              </>,
              "danger"
            );
            console.error(reason);
          });
      })
      .catch(reason => {
        showNotification(
          "Uploader Form Submission Failure",
          <>
            <p>
              There was a problem with your submission. Please check your details and try again.
            </p>
          </>,
          "danger"
        );
        console.error(reason);
      });
  };

  const formSubmitHandler = e => {
    console.log("FS:", formState);
    e.preventDefault();
    e.target.className += " was-validated";
    openUploaderWindow();
    toggleModal();
  };

  const formChangeHandler = e => {
    let { id, value } = e.target;
    setFormState({ ...formState, [id]: value });
    console.log(id, formState[id]);
  };

  const checkCreatMediaOrder = e => {
    console.log("E", e);
    if (tickboxChecked === true) {
      setInvalidFeedback(true);
      setTimeout(() => {
        setInvalidFeedback(false);
      }, 3000);
    }
  };

  const toggleModal = () => setModalOpen(modalOpen => !modalOpen);

  return (
    <div>
      <MDBNavItem>
        <MDBNavLink className="waves-effect waves-light" to="#!" onClick={e => onUploadClick(e)}>
          <MDBIcon icon="upload" />
        </MDBNavLink>
      </MDBNavItem>
      <MDBModal isOpen={modalOpen} toggle={() => toggleModal()}>
        <MDBModalHeader>Uploader Options</MDBModalHeader>
        <MDBModalBody className="text-center">
          <form
            id="pre-uploader-form"
            className="needs-validation"
            onSubmit={e => formSubmitHandler(e)}
            noValidate
          >
            Select from the options below:
            <MDBRow>
              <MDBCol></MDBCol>
              <MDBCol size="8">
                <div id="job_and_cust_ids" onClick={e => checkCreatMediaOrder(e)}>
                  <MDBInput
                    label="MediaPulse Job No."
                    className={formState.mp_job_id ? "form-control is-valid" : ""}
                    outline
                    type="text"
                    size="lg"
                    id="mp_job_id"
                    value={formState.mp_job_id}
                    onChange={e => formChangeHandler(e)}
                    disabled={tickboxChecked === true}
                  />
                  <MDBInput
                    label="MediaPulse Customer ID"
                    className={formState.mp_cust_id ? "form-control is-valid" : ""}
                    outline
                    type="text"
                    size="lg"
                    id="mp_cust_id"
                    value={formState.mp_cust_id}
                    onChange={e => formChangeHandler(e)}
                    disabled={tickboxChecked === true}
                  />
                </div>
                <MDBInput
                  label="Create Media Order for Each Asset"
                  type="checkbox"
                  checked={tickboxChecked}
                  onChange={() => {
                    setTickboxChecked(tb => !tb);
                    setFormState({
                      ...formState,
                      service_template_id: "",
                      create_media_order: !tickboxChecked
                    });
                    setInvalidFeedback(false);
                  }}
                  disabled={!(formState.mp_cust_id.length > 0 && formState.mp_job_id.length > 0)}
                  id="create_media_order"
                />
                {/* <MDBInput
                  label='Service Template ID'
                  className={formState.service_template_id.length > 0 ? 'form-control is-valid' : ''}
                  outline
                  type='text'
                  size='lg'
                  id='service_template_id'
                  value={formState.service_template_id}
                  onChange={(e) => formChangeHandler(e)}
                  disabled={!tickboxChecked}
                /> */}
                {invalidFeedback && (
                  <div style={{ color: "red" }}>
                    You must untick "Create Media Order" in order to change the values
                  </div>
                )}
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>
            <MDBBtn color="secondary" onClick={() => toggleModal()}>
              Cancel
            </MDBBtn>
            <MDBBtn
              disabled={
                (formState.mp_cust_id.length > 0 && !formState.mp_job_id.length > 0) ||
                (!formState.mp_cust_id.length > 0 && formState.mp_job_id.length > 0)
              }
              color="primary"
              position="right"
              type="submit"
              onSubmit={() => formSubmitHandler()}
            >
              Submit
            </MDBBtn>
          </form>
        </MDBModalBody>
      </MDBModal>
    </div>
  );
};

export default LaunchUploader;

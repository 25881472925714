import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
} from "mdbreact";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const EditUserProperty = ({
  editUserPropertyModalOpen,
  toggleEditUserPropertyModal,
  editPropertyData,
  onNewPropertySaved,
  canDeleteUserProperties,
  deleteUserProperty,
}) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const toggleConfirmDeleteModal = () => {
    setConfirmDeleteModalOpen(!confirmDeleteModalOpen);
  };

  let updatedUserPropertyValue;
  if (editPropertyData) {
    updatedUserPropertyValue = editPropertyData.value;
  }

  const onEditPropertyDataChange = (value) => {
    updatedUserPropertyValue = value;
  };

  const onEditFormSubmit = (e) => {
    e.preventDefault();
    toggleEditUserPropertyModal();
    onNewPropertySaved({ uuid: editPropertyData.uuid, value: updatedUserPropertyValue, label: editPropertyData.label });
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    updatedUserPropertyValue = editPropertyData.value;
    toggleEditUserPropertyModal();
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    toggleConfirmDeleteModal();
  };

  const onConfirmDelete = (e) => {
    e.preventDefault();
    toggleConfirmDeleteModal();
    toggleEditUserPropertyModal();
    deleteUserProperty(editPropertyData.uuid);
  };

  let editPropertyFieldContent;
  if (editPropertyData) {
    if (editPropertyData.inputType === "text") {
      editPropertyFieldContent = (
        <MDBInput
          type="text"
          label={editPropertyData.label}
          valueDefault={editPropertyData.value}
          getValue={onEditPropertyDataChange}
        />
      );
    }
    if (editPropertyData.inputType === "textarea") {
      editPropertyFieldContent = (
        <MDBInput
          type="textarea"
          label={editPropertyData.label}
          valueDefault={editPropertyData.value}
          getValue={onEditPropertyDataChange}
        />
      );
    }
    if (editPropertyData.inputType === "number") {
      editPropertyFieldContent = (
        <MDBInput
          type="number"
          label={editPropertyData.label}
          valueDefault={editPropertyData.value}
          getValue={onEditPropertyDataChange}
        />
      );
    }
    if (editPropertyData.inputType === "checkbox") {
      editPropertyFieldContent = (
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={editPropertyData.name}
            defaultChecked={JSON.parse(updatedUserPropertyValue)}
            onChange={(e) => onEditPropertyDataChange(e.target.checked.toString())}
          />
          <label className="custom-control-label" htmlFor={editPropertyData.name}>
            {editPropertyData.label}
          </label>
        </div>
      );
    }
    if (editPropertyData.inputType === "date") {
      editPropertyFieldContent = (
        <DatePicker
          selected={updatedUserPropertyValue}
          onChange={(date) => onEditPropertyDataChange(date)}
          showTimeSelect
          dateFormat="MMMM d, yyyy HH:mm 'UTC'"
          placeholderText={editPropertyData.label}
          className="form-control form-control-sm ml-0 my-1"
        />
      );
    }
    if (
      editPropertyData.inputType === "select" ||
      editPropertyData.inputType === "language-select" ||
      editPropertyData.inputType === "timezone-select" ||
      editPropertyData.inputType === "country-select"
    ) {
      editPropertyFieldContent = (
        <select
          className="browser-default custom-select"
          onChange={(e) => onEditPropertyDataChange(e.target.value)}
          defaultValue={editPropertyData.value}
        >
          <option value={null}>{editPropertyData.label}</option>
          {editPropertyData.metadata.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      );
    }
    if (editPropertyData.inputType === "multiple") {
      let options = [];
      let selectedOptions = []
      updatedUserPropertyValue.forEach(property => selectedOptions.push(property.S))
      editPropertyData.metadata.forEach((option) => {
        let selected = false;
        if (selectedOptions.includes(option.value)) selected = true;
        options.push(
          <MDBSelectOption key={option.value} value={option.value} selected={selected}>
            {option.label}
          </MDBSelectOption>
        );
      });
      editPropertyFieldContent = (
        <MDBSelect multiple getValue={(e) => onEditPropertyDataChange(e)} search>
          <MDBSelectInput selected={editPropertyData.label} />
          <MDBSelectOptions>{options}</MDBSelectOptions>
        </MDBSelect>
      );
    }
  }

  let modalContent = false;
  if (editPropertyData) {
    modalContent = (
      <>
        <MDBModal isOpen={editUserPropertyModalOpen} toggle={toggleEditUserPropertyModal} className="full-height">
          <MDBModalHeader>Editing {editPropertyData.label}</MDBModalHeader>
          <form onSubmit={(e) => onEditFormSubmit(e)}>
            <MDBModalBody>{editPropertyFieldContent}</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={(e) => onCancelClick(e)} className="float-left">
                Cancel
              </MDBBtn>
              {canDeleteUserProperties && (
                <MDBBtn color="danger" onClick={(e) => onDeleteClick(e)} className="float-left">
                  Delete
                </MDBBtn>
              )}

              <MDBBtn type="submit" onClick={(e) => onEditFormSubmit(e)} className="float-right">
                Save
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
        <MDBModal isOpen={confirmDeleteModalOpen} toggle={toggleConfirmDeleteModal}>
          <MDBModalBody>
            <p>Are you sure you want to delete {editPropertyData.label} from this user?</p>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={(e) => toggleConfirmDeleteModal()}>
              Cancel
            </MDBBtn>
            <MDBBtn onClick={(e) => onConfirmDelete(e)}>Delete</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
  return modalContent;
};

export default EditUserProperty;

import React, { useState, useEffect } from "react";
import config from "../../content_types";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdbreact";
import { NewRoleModal } from "./NewRoleModal";
import showNotification from "../../helpers/showNotification";
import LoadingIcon from "../LoadingIcon";
import apiGatewayCall from "../../helpers/apiGatewayCall";

export const CurrentRolesList = () => {
  useEffect(() => {
    getRoles();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  const [editRoleModalOpen, setEditRoleModalOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState({});
  const [allCognitoRoles, setAllCognitoRoles] = useState([]);
  const [currentRoleCognitoRoles, setCurrentRoleCognitoRoles] = useState([]);
  const [currentRoleCanUseConsole, setCurrentRoleCanUseConsole] = useState([]);

  const [canCreate, setCanCreate] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const getRoles = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/groups", "get")
      .then((response) => {
        setAllRoles(response.roles);
        setIsLoading(false);
        setCanCreate(response.can_create);
        setCanEdit(response.can_edit);
        setCanDelete(response.can_delete);
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to display a list of roles.", "warning");
      });
  };

  const onRoleClick = async (e, groupname) => {
    e.preventDefault();
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/groups/" + groupname, "get")
      .then((response) => {
        setIsLoading(false);
        setCurrentRole(response.role);
        setAllCognitoRoles(response.all_groups);
        setCurrentRoleCognitoRoles(response.role.groups);
        setCurrentRoleCanUseConsole(response.role.canUseConsole);
        toggleEditRoleModal();
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to edit roles.", "warning");
      });
  };
  const toggleEditRoleModal = () => {
    setEditRoleModalOpen(!editRoleModalOpen);
  };

  const deleteRole = async (groupname) => {
    await apiGatewayCall(config.api_name, "/user-management/groups/" + groupname, "del")
      .then((response) => {
        if (response.statusCode === 403) {
          if ("users" in response) {
            showNotification(
              "Deletion failed",
              "There are users with this role. Please either reassign them to other roles, or delete them, before this role can be deleted.",
              "warning"
            );
          }
        } else {
          showNotification("Role deleted", "Successfully deleted.", "success");
          toggleEditRoleModal();
          getRoles();
        }
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to delete roles.", "warning");
      });
  };

  const onRoleSubmit = async (e) => {
    e.preventDefault();
    await apiGatewayCall(config.api_name, "/user-management/groups/" + currentRole.uuid, "post", {
      body: { groups: currentRoleCognitoRoles, canUseConsole: currentRoleCanUseConsole },
    })
      .then(() => {
        showNotification("Role updated", currentRole.label + " was successfully updated.", "success");
        toggleEditRoleModal();
        getRoles();
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to edit roles", "warning");
      });
  };
  const onCognitoRolesChange = (e) => {
    let newCurrentRoleCognitoRoles = [...currentRoleCognitoRoles];
    const cognitoRole = {
      field: e.target.name,
      value: e.target.value,
      status: e.target.checked,
    };
    if (cognitoRole.status === true) {
      newCurrentRoleCognitoRoles.push(cognitoRole.value);
      setCurrentRoleCognitoRoles(newCurrentRoleCognitoRoles);
    } else if (cognitoRole.status === false) {
      newCurrentRoleCognitoRoles = newCurrentRoleCognitoRoles.filter((roleRole) => roleRole !== cognitoRole.value);
      setCurrentRoleCognitoRoles(newCurrentRoleCognitoRoles);
    }
  };

  let loadingContent = null;
  if (isLoading) loadingContent = <LoadingIcon fullHeight={false} />;

  return (
    <>
      {loadingContent}
      <ul className="list-unstyled">
        {allRoles.map((group) => {
          return (
            <li key={group.name}>
              <a onClick={(e) => onRoleClick(e, group.uuid)} href="#!">
                {group.label}
              </a>
            </li>
          );
        })}
      </ul>

      <MDBModal isOpen={editRoleModalOpen} toggle={toggleEditRoleModal}>
        <MDBModalHeader toggle={toggleEditRoleModal}>Editing {currentRole.label}</MDBModalHeader>
        <form onSubmit={(e) => onRoleSubmit(e)}>
          <MDBModalBody>
            <div className="form-check mb-4">
              <input
                type="checkbox"
                name="canUseConsole"
                id="canUseConsole"
                className="form-check-input"
                defaultChecked={currentRoleCanUseConsole}
                onChange={(e) => setCurrentRoleCanUseConsole(e.target.checked)}
              />
              <label htmlFor="canUseConsole" className="form-check-label">
                Can use Console
              </label>
            </div>

            <p>Add or remove permissions for this role</p>
            {allCognitoRoles ? (
              <fieldset>
                <legend>Permissions</legend>
                {allCognitoRoles.map((group) => {
                  const checkboxId = "checkbox-" + group;
                  let checkboxChecked = false;
                  currentRoleCognitoRoles.forEach((currentRoleCognitoRole) => {
                    if (currentRoleCognitoRole === group) {
                      checkboxChecked = true;
                    }
                  });

                  return (
                    <div className="form-check" key={group}>
                      <input
                        type="checkbox"
                        name="groups"
                        value={group}
                        id={checkboxId}
                        className="form-check-input"
                        onChange={(e) => onCognitoRolesChange(e)}
                        defaultChecked={checkboxChecked}
                      />
                      <label htmlFor={checkboxId} className="form-check-label">
                        {group}
                      </label>
                    </div>
                  );
                })}
              </fieldset>
            ) : (
              ""
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={toggleEditRoleModal} className="float-left">
              Close
            </MDBBtn>
            {canDelete && (
              <MDBDropdown className="float-left">
                <MDBDropdownToggle caret color="danger" className="float-left">
                  Delete
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem onClick={(e) => deleteRole(currentRole.uuid)}>Delete</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            )}
            {canEdit && (
              <MDBBtn color="primary" type="submit" className="float-right">
                Save changes
              </MDBBtn>
            )}
          </MDBModalFooter>
        </form>
      </MDBModal>
      {canCreate && <NewRoleModal setIsLoading={setIsLoading} getRoles={getRoles} />}
    </>
  );
};

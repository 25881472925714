import React, { useState } from "react";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdbreact";
import moment from "moment";
import NewUserProperty from "./NewUserProperty";
import EditUserProperty from "./EditUserProperty";
import CopyIcon from "../../CopyIcon";

const UserProperties = ({
  setIsLoading,
  updatedUser,
  allProperties,
  updateProperties,
  canCreateUserProperties,
  canEditUserProperties,
  canDeleteUserProperties,
  deleteUserProperty,
  allPropertyTypes,
}) => {
  const [editUserPropertyModalOpen, setEditUserPropertyModalOpen] = useState(false);
  const [editPropertyData, setEditPropertyData] = useState(null);

  const onNewPropertySaved = (property) => {
    updateProperties(property);
  };

  const onPropertyClick = (propertyId) => {
    updatedUser.properties.forEach((currentProperty) => {
      if (currentProperty.uuid === propertyId) {
        if (currentProperty.inputType === "date") {
          currentProperty.value = new Date(currentProperty.value);
        }
        setEditPropertyData(currentProperty);
      }
    });
    setEditUserPropertyModalOpen(true);
  };

  let propertiesContent = [];
  let propertyTypesDisplayed = [];
  let tableClassName = "mdb-0";
  let tableHover;
  // let rowClickEvent;
  if (updatedUser && allProperties) {
    if (canEditUserProperties) {
      tableHover = true;
    }
    allPropertyTypes.forEach((propertyType) => {
      let rowsForPropertyType = [];
      const tableKey = "table-" + propertyType.value;
      let tableForPropertyType = (
        <MDBTable className={tableClassName} key={tableKey} small hover={tableHover}>
          <MDBTableHead>
            <tr>
              <th>Property</th>
              <th>Value</th>
              <th></th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>{rowsForPropertyType}</MDBTableBody>
        </MDBTable>
      );
      updatedUser.properties.forEach((userProperty) => {
        if (userProperty.propertyType === propertyType.value) {
          if (!propertyTypesDisplayed.includes(propertyType.label)) {
            propertyTypesDisplayed.push(propertyType.label);
            propertiesContent.push(
              <MDBCard className="mb-4" key={propertyType.value}>
                <MDBCardBody>
                  <MDBCardTitle tag="h5">{propertyType.label}</MDBCardTitle>
                  {tableForPropertyType}
                </MDBCardBody>
              </MDBCard>
            );
          }
          // if (canEditUserProperties) {
          //   rowClickEvent = () => onPropertyClick(userProperty.uuid);
          // }
          let rowValue = userProperty.value.toString();
          if (userProperty.inputType === "date") {
            rowValue = moment(userProperty.value).format("YYYY-MM-DD HH:mm:ss");
          }

          if (userProperty.inputType === "select") {
            allProperties.forEach((allProperty) => {
              if (allProperty.uuid === userProperty.uuid) {
                allProperty.metadata.forEach((metadataItem) => {
                  if (userProperty.value === metadataItem.value) {
                    rowValue = metadataItem.label;
                  }
                });
              }
            });
          }
          if (userProperty.inputType === "multiple") {
            const valuesArray = userProperty.value;
            let valuesString = "";
            allProperties.forEach((allProperty) => {
              if (allProperty.uuid === userProperty.uuid) {
                let count = 1;
                allProperty.metadata.forEach((metadataItem) => {
                  valuesArray.forEach((value) => {
                    if (value.S === metadataItem.value) {
                      valuesString = valuesString + metadataItem.label;
                      if (count !== valuesArray.length) {
                        valuesString = valuesString + ", ";
                      }
                      count++;
                    }
                  });
                });
              }
            });
            rowValue = valuesString;
          }
          let _row = [
            <td key={userProperty.uuid + "-label"}>{userProperty.label}</td>,
            <td key={userProperty.uuid + "-value"}>
              <CopyIcon text={rowValue} />
            </td>,
          ];
          if (canEditUserProperties) {
            _row.push(
              <td key={userProperty.uuid + "-edit"}>
                <MDBIcon icon="edit" onClick={(e) => onPropertyClick(userProperty.uuid)} className="clickable" />
              </td>
            );
          } else {
            _row.push(<td key={userProperty.uuid + "-blank"}></td>);
          }

          rowsForPropertyType.push(<tr key={userProperty.name}>{_row}</tr>);
        }
      });
    });
  }

  const toggleEditUserPropertyModal = () => {
    setEditUserPropertyModalOpen(!editUserPropertyModalOpen);
  };

  return (
    <MDBRow>
      <MDBCol>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>Properties</MDBCardTitle>
            {canCreateUserProperties && (
              <NewUserProperty
                setIsLoading={setIsLoading}
                allProperties={allProperties}
                onNewPropertySaved={onNewPropertySaved}
              />
            )}

            {propertiesContent}

            <EditUserProperty
              editUserPropertyModalOpen={editUserPropertyModalOpen}
              toggleEditUserPropertyModal={toggleEditUserPropertyModal}
              editPropertyData={editPropertyData}
              onNewPropertySaved={onNewPropertySaved}
              canDeleteUserProperties={canDeleteUserProperties}
              deleteUserProperty={deleteUserProperty}
            />
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
};

export default UserProperties;

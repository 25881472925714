import React, { Fragment } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import setPageTitle from "../../helpers/setPageTitle";

import { CurrentRolesList } from "../../components/UserManagement/CurrentRolesList";
import CurrentPermissionsList from "../../components/UserManagement/CurrentPermissionsList";

export const RolesPermissionsHome = () => {
  setPageTitle("Roles and permissions");
  return (
    <Fragment>
      <MDBRow>
        <MDBCol>
          <h1 className="page-title">Roles and permissions</h1>
        </MDBCol>
      </MDBRow>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle tag="h2">Roles</MDBCardTitle>
          <CurrentRolesList />
        </MDBCardBody>
      </MDBCard>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle tag="h2">Permissions</MDBCardTitle>
          <CurrentPermissionsList />
        </MDBCardBody>
      </MDBCard>
    </Fragment>
  );
};

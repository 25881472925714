import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead
} from "mdbreact";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const WorkflowLocationsDetailModal = ({
  detailModalOpen,
  triggerDetailsModal,
  workflowLocation,
  canEdit
}) => {
  useEffect(() => {
    updateModalContent();
    // eslint-disable-next-line
  }, [workflowLocation]);

  const [title, setTitle] = useState("");
  const [workflowContent, setWorkflowContent] = useState([]);
  const [editUrl, setEditUrl] = useState("");

  const updateModalContent = () => {
    if (workflowLocation) {
      setTitle(workflowLocation.name);

      const editUrl =
        "/workflow-configuration/locations/" + workflowLocation.locationId;
      setEditUrl(editUrl);

      const mainDetails = () => {
        let workflowDetails = workflowLocation;
        const hiddenFields = ["content"];
        Object.keys(workflowDetails).forEach(tableField => {
          if (hiddenFields.includes(tableField)) {
            delete workflowDetails[tableField];
          }
        });

        let modalContent = [];
        let valueCount = 0;
        let table = (
          <div className="mb-4" key="top-table">
            <MDBTable responsive>
              <MDBTableHead>
                <tr>
                  {Object.keys(workflowDetails).map(field => {
                    return <th key={field}>{field}</th>;
                  })}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr>
                  {Object.values(workflowDetails).map(value => {
                    const valueId = "value-" + valueCount;
                    valueCount++;
                    return <td key={valueId}>{value}</td>;
                  })}
                </tr>
              </MDBTableBody>
            </MDBTable>
          </div>
        );
        modalContent.push(table);

        setWorkflowContent(modalContent);
      };

      mainDetails();
    }
  };

  return (
    <MDBModal
      isOpen={detailModalOpen}
      toggle={triggerDetailsModal}
      size="fluid"
      className="listing-detail-modal"
    >
      <MDBModalHeader toggle={triggerDetailsModal} tag="h2">
        {title}
      </MDBModalHeader>
      <MDBModalBody>{workflowContent}</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={triggerDetailsModal}>
          Close
        </MDBBtn>
        {canEdit ? (
          <MDBBtn color="primary" tag={Link} to={editUrl}>
            <MDBIcon icon="edit" className="mr-2" /> Edit
          </MDBBtn>
        ) : (
          ""
        )}
      </MDBModalFooter>
    </MDBModal>
  );
};

export default WorkflowLocationsDetailModal;

const config = {
  environmentName: "staging",
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://6y17rm8x47.execute-api.eu-west-2.amazonaws.com/production",
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_8HSnblsGZ",
    APP_CLIENT_ID: "3n44lbl455mcn1jks6j7eirmr1",
  },
  uploaderUrl: "https://staging-uploader.take1.tv/",
  uploaderApiUrl: "https://staging-uploader-api.take1.tv/",
  cookieDomain: ".take1.tv",
  largeFileUploaderHostname: "largefileuploader.take1.tv"
};

export default config;

import React, { useEffect, useState } from 'react';
import apiGatewayCall from '../../helpers/apiGatewayCall';
import showNotification from '../../helpers/showNotification';
import sortArrayOfObjectsByKey from '../../helpers/sortArrayOfObjectsByKey';
import config from '../../content_types';
import LoadingIcon from '../../components/LoadingIcon';
import { MDBDataTable, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import moment from 'moment';

const Tasks = () => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [sourcesModalOpen, setSourcesModalOpen] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  const [tasksTable] = useState({
    columns: [
      { label: 'InputInstanceId', field: 'inputInstanceId' },
      { label: 'Op. No.', field: 'reg.operation_no' },
      { label: 'Estimated Start Date', field: 'reg.trx_begin_dt' },
      { label: 'Deadline Date', field: 'reg.trx_end_dt' },
      { label: 'Job', field: 'reg.job_desc' },
      { label: 'Order', field: 'reg.wo_desc' },
      { label: 'Sources', field: 'reg.title' },
      { label: 'Task', field: 'reg.task_no' },
      { label: 'Task Dur. (Mins)', field: 'reg.cost_unit' },
      { label: 'Assignee Console ID', field: 'reg.resource_code.external_key' },
      { label: 'Resource Code', field: 'reg.resource_code.resource_code' },
      { label: 'Resource Name', field: 'reg.resource_code.resource_desc' },
      { label: 'Task Phase', field: 'reg.trx_phase_code' },
      { label: 'Task Status', field: 'reg.status_no' },
    ],
    rows: [],
  });
  const [sourcesTable, setSourcesTable] = useState({
    columns: [
      { label: 'Asset No.', field: 'master_no' },
      { label: 'Source No.', field: 'source_no' },
      { label: 'Title', field: 'title' },
      { label: 'Dur. (HH:MM:SS)', field: 'length' },
      { label: 'File Size', field: 'file_size' },
      { label: 'File Size Unit', field: 'file_size_unit' },
      { label: 'External Key', field: 'external_key' },
    ],
    rows: [],
  });

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line
  }, []);

  const fetchTasks = async () => {
    setLoading(true);
    let url = '/tasks/';
    if (lastEvaluatedKey) url = `${url}?lastEvaluatedKey=${lastEvaluatedKey}`;
    await apiGatewayCall(config.api_name, url, 'get')
      .then((response) => {
        setTasks(([...tasks]) => response.tasks);
        setLastEvaluatedKey(() => {
          if (response.LastEvaluatedKey !== undefined) {
            return encodeURIComponent(JSON.stringify(response.LastEvaluatedKey));
          } else {
            return null;
          }
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification('Access denied', 'You do not have permission.', 'warning');
      });
  };

  const loadTaskRows = () => {
    const _tasks = tasks.map((task, i) => {
      task.inputInstanceId = task.inputInstanceId ? task.inputInstanceId : '';
      task['reg.operation_no'] = task['reg.operation_no'] ? task['reg.operation_no'] : '';
      task['reg.trx_begin_dt'] = task['reg.trx_begin_dt']
        ? moment(task['reg.trx_begin_dt']).local().format('DD-MMM-YYYY HH:mm')
        : '';
      task['reg.trx_end_dt'] = task['reg.trx_end_dt']
        ? moment(task['reg.trx_end_dt']).local().format('DD-MMM-YYYY HH:mm')
        : '';
      task['reg.job_desc'] = task['reg.job_desc'] ? task['reg.job_desc'] : '';
      task['reg.wo_desc'] = task['reg.wo_desc'] ? task['reg.wo_desc'] : '';
      if (renderSourcesButton(task)) {
        recompileSources(i, task);
        task['reg.title'] = (
          <MDBBtn onClick={(e) => openSourcesClick(e)} color='purple' id={i} size='sm'>
            View Details
          </MDBBtn>
        );
      } else {
        task['reg.title'] = 'None';
      }
      if (!task['reg.task_no']) task['reg.task_no'] = '';
      if (!task['reg.cost_unit']) task['reg.cost_unit'] = '';
      if (!task['reg.resource_code.external_key']) task['reg.resource_code.external_key'] = '';
      if (!task['reg.trx.resource_code']) task['reg.trx.resource_code'] = '';
      if (!task['reg.trx.resource_desc']) task['reg.trx.resource_desc'] = '';
      if (!task['reg.trx.phase_code']) task['reg.trx.phase_code'] = '';
      if (!task['reg.status_no']) task['reg.status_no'] = '';
      return task;
    });
    tasksTable.rows = sortArrayOfObjectsByKey(_tasks, 'reg.trx_end_dt');
  };

  const renderSourcesButton = (task, expr = '^reg.sources') => {
    const regex = new RegExp(expr);
    return Object.keys(task).some((key) => {
      return regex.test(key);
    });
  };

  const recompileSources = (id, task) => {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    const regex = new RegExp('reg.sources.([0-9]).(.+)$');
    task.sources = Object.keys(task)
      //run reg on all task attributes
      .map((key) => {
        let m = key.match(regex);
        return m !== null ? m[1] : '';
      })
      //filter out those without a source
      .filter((x) => x !== '')
      //reduce the number to reflect number of sources
      .filter(unique)
      //build the sources as table rows
      .map((i) => {
        return {
          master_no: tasks[id][`reg.sources.${i}.master_no`],
          source_no: tasks[id][`reg.sources.${i}.source_no`],
          title: tasks[id][`reg.sources.${i}.title`],
          length: tasks[id][`reg.sources.${i}.length`],
          file_size: tasks[id][`reg.sources.${i}.file_size`],
          file_size_unit: tasks[id][`reg.sources.${i}.file_size_unit`],
          external_key: tasks[id][`reg.sources.${i}.external_key`],
        };
      });
  };

  const openSourcesClick = (e) => {
    e.preventDefault();
    loadSourceRows(e.target.id);
    toggleOpenSources();
  };

  const loadSourceRows = (id) => {
    const _sourcesTable = { ...sourcesTable };
    _sourcesTable.rows = tasks[id].sources;
    setSourcesTable(_sourcesTable);
  };

  const toggleOpenSources = () => {
    setSourcesModalOpen(!sourcesModalOpen);
  };

  const onPaginationChange = (e) => {
    if (e.activePage === e.pagesAmount || e.activePage + 1 === e.pagesAmount) {
      if (lastEvaluatedKey) {
        fetchTasks();
      }
    }
  };

  loadTaskRows();

  return (
    <div>
      {loading && <LoadingIcon />}
      <h1>Tasks</h1>
      {loading && tasks.length === 0 && <p>Loading tasks...</p>}
      {tasks.length > 0 ? (
        <MDBDataTable
          data={tasksTable}
          hover
          //className="clickable-table"
          noBottomColumns
          noRecordsFoundLabel='No tasks available'
          // entries={5}
          onPageChange={(e) => {
            onPaginationChange(e);
          }}
          disableRetreatAfterSorting={true}
        />
      ) : (
        !loading && <p>No tasks found.</p>
      )}
      <MDBModal size='fluid' isOpen={sourcesModalOpen} toggle={toggleOpenSources}>
        <MDBModalHeader>Sources for Task:</MDBModalHeader>
        <MDBModalBody>
          <MDBDataTable
            data={sourcesTable}
            striped
            displayEntries={true}
            noBottomColumns={true}
            searching={false}
            sortable={true}
            paging={false}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color='secondary'
            onClick={(e) => {
              toggleOpenSources();
            }}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
};

export default Tasks;

import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";
import config from "../content_types";

const DeleteButton = ({ content_type, id, title, setRedirectToHome }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deniedModalOpen, setDeniedModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleDeniedModal = () => {
    setDeniedModalOpen(!deniedModalOpen);
  };
  const afterSuccessfulDelete = () => {
    setRedirectToHome(true);
  };
  const afterDeleteDenied = () => {
    setModalOpen(false);
    setDeniedModalOpen(true);
  };
  const deleteRecord = async () => {
    const jwt = await Auth.currentSession();
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: jwt.accessToken.payload.username
      }
    };

    await API.del(
      config.api_name,
      config[content_type].delete.api_url + "/" + id,
      requestOptions
    ).then(response => {
      if (response.statusCode === 200) {
        afterSuccessfulDelete();
      } else if (response.statusCode === 400) {
        afterDeleteDenied();
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  return (
    <>
      <MDBBtn color="danger" onClick={toggleModal}>
        Delete
      </MDBBtn>

      <MDBModal isOpen={modalOpen} toggle={toggleModal}>
        <MDBModalBody>Are you sure you want to delete {title}?</MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={toggleModal}>
            Cancel
          </MDBBtn>
          <MDBBtn color="danger" onClick={deleteRecord}>
            Delete
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <MDBModal isOpen={deniedModalOpen} toggle={toggleDeniedModal}>
        <MDBModalHeader>Can't delete this item</MDBModalHeader>
        <MDBModalBody>
          This item cannot be deleted because it is being used elsewhere in the
          system. Either delete those dependencies first or, if possible, set
          its status to "inactive".
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={toggleDeniedModal}>
            OK
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
};

export default DeleteButton;

import { MDBBtn, MDBIcon, MDBInput } from "mdbreact";
import React, { useEffect, useRef } from "react";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";
import * as _config from "../../config";

const OoonaSettingsExternalGuid = ({
  field,
  configurationSet,
  setLoading,
  updateConfigurationSetValues,
  onSubmit,
  canEdit,
}) => {
  const configurationSetNameRef = useRef(configurationSet.name);

  useEffect(() => {
    if (configurationSet.name !== configurationSetNameRef.current && !('uuid' in configurationSet)) {
      configurationSetNameRef.current = configurationSet.name;
      let _configurationSet = { ...configurationSet };
      _configurationSet.values[field.inputInstance.slug] = {
        ooonaGuidName: generateInitialValue(configurationSetNameRef.current),
        created: false,
      };
      updateConfigurationSetValues(field, _configurationSet.values[field.inputInstance.slug]);
    }
    // eslint-disable-next-line
  }, [configurationSet.name]);

  const generateInitialValue = (configurationSetName) => {
    let initialValuePrefix = "";
    if (_config.default.environmentName !== "production") {
      initialValuePrefix = _config.default.environmentName.substr(0, 1) + "_";
    }
    const initialValue =
      initialValuePrefix +
      field.inputInstance.values.settingsType.substring(0, 4).toLowerCase() +
      "_" +
      configurationSetName
        // configurationSetNameRef.current
        .replace(/\s+/g, "")
        .replace(/[^\w\s]/gi, "")
        .toLowerCase();

    return initialValue;
  };

  if (
    configurationSet.values[field.inputInstance.slug] === undefined ||
    configurationSet.values[field.inputInstance.slug] === ""
  ) {
    configurationSet.values[field.inputInstance.slug] = {
      ooonaGuidName: generateInitialValue(configurationSetNameRef.current),
      created: false,
    };
  }

  const onOoonaSettingsExternalClick = async (e) => {
    // e.preventDefault();
    if (configurationSet.name !== "") {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/ooona/generate-settings/", "post", {
        body: {
          configurationType: field.inputInstance.values.settingsType,
          configurationName: configurationSet.values[field.inputInstance.slug].ooonaGuidName,
          exists: configurationSet.values[field.inputInstance.slug].created,
        },
      })
        .then((response) => {
          setLoading(false);
          let _configurationSet = { ...configurationSet };
          _configurationSet.values[field.inputInstance.slug].created = true;
          updateConfigurationSetValues(field, _configurationSet.values[field.inputInstance.slug]);
          const newWindow = window.open(response.url, "_blank");
          newWindow.focus();
          setTimeout(() => {
            onSubmit(e);
          }, 500);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      showNotification(
        "Error",
        "Please make sure your configuration set has a name before opening the settings on Ooonatools",
        "warning"
      );
    }
  };

  return (
    <div className="mb-4">
      <h3 className="h5">{field.inputInstance.label}</h3>
      <MDBInput
        type="text"
        label={field.inputInstance.label + " GUID"}
        id={field.inputInstance.slug}
        getValue={(e) => {
          let _configurationSet = { ...configurationSet };
          _configurationSet.values[field.inputInstance.slug].ooonaGuidName = e;
          updateConfigurationSetValues(field, _configurationSet.values[field.inputInstance.slug]);
        }}
        value={configurationSet.values[field.inputInstance.slug].ooonaGuidName}
        disabled={true}
        // disabled={configurationSet.values[field.inputInstance.slug].created || !canEdit}
      />
      {"uuid" in configurationSet && (
        <div>
          <MDBBtn
            onClick={() => onOoonaSettingsExternalClick()}
            className="m-0"
            disabled={!("uuid" in configurationSet) || !canEdit}
          >
            {configurationSet.values[field.inputInstance.slug].created ? "Edit " : "Create "}
            {field.inputInstance.values.settingsType}
          </MDBBtn>
          {!configurationSet.values[field.inputInstance.slug].created && (
            <MDBIcon
              icon="exclamation-triangle"
              className="amber-text ml-4"
              size="2x"
              style={{ verticalAlign: "middle", lineHeight: 1.5 }}
              title="This Ooona setting will not be available until it is created using this button."
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OoonaSettingsExternalGuid;

import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { useState, useEffect } from "react";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import LoadingIcon from "../../components/LoadingIcon";
import setPageTitle from "../../helpers/setPageTitle";
import showNotification from "../../helpers/showNotification";
import ConfigurationSetInformation from "./edit/ConfigurationSetInformation";
import ConfigurationSetData from "./ConfigurationSetData";
import ConfigurationSetPreview from "./ConfigurationSetPreview";
import ConfigurationSetMoreInformation from "./edit/ConfigurationSetMoreInformation";
import ConfigurationSetDependants from "./edit/ConfigurationSetDependants";

const ConfigurationSetsEdit = ({ match }) => {
  const configurationSetId = match.params.id;
  setPageTitle("Editing configuration set");

  const [loading, setLoading] = useState(false);
  const [configurationSet, setConfigurationSet] = useState(null);
  const [configurationSets, setConfigurationSets] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [unauthorised, setUnauthorised] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    getFormData();
    // eslint-disable-next-line
  }, []);

  const getFormData = async () => {
    setLoading(true);
    apiGatewayCall(config.api_name, "/configuration-sets/" + configurationSetId, "get")
      .then((response) => {
        setLoading(false);
        if (response.configurationSet) {
          setConfigurationSet(response.configurationSet);
          setConfigurationSets(response.formData.configurationSets);
          setPageTitle(response.configurationSet.schema.label + " / " + response.configurationSet.name);
          setCanEdit(response.configurationSet.canEdit);
        } else {
          setUnauthorised(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onConfirmDeleteClick = async (e) => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/configuration-sets/" + configurationSet.uuid, "del").then(() => {
      setDeleteModalOpen(false);
      setLoading(false);
      window.location.href = "/configuration-sets";
    });
  };

  const onSubmit = async (e = null) => {
    if (e) {
      e.preventDefault();
    }
    if (validateForm()) {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/configuration-sets/" + configurationSet.uuid, "post", {
        body: configurationSet,
      })
        .then(() => {
          setLoading(false);
          showNotification("Success", configurationSet.name + " was successfully saved.", "success");
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      showNotification("Error", "Please make sure you enter a name.", "warning");
    }
  };

  const validateForm = () => {
    if (configurationSet.name !== "") return true;
    else return false;
  };
  const updateConfigurationSetInformation = (field, newValue) => {
    setConfigurationSet((oldConfigurationSet) => {
      let _configurationSet = { ...oldConfigurationSet };
      _configurationSet[field] = newValue;
      return _configurationSet;
    });
  };

  return (
    <article className="page page-edit-configuration-set">
      {unauthorised && (
        <>
          <h1 className="page-title">Permission denied</h1>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>
              <a href="/">Home</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <a href="/configuration-sets">Configuration sets</a>
            </MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <p>You do not have permission to view this configuration set.</p>
        </>
      )}
      {configurationSet && configurationSets && (
        <>
          <h1 className="page-title">
            Configuration set: {configurationSet.schema.label} / {configurationSet.name}
          </h1>

          <MDBBreadcrumb>
            <MDBBreadcrumbItem>
              <a href="/">Home</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <a href="/configuration-sets">Configuration sets</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>
              {configurationSet.schema.label} / {configurationSet.name}
            </MDBBreadcrumbItem>
          </MDBBreadcrumb>

          <MDBRow>
            <MDBCol size="7">
              <form className="new-schema-form" onSubmit={(e) => onSubmit(e)}>
                <ConfigurationSetInformation
                  configurationSet={configurationSet}
                  updateConfigurationSetInformation={updateConfigurationSetInformation}
                  canEdit={canEdit}
                />
                <ConfigurationSetData
                  configurationSet={configurationSet}
                  setConfigurationSet={setConfigurationSet}
                  setLoading={setLoading}
                  onSubmit={onSubmit}
                  configurationSets={configurationSets}
                  canEdit={canEdit}
                />
                <footer className="mb-4">
                  <MDBBtn tag="a" href="/configuration-sets" color="secondary">
                    Back to configuration sets
                  </MDBBtn>
                  {canEdit && (
                    <MDBBtn type="submit" onClick={(e) => onSubmit(e)} className="float-right">
                      Save
                    </MDBBtn>
                  )}
                </footer>
              </form>
            </MDBCol>
            <MDBCol size="5">
              <ConfigurationSetPreview configurationSet={configurationSet} />
              {configurationSet.dependants.length > 0 && (
                <ConfigurationSetDependants dependants={configurationSet.dependants} />
              )}
              <ConfigurationSetMoreInformation configurationSet={configurationSet} />
            </MDBCol>
          </MDBRow>
          <MDBModal isOpen={deleteModalOpen}>
            <MDBModalHeader>Delete {configurationSet.name}</MDBModalHeader>
            <MDBModalBody>
              Are you sure you want to delete {configurationSet.name}? This action cannot be undone.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteModalOpen(false);
                }}
                color="secondary"
              >
                Cancel
              </MDBBtn>
              <MDBBtn onClick={(e) => onConfirmDeleteClick(e)}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </>
      )}
      {loading && <LoadingIcon />}
    </article>
  );
};

export default ConfigurationSetsEdit;

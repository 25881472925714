import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import React from "react";
import SchemaPermission from "./SchemaPermission";

const SchemaPermissions = ({ schema, allRoles, setSchema, canEdit }) => {
  const permissionTypes = ["schemaView", "schemaEdit", "configurationSetsView", "configurationSetsEdit"];
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>Permissions</MDBCardTitle>
        {permissionTypes.map((permissionType) => (
          <SchemaPermission
            key={permissionType}
            schema={schema}
            allRoles={allRoles}
            permission={permissionType}
            setSchema={setSchema}
            canEdit={canEdit}
          />
        ))}
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaPermissions;

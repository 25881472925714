import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";
import React, { useState } from "react";
import Select from "react-select";

const Take1Details = ({ userData, updateUserData, software }) => {
  const [selectedSoftwareExperience, setSelectedSoftwareExperience] = useState(() => {
    let _selectedSoftwareExperience = [];
    if (userData.softwareExperience.length) {
      software.forEach((singleSoftware) => {
        if (userData.softwareExperience.includes(singleSoftware.value)) {
          _selectedSoftwareExperience.push(singleSoftware);
        }
      });
    }
    return _selectedSoftwareExperience;
  });

  const onSoftwareExperienceChange = (value) => {
    setSelectedSoftwareExperience(value);
    let _softwareExperience = [];
    if (value.length) {
      value.forEach((singleValue) => {
        _softwareExperience.push(singleValue.value);
      });
    }
    updateUserData({ target: { id: "softwareExperience", value: _softwareExperience } });
  };
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag="h2" className="h4">
          Take 1 details
        </MDBCardTitle>
        {software && (
          <div className="form-group">
            <label htmlFor="software">Software experience</label>
            <Select
              options={software}
              id="software"
              isSearchable={true}
              value={
                selectedSoftwareExperience
                // userData.softwareExperience
                // "software" in userData &&
                // software.filter((singleSoftware) => singleSoftware.value === userData.softwareExperience)
              }
              isMulti
              onChange={(value) => {
                onSoftwareExperienceChange(value);
                // updateUserData({ target: { id: "softwareExperience", value: value } });
                // console.log(value);
                // switch (action.action) {
                //   case "select-option":
                //     console.log(`Adding ${value.value}`);
                //     break;
                //   case "deselect-option":
                //     console.log(`Removing ${value.value}`);
                //     break;
                //   default:
                //     break;
                // }
                // updateUserData({ target: { id: "software", value: value.value } });
              }}
            />
          </div>
        )}
      </MDBCardBody>
    </MDBCard>
  );
};

export default Take1Details;

import React, { useEffect, useState } from 'react';
import config from '../../../content_types';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdbreact';
import moment from 'moment';
import showNotification from '../../../helpers/showNotification';
import LoadingIcon from '../../LoadingIcon';
import apiGatewayCall from '../../../helpers/apiGatewayCall';
import setPageTitle from '../../../helpers/setPageTitle';
import UserProperties from './UserProperties';
import PhoneInput from 'react-phone-input-2';

const EditUser = ({ match }) => {
  const userId = match.params.id;

  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [canListUserProperties, setCanListUserProperties] = useState(false);
  const [canCreateUserProperties, setCanCreateUserProperties] = useState(false);
  const [canEditUserProperties, setCanEditUserProperties] = useState(false);
  const [canDeleteUserProperties, setCanDeleteUserProperties] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [confirmResetMfaModalOpen, setConfirmResetMfaModalOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [updatedUser, setUpdatedUser] = useState(null);

  const [currentUserRoles, setCurrentUserRoles] = useState(null);
  const [allRoles, setAllRoles] = useState(null);

  const [allProperties, setAllProperties] = useState(null);
  const [allPropertyTypes, setAllPropertyTypes] = useState(null);

  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    getUser();
    getConsoleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConsoleData = async () => {
    setIsLoading(true);
    try {
      await apiGatewayCall(config.api_name, `/user-portal/?id=${userId}`, 'get')
        .then((response) => {
          console.log(userId);
          console.log(response);
          setProfileData((_profileData) => response.userData);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    } catch (error) {
      console.log('Error retrieving Profile data:', error);
    }
  };

  const getUser = async () => {
    setPageTitle('Editing user');
    setIsLoading(true);
    await apiGatewayCall(config.api_name, '/user-management/users/' + userId, 'get')
      .then((response) => {
        setPageTitle(response.user.fullName);
        setIsLoading(false);
        setCurrentUser(() => {
          let newCurrentUser = { ...response.user };
          newCurrentUser.roles = [];
          response.user.roles.forEach((role) => {
            newCurrentUser.roles.push(role.uuid);
          });
          return newCurrentUser;
        });
        setUpdatedUser(() => {
          let newCurrentUser = { ...response.user };
          newCurrentUser.roles = [];
          response.user.roles.forEach((role) => {
            newCurrentUser.roles.push(role.uuid);
          });
          return newCurrentUser;
        });
        setAllRoles(response.all_roles);
        setAllProperties(() => {
          const sortedProperties = response.all_properties.sort((a, b) => a.label.localeCompare(b.label));
          return sortedProperties;
        });
        setAllPropertyTypes(response.all_property_types.propertyTypes);

        setCanEdit(response.can_edit);
        setCanDelete(response.can_delete);

        setCanListUserProperties(response.can_list_user_properties);
        setCanCreateUserProperties(response.can_create_user_properties);
        setCanEditUserProperties(response.can_edit_user_properties);
        setCanDeleteUserProperties(response.can_delete_user_properties);

        setCurrentUserRoles(() => {
          let newCurrentUserRoles = [];
          response.user.roles.forEach((role) => {
            newCurrentUserRoles.push(role.uuid);
          });
          return newCurrentUserRoles;
        });
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        if ('errorType' in e && e.errorType === 'TypeError') {
          let stackTrace = e.errorMessage + '\n';
          e.stackTrace.forEach((line) => {
            stackTrace += line;
          });
          showNotification(e.errorType, stackTrace, 'danger');
        } else {
          showNotification('Access denied', 'You do not have permission to edit users.', 'warning');
        }
      });
  };

  const deleteUser = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, '/user-management/users/' + currentUser.username, 'del')
      .then(() => {
        window.location = '/user-management/';
      })
      .catch(() => {
        showNotification('Access denied', 'You do not have permission to delete users.', 'warning');
      });
  };

  const onInputChange = (field, value) => {
    let _newUserData = { ...updatedUser };
    if (field === 'phoneNumber') value = value.replace(/\s/g, '');
    _newUserData[field] = '+' + value;
    setUpdatedUser(_newUserData);
  };

  const onRolesChange = (e) => {
    let newUserRoles = [...currentUserRoles];
    const role = {
      field: e.target.name,
      value: e.target.value,
      status: e.target.checked,
    };
    if (role.status === true) {
      newUserRoles.push(role.value);
      setCurrentUserRoles(newUserRoles);
    } else if (role.status === false) {
      newUserRoles = newUserRoles.filter((userRole) => userRole !== role.value);
      setCurrentUserRoles(newUserRoles);
    }

    let newUserData = { ...updatedUser };
    newUserData.roles = newUserRoles;
    setUpdatedUser(newUserData);
  };

  const onFormSubmit = async (e = null) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    const options = {
      body: updatedUser,
    };

    await apiGatewayCall(config.api_name, '/user-management/users/' + userId, 'post', options)
      .then(() => {
        getUser();
        showNotification('Completed', updatedUser.fullName + ' was successfully updated', 'success');
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        showNotification('Access denied', 'You do not have permission to edit users.', 'warning');
      });
  };

  const resetMfa = async (e) => {
    e.preventDefault();
    setConfirmResetMfaModalOpen(true);
  };
  const toggleConfirmResetMfaModalOpen = () => {
    setConfirmResetMfaModalOpen(!confirmResetMfaModalOpen);
  };
  const confirmResetMfa = async (username) => {
    await apiGatewayCall(config.api_name, '/user-management/users/' + username + '/disable-mfa', 'get')
      .then((response) => {
        if (response.statusCode === 400) {
          showNotification(
            'Error',
            'An error occurred. Check the user definitely exists and has confirmed their account.',
            'warning'
          );
        } else if (response.statusCode === 200) {
          showNotification(
            'Update successful',
            'MFA for user ' + currentUser.email + ' was successfully reset.',
            'success'
          );
          toggleConfirmResetMfaModalOpen();
        }
      })
      .catch(() => {
        showNotification('Access denied', 'You do not have permission to edit users.', 'warning');
      });
  };

  const updateProperties = (newProperty) => {
    let newUpdatedUser = { ...updatedUser };

    let propertyExists = false;
    newUpdatedUser.properties.forEach((existingProperty) => {
      if (existingProperty.uuid === newProperty.uuid) {
        propertyExists = true;
        existingProperty.value = newProperty.value;
      }
    });

    if (!propertyExists) {
      newUpdatedUser.properties.push(newProperty);
    }

    setUpdatedUser(newUpdatedUser);
    onFormSubmit();
  };

  const deleteUserProperty = (propertyId) => {
    let newUpdatedUser = { ...updatedUser };
    newUpdatedUser.properties = updatedUser.properties.filter(
      (existingProperty) => existingProperty.uuid !== propertyId
    );
    setUpdatedUser(newUpdatedUser);
  };

  let loadingContent = null;
  if (isLoading) loadingContent = <LoadingIcon />;

  let titleContent;
  let breadcrumbsContent;
  let userContent;
  let rolesContent;
  let submitContent;
  let resetMfaModal;
  let propertiesContent;
  let userReadOnlyContent;
  if (currentUser) {
    titleContent = (
      <MDBRow>
        <MDBCol size='12'>
          <h1 className='page-title'>{currentUser.fullName}</h1>
        </MDBCol>
      </MDBRow>
    );
    breadcrumbsContent = (
      <MDBRow>
        <MDBCol>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>
              <a href='/'>Home</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <a href='/user-management'>User directory</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>{currentUser.fullName}</MDBBreadcrumbItem>
          </MDBBreadcrumb>
        </MDBCol>
      </MDBRow>
    );
    userContent = (
      <MDBRow>
        <MDBCol>
          <MDBCard className='mb-4'>
            <MDBCardBody>
              <MDBCardTitle>Details</MDBCardTitle>
              <MDBInput
                valueDefault={currentUser.fullName}
                label='Full name'
                getValue={(e) => onInputChange('fullName', e)}
                maxLength='29'
              />
              <MDBInput
                valueDefault={currentUser.email}
                label='Email address'
                getValue={(e) => onInputChange('email', e)}
              />
              {
                <PhoneInput
                  onChange={(e) => onInputChange('phoneNumber', e)}
                  country={'gb'}
                  value={currentUser.phoneNumber}
                  preferredCountries={['gb', 'cl']}
                  inputProps={{ className: 'form-control', id: 'phoneNumber', required: true }}
                  autoFormat={true}
                  countryCodeEditable={false}
                  prefix='+'
                  isValid={(inputNumber, country) => {
                    if (inputNumber.slice(country.dialCode.length).charAt(0) === '0') {
                      return 'Invalid value: ' + inputNumber + ', No first zero please';
                    }
                    return true;
                  }}
                />
              }
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
    submitContent = (
      <MDBRow>
        <MDBCol size='12'>
          <MDBBtn tag='a' href='/user-management/' color='secondary' className='float-left'>
            Back
          </MDBBtn>
          {canEdit && (
            <MDBBtn onClick={(e) => resetMfa(e)} className='float-left'>
              Reset MFA for this user
            </MDBBtn>
          )}
          {canDelete && (
            <MDBDropdown className='float-left'>
              <MDBDropdownToggle caret color='danger'>
                Delete
              </MDBDropdownToggle>
              <MDBDropdownMenu basic>
                <MDBDropdownItem onClick={(e) => deleteUser()}>Delete</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          )}
          {canEdit && (
            <MDBBtn type='submit' className='float-right'>
              Save
            </MDBBtn>
          )}
        </MDBCol>
      </MDBRow>
    );
    resetMfaModal = (
      <MDBModal isOpen={confirmResetMfaModalOpen} toggle={toggleConfirmResetMfaModalOpen}>
        <MDBModalHeader toggle={toggleConfirmResetMfaModalOpen}>Reset MFA</MDBModalHeader>
        <MDBModalBody>
          <p>Are you sure you want to reset MFA settings for {currentUser.fullName}?</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color='secondary' onClick={toggleConfirmResetMfaModalOpen} className='float-left'>
            Cancel
          </MDBBtn>
          <MDBBtn color='primary' className='float-right' onClick={(e) => confirmResetMfa(currentUser.username)}>
            OK
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );

    let emailVerified = false;
    let mfaSetUp = false;
    if (currentUser.emailVerified === 'true') emailVerified = true;
    if (currentUser.mfa === 'SOFTWARE_TOKEN_MFA') mfaSetUp = true;

    userReadOnlyContent = (
      <MDBRow>
        <MDBCol>
          <MDBCard className='mb-4'>
            <MDBCardBody>
              <MDBInput valueDefault={currentUser.username} label='Cognito user ID (cannot be changed)' disabled />
              <MDBInput
                valueDefault={moment(profileData.dateCreated).format('YYYY-MM-DD')}
                label='Cognito Date created (cannot be changed)'
                disabled
              />
              <MDBInput
                valueDefault={
                  new Date(profileData.adminAppCreated).valueOf() !== new Date(profileData.dateCreated).valueOf()
                    ? moment(profileData.adminAppCreated).format('YYYY-MM-DD')
                    : 'Did not originate from the Admin App'
                }
                label='(Admin App) Date created (cannot be changed)'
                disabled
              />
              <MDBInput type='checkbox' disabled label='Email verified' checked={emailVerified} />
              <MDBInput type='checkbox' disabled label='MFA set up' checked={mfaSetUp} />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
    if (canListUserProperties) {
      propertiesContent = (
        <UserProperties
          setIsLoading={setIsLoading}
          updatedUser={updatedUser}
          allProperties={allProperties}
          updateProperties={updateProperties}
          canCreateUserProperties={canCreateUserProperties}
          canEditUserProperties={canEditUserProperties}
          canDeleteUserProperties={canDeleteUserProperties}
          deleteUserProperty={deleteUserProperty}
          allPropertyTypes={allPropertyTypes}
        />
      );
    }
  }
  if (currentUserRoles) {
    rolesContent = (
      <MDBRow>
        <MDBCol>
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Roles</MDBCardTitle>

              {allRoles.map((role) => {
                const checkboxId = 'checkbox-' + role.uuid;
                return (
                  <div className='form-check' key={role.name}>
                    <input
                      type='checkbox'
                      name='roles'
                      value={role.uuid}
                      id={checkboxId}
                      className='form-check-input'
                      onChange={(e) => onRolesChange(e)}
                      defaultChecked={currentUserRoles.includes(role.uuid) ? true : false}
                    />
                    <label htmlFor={checkboxId} className='form-check-label'>
                      {role.label}
                    </label>
                  </div>
                );
              })}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    );
  }

  return (
    <>
      {loadingContent}
      {titleContent}
      {breadcrumbsContent}
      <form onSubmit={(e) => onFormSubmit(e)}>
        {userContent}
        {propertiesContent}
        {rolesContent}
        {userReadOnlyContent}
        {submitContent}
      </form>
      {resetMfaModal}
    </>
  );
};

export default EditUser;

import React, { useEffect, useState, Fragment } from "react";
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardTitle, MDBCollapseHeader, MDBCollapse, MDBIcon } from "mdbreact";
import { Auth, API } from "aws-amplify";
import config from "../../content_types";
import LoadingIcon from "../../components/LoadingIcon";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import moment from 'moment';

const WorkflowMonitoringSummary = () => {
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [recordsByTime, setRecordsByTime] = useState([]);
  const [summaryOpen, setSummaryOpen] = useState(true);
  const [chartData, setChartData] = useState([]);

  const times = [2, 24, 48];

  let loadingContent = null;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (initiator = null) => {
    if (initiator === "refresh") {
      setRefreshLoading(true);
    }
    setLoading(true);
    const jwt = await Auth.currentSession();
    const user_name = jwt.accessToken.payload.username;
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: user_name,
      },
    };
    await API.get(config.api_name, "/workflow-instances/summary", requestOptions).then((response) => {
      if (response.statusCode === 200) {
        const records = response.body;
        buildSections(records);
        buildGraphData(records);
        setLoading(false);
        setRefreshLoading(false);
      }
    });
  };

  const buildGraphData = (records) => {
    let period = Math.max(...times)
    let dates = []
    let _chartData = []
    while (period >= 0) {
      const formattedDate = moment().subtract(period, 'hours').format('MMM DD')
      if(!dates.includes(formattedDate)){
        dates.push(formattedDate)
      }
      period--
    }
    dates.forEach(date => {
      let _dateData = {
        date: date,
        Successes: 0,
        Failures: 0
      }
      records.forEach(record => {
        if(moment(record.startedAt).format('MMM DD') === date){
          if (record.status === "WORKFLOW_ABORTED") {
            _dateData.Failures++
          } else {
            _dateData.Successes++
          }
        }
      })
      _chartData.push(_dateData)
    })

    setChartData(_chartData);
  };

  const buildSections = (records) => {
    let _recordsByTime = [];
    const currentTime = new Date().getTime() / 1000;

    times.forEach((time) => {
      let timeSuccess = [];
      let timeFail = [];
      records.forEach((record) => {
        const startTime = new Date(record.startedAt).getTime() / 1000;
        const timeDifference = (currentTime - startTime) / 3600;
        if (timeDifference < time) {
          if (record.status === "WORKFLOW_ABORTED") {
            timeFail.push(record.inputInstanceId);
          } else {
            timeSuccess.push(record.inputInstanceId);
          }
        }
      });
      _recordsByTime.push({
        time: time,
        success: timeSuccess,
        fail: timeFail,
      });
    });

    let recordsByTimeContent = [];
    _recordsByTime.forEach((time) => {
      recordsByTimeContent.push(
        <MDBCol key={time.time}>
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Last {time.time} hours</MDBCardTitle>
              <p className="workflow-monitoring-summary-numbers">
                <span className="success-text">{time.success.length}</span> /{" "}
                <span className="danger-text">{time.fail.length}</span>
              </p>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      );
    });
    setRecordsByTime(recordsByTimeContent.reverse());
  };

  const toggleCollapse = () => {
    setSummaryOpen(!summaryOpen);
  };

  const onRefreshClick = () => {
    fetchData("refresh");
  };

  if (loading === true) {
    loadingContent = <LoadingIcon fullHeight={false} />;
  }

  let summaryHeaderIcon = "angle-down";
  if (summaryOpen === true) summaryHeaderIcon = "times";

  let summaryRefreshIcon = null;
  if (loading === true && refreshLoading === true) {
    summaryRefreshIcon = <MDBIcon icon="sync" className="float-right clickable" spin />;
  } else if (loading === false && refreshLoading === false) {
    summaryRefreshIcon = <MDBIcon icon="sync" className="float-right clickable" onClick={onRefreshClick} />;
  }

  return (
    <Fragment>
      <MDBCard>
        {loadingContent}
        <MDBCollapseHeader onClick={toggleCollapse}>
          Summary <MDBIcon icon={summaryHeaderIcon} className="float-right" />
        </MDBCollapseHeader>
        <MDBCollapse id="summary-collapse" isOpen={summaryOpen}>
          <MDBCardBody>
            {summaryRefreshIcon}
            <MDBRow>{recordsByTime}</MDBRow>
            <MDBRow>
              <MDBCol>
                <ResponsiveContainer width="100%" height={200} className="mt-4">
                  <LineChart data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                    <Line type="monotone" dataKey="Successes" stroke="green" />
                    <Line type="monotone" dataKey="Failures" stroke="red" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="date" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                  </LineChart>
                </ResponsiveContainer>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCollapse>
      </MDBCard>
    </Fragment>
  );
};

export default WorkflowMonitoringSummary;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MDBCol } from "mdbreact";
import showNotification from "../helpers/showNotification";

const SearchForm = ({ canSearch }) => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");

  const onSearchFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === true) {
      const destinationUrl = "/search/" + searchTerm;
      history.push(destinationUrl);
    } else {
      showNotification("", "Please enter something to search.", "warning");
    }
  };

  const validateForm = () => {
    if (searchTerm !== "") {
      return true;
    } else {
      return false;
    }
  };

  let formContent = null;
  if (canSearch)
    formContent = (
      <MDBCol size="4">
        <form className="production-email-search-form" onSubmit={(e) => onSearchFormSubmit(e)}>
          <div className="input-group">
            <div className="input-group-prepend" onClick={(e) => onSearchFormSubmit(e)}>
              <span className="input-group-text" id="basic-addon">
                <i className="fa fa-search prefix"></i>
              </span>
            </div>
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
            />
          </div>
        </form>
      </MDBCol>
    );
  return formContent;
};

export default SearchForm;

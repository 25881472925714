import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdbreact";
import React from "react";
import SchemaInputSelectOptions from "./SchemaInputSelectOptions";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import slugify from "slugify";
import SchemaOoonaSettingsExternalGuid from "../SchemaOoonaSettingsExternalGuid";
import ExternalConfigurationSet from "../ExternalConfigurationSet";
import SchemaOoonaProjectPreview from "../SchemaOoonaProjectPreview";

const SchemaNewCurrentInput = ({ input, setSchema, configurationSets, allSchemas }) => {
  const updateInput = (inputId, field, value) => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      _schema.inputs.forEach((input) => {
        if (input.inputInstance.id === inputId) {
          if (field === "label") {
            const slugifiedLabel = slugify(value, { replacement: "_", lower: true });
            input.inputInstance.slug = slugifiedLabel;
          }
          input.inputInstance[field] = value;
        }
      });
      return _schema;
    });
  };

  const onDeleteInput = (e, inputId) => {
    e.preventDefault();
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      _schema.inputs = _schema.inputs.filter((input) => input.inputInstance.id !== inputId);
      return _schema;
    });
  };

  return (
    <MDBCard key={input.inputInstance.id}>
      <MDBCardBody>
        <MDBRow>
          <MDBCol>
            <h3 className="h5">
              {input.inputTemplate.label}
              <MDBIcon
                icon="times"
                onClick={(e) => onDeleteInput(e, input.inputInstance.id)}
                className="clickable float-right"
              />
            </h3>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            {input.inputTemplate.slug !== "ooona-project-preview-button" && (
              <>
                <MDBInput
                  type="text"
                  getValue={(e) => updateInput(input.inputInstance.id, "label", e)}
                  label="Field label"
                  containerClass="mb-0"
                  value={input.inputInstance.label}
                />
                <p>The field label is the label shown to the user, and can contain whatever characters you want.</p>
              </>
            )}

            {["text", "number", "email", "telephone-number", "textarea", "select", "checkbox", "json"].includes(
              input.inputTemplate.slug
            ) && (
              <>
                <MDBInput
                  type="text"
                  getValue={(e) => updateInput(input.inputInstance.id, "slug", e)}
                  label="Field slug"
                  containerClass="mb-0"
                  value={input.inputInstance.slug}
                />
                <p>
                  The field slug is an all lower-case name used behind the scenes to identify the field. Keep this to
                  letters and numbers, and use underscores (_) instead of spaces.
                </p>
              </>
            )}
            {["text", "number", "email", "telephone-number", "textarea"].includes(input.inputTemplate.slug) && (
              <>
                <MDBInput
                  type="text"
                  label="Default value"
                  getValue={(e) => updateInput(input.inputInstance.id, "default", e)}
                  containerClass="mb-0"
                  value={input.inputInstance.default}
                />
                <p>The value to be displayed by default for this field.</p>
              </>
            )}
            {input.inputTemplate.slug === "select" && <SchemaInputSelectOptions setSchema={setSchema} input={input} />}
            {input.inputTemplate.slug === "ooona-settings-external-guid" && (
              <SchemaOoonaSettingsExternalGuid updateInput={updateInput} input={input} canEdit={true} />
            )}
            {input.inputTemplate.slug === "ooona-project-preview-button" && (
              <SchemaOoonaProjectPreview />
            )}
            {input.inputTemplate.slug === "configuration-set-data" && (
              <ExternalConfigurationSet
                updateInput={updateInput}
                input={input}
                allSchemas={allSchemas}
                canEdit={true}
              />
            )}
          </MDBCol>
          <MDBCol>
            <h4 className="h6">Preview</h4>
            {input.inputTemplate.slug === "text" && (
              <MDBInput type="text" label={input.inputInstance.label} value={input.inputInstance.default} />
            )}
            {input.inputTemplate.slug === "number" && (
              <MDBInput type="number" label={input.inputInstance.label} value={input.inputInstance.default} />
            )}
            {input.inputTemplate.slug === "email" && (
              <MDBInput type="email" label={input.inputInstance.label} value={input.inputInstance.default} />
            )}
            {input.inputTemplate.slug === "checkbox" && (
              <MDBInput type="checkbox" label={input.inputInstance.label} value={input.inputInstance.slug} />
            )}
            {input.inputTemplate.slug === "telephone-number" && (
              <MDBInput type="tel" label={input.inputInstance.label} value={input.inputInstance.default} />
            )}
            {input.inputTemplate.slug === "textarea" && (
              <MDBInput type="textarea" label={input.inputInstance.label} value={input.inputInstance.default} />
            )}
            {input.inputTemplate.slug === "ooona-settings-external-guid" && "values" in input.inputInstance && (
              <MDBBtn>Generate {input.inputInstance.values.settingsType}</MDBBtn>
            )}
            {input.inputTemplate.slug === "configuration-set-data" && (
              <select className="browser-default custom-select">
                <option value="0">{input.inputInstance.label}</option>
                {configurationSets.length && input.inputInstance.parentSchema ? (
                  configurationSets.map((configurationSet) => {
                    if (configurationSet.schema.uuid === input.inputInstance.parentSchema) {
                      return (
                        <option key={configurationSet.uuid} value={configurationSet.uuid}>
                          {configurationSet.label}
                        </option>
                      );
                    } else {
                      return false;
                    }
                  })
                ) : (
                  <option disabled>No configuration sets available</option>
                )}
              </select>
            )}
            {input.inputTemplate.slug === "json" && (
              <>
                <label htmlFor={"example-json-" + input.inputInstance.slug}>{input.inputInstance.label}</label>
                <JSONInput
                  id={"example-json-" + input.inputInstance.slug}
                  locale={locale}
                  theme="light_mitsuketa_tribute"
                  colors={{ default: "#495057" }}
                  height="auto"
                  style={{
                    body: {
                      border: "1px solid #ced4da",
                      borderRadius: "0.25rem",
                      color: "#495057",
                      minHeight: "200px",
                    },
                  }}
                />
              </>
            )}
            {input.inputTemplate.slug === "select" && (
              <select className="browser-default custom-select">
                <option value="0">Select an input to add</option>
                {input.inputInstance.options.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  );
                })}
              </select>
            )}
            {input.inputTemplate.slug === "ooona-project-preview-button" && (
              <MDBBtn>Open preview</MDBBtn>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaNewCurrentInput;

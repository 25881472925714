import React from "react";
import { Link } from "react-router-dom";

import { MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";

const WorkflowListingToolbar = ({
  refreshRecords,
  newRecordUrl,
  canCreate
}) => {
  return (
    <MDBRow>
      <MDBCol>
        <div className="mb-2">
          <MDBBtn color="primary" onClick={refreshRecords}>
            <MDBIcon icon="sync" className="mr-2" /> Refresh
          </MDBBtn>{" "}
          {canCreate ? (
            <MDBBtn color="primary" tag={Link} to={newRecordUrl}>
              <MDBIcon icon="plus-circle" className="mr-2" /> Add new
            </MDBBtn>
          ) : (
            ""
          )}
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default WorkflowListingToolbar;

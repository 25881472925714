import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBBtn,
  MDBIcon
} from "mdbreact";
import Assembly from "./Assembly";

const Compiling = ({
  compilingInstructions,
  showViewButton,
  workflowViewClick,
  newWorkflowClick,
  canWrite,
  showAddButton,
  newWorkflowItemClick
}) => {
  let compilingContent = [];
  compilingInstructions.forEach(compilingInstruction => {
    let key =
      "compiling-instruction-" +
      compilingInstruction.serviceTemplateCompilingInstructionId;
    let compilingInstructionContent = (
      <MDBCard key={key}>
        <MDBCardBody>
          <MDBCardTitle>
            {compilingInstruction.serviceTemplateCompilingInstructionId}:{" "}
            {compilingInstruction.compiling_instruction_template.name}{" "}
            {showViewButton ? (
              <MDBBtn
                onClick={e =>
                  workflowViewClick(
                    "CI",
                    compilingInstruction.serviceTemplateCompilingInstructionId
                  )
                }
                className="float-right"
              >
                Edit
              </MDBBtn>
            ) : (
              ""
            )}
          </MDBCardTitle>
          <MDBCardText tag="div">
            <p>
              {compilingInstruction.compiling_instruction_template.description}
            </p>
            <h3>Assembly instructions</h3>
          </MDBCardText>
          {compilingInstruction.assembly_instructions.length ? (
            <Assembly
              assemblyInstructions={compilingInstruction.assembly_instructions}
              showViewButton={showViewButton}
              workflowViewClick={workflowViewClick}
              canWrite={canWrite}
              showAddButton={showAddButton}
            />
          ) : (
            ""
          )}
          {canWrite && showAddButton ? (
            <div className="new-workflow-item">
              <MDBBtn
                color="primary"
                onClick={e => newWorkflowItemClick(e, "AI")}
                // onClick={e =>
                //   newWorkflowClick(
                //     "AI",
                //     compilingInstruction.serviceTemplateCompilingInstructionId,
                //     compilingInstruction.serviceTemplateId
                //   )
                // }
              >
                <MDBIcon icon="plus-circle" className="mr-2" /> Add new assembly
                instruction
              </MDBBtn>
            </div>
          ) : (
            ""
          )}
        </MDBCardBody>
      </MDBCard>
    );
    compilingContent.push(compilingInstructionContent);
  });
  return compilingContent;
};

export default Compiling;

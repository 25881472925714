import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdbreact";
import React, { useState } from "react";
import uuid from "react-uuid";

const SchemaInputSelectOptions = ({ input, setSchema }) => {
  const [newSelectOptionValue, setNewSelectOptionValue] = useState("");
  const [newSelectOptionText, setNewSelectOptionText] = useState("");

  const onNewSelectOptionFormSubmit = (e, inputId) => {
    e.preventDefault();
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      _schema.inputs.forEach((input) => {
        if (input.inputInstance.id === inputId) {
          const newOptionId = uuid();
          input.inputInstance.options.push({ text: newSelectOptionText, value: newSelectOptionValue, id: newOptionId });
          setNewSelectOptionValue("");
          setNewSelectOptionText("");
        }
      });
      return _schema;
    });
  };

  const onOptionDelete = (e, inputId, id) => {
    e.preventDefault();
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      _schema.inputs.forEach((input) => {
        if (input.inputInstance.id === inputId) {
          input.inputInstance.options = input.inputInstance.options.filter((option) => option.id !== id);
        }
      });
      return _schema;
    });
  };

  return (
    <MDBCard className="schema-input-dropdown-options">
      <MDBCardBody>
        <h4 className="h6">Dropdown options</h4>
        <MDBRow>
          <MDBCol size="5">
            <MDBInput
              label="New option text"
              getValue={(e) => setNewSelectOptionText(e)}
              value={newSelectOptionText}
              className="mt-0"
            />
          </MDBCol>
          <MDBCol size="5">
            <MDBInput
              label="New option value"
              getValue={(e) => setNewSelectOptionValue(e)}
              value={newSelectOptionValue}
              className="mt-0"
            />
          </MDBCol>
          <MDBCol size="2">
            <MDBBtn onClick={(e) => onNewSelectOptionFormSubmit(e, input.inputInstance.id)} className="m-0 px-4">
              Add
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <table className="table">
          <thead>
            <tr>
              <th>Text</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {input.inputInstance.options.map((option) => {
              return (
                <tr>
                  <td>{option.text}</td>
                  <td>{option.value}</td>
                  <td>
                    <MDBIcon
                      icon="times"
                      onClick={(e) => onOptionDelete(e, input.inputInstance.id, option.id)}
                      className="clickable"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaInputSelectOptions;

import React from "react";

import { MDBDataTable } from "mdbreact";

const RecordsListing = ({ records, openDetailModal, idField }) => {
  if (records && records.length > 0) {
    let fields = Object.keys(records[0]);
    let columns = [];

    fields.map(field => {
      if (field !== "clickEvent") {
        columns.push({
          label: field,
          field: field,
          sort: "asc"
        });
        return true;
      } else {
        return false;
      }
    });

    let recordsCount = 0;
    records.map(record => {
      records[recordsCount].clickEvent = e => {
        openDetailModal(e, record[idField]);
      };
      recordsCount++;
      return true;
    });

    let tableData = {};
    tableData.columns = columns;
    tableData.rows = records;

    return (
      <MDBDataTable
      barReverse
        responsive
        striped
        hover
        entries={20}
        entriesOptions={[20, 50, 100]}
        data={tableData}
        className="clickable-table"
      />
    );
  } else {
    return <p>No results found.</p>;
  }
};

export default RecordsListing;

import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import React from 'react';
import Address from './Address';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ContactDetails = ({ userData, updateUserData, countries }) => {
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag='h2' className='h4'>
          Contact details
        </MDBCardTitle>
        <div className='form-group'>
          <label htmlFor='emailAddress'>
            Email address <span className='red-text'>*</span>
          </label>
          <input
            required
            type='email'
            className='form-control'
            id='emailAddress'
            onChange={(e) => updateUserData(e)}
            defaultValue={userData.emailAddress}
          />
          <div className='invalid-feedback'>Please enter your email address.</div>
        </div>
        <div className='form-group'>
          <label htmlFor='phoneNumber'>
            Mobile number <span className='red-text'>*</span>
          </label>
          <PhoneInput
            required
            onChange={(phoneNumber) =>
              updateUserData({ target: { id: 'phoneNumber', value: '+' + phoneNumber.replace(/\s/g, '') } })
            }
            country={'gb'}
            value={userData.phoneNumber}
            preferredCountries={['gb', 'cl']}
            inputProps={{ className: 'form-control', id: 'phoneNumber' }}
            autoFormat={true}
            countryCodeEditable={false}
            prefix='+'
            isValid={(inputNumber, country) => {
              if (inputNumber.slice(country.dialCode.length).charAt(0) === '0') {
                return 'Invalid value: ' + inputNumber + ', No first zero please';
              }
              return true;
            }}
          />
          <div className='invalid-feedback'>Please enter your mobile number.</div>
        </div>
        <div className='form-group'>
          <label htmlFor='landlineNumber'>Landline number</label>
          <PhoneInput
            onChange={(phoneNumber) =>
              updateUserData({ target: { id: 'landlineNumber', value: '+' + phoneNumber.replace(/\s/g, '') } })
            }
            country={'gb'}
            value={userData.landlineNumber}
            preferredCountries={['gb', 'cl']}
            inputProps={{ className: 'form-control', id: 'landlineNumber' }}
            autoFormat={true}
            countryCodeEditable={false}
            prefix='+'
            isValid={(inputNumber, country) => {
              if (inputNumber.slice(country.dialCode.length).charAt(0) === '0') {
                return 'Invalid value: ' + inputNumber + ', No first zero please';
              }
              return true;
            }}
          />
        </div>
        <Address userData={userData} updateUserData={updateUserData} countries={countries} />
      </MDBCardBody>
    </MDBCard>
  );
};

export default ContactDetails;

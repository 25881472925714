import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Merging from "./Merging";
import Despatch from "./Despatch";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon
} from "mdbreact";

const ServiceTemplateDetailsModal = ({
  detailModalOpen,
  triggerDetailsModal,
  serviceTemplate,
  canWrite
}) => {
  useEffect(() => {
    updateModalContent();
    // eslint-disable-next-line
  }, [serviceTemplate]);

  const [title, setTitle] = useState("");
  const [serviceTemplateContent, setServiceTemplateContent] = useState("");
  const [workflowContent, setWorkflowContent] = useState([]);
  const [editUrl, setEditUrl] = useState("");

  const updateModalContent = () => {
    if (serviceTemplate) {
      setTitle(serviceTemplate.referenceName);

      let mergingInstructions = serviceTemplate.merging_instructions;
      let despatchInstructions = serviceTemplate.despatch_instructions;
      let despatchInstructionsFriendly =
        serviceTemplate.despatch_instructions_friendly;

      const editUrl = "/service-templates/" + serviceTemplate.serviceTemplateId;
      setEditUrl(editUrl);

      const mainDetails = () => {
        let serviceTemplateDetails = serviceTemplate;
        if (serviceTemplateDetails.ingredientSetId !== 0) {
          serviceTemplateDetails.ingredientSetId =
            serviceTemplateDetails.ingredient_set.displayName;
        }
        const hiddenFields = [
          "assembly_instructions",
          "ingredient_set",
          "merging_instructions",
          "despatch_instructions",
          "despatch_instructions_friendly"
        ];
        Object.keys(serviceTemplateDetails).forEach(tableField => {
          if (hiddenFields.includes(tableField)) {
            delete serviceTemplateDetails[tableField];
          }
        });

        let valueCount = 0;
        let table = (
          <div className="mb-4">
            <MDBTable responsive>
              <MDBTableHead>
                <tr>
                  {Object.keys(serviceTemplateDetails).map(field => {
                    return <th key={field}>{field}</th>;
                  })}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                <tr>
                  {Object.values(serviceTemplateDetails).map(value => {
                    const valueId = "value-" + valueCount;
                    valueCount++;
                    return <td key={valueId}>{value}</td>;
                  })}
                </tr>
              </MDBTableBody>
            </MDBTable>
          </div>
        );

        setServiceTemplateContent(table);
      };

      const workflowDetails = () => {
        let workflowDetailsContent = [<h3 key="workflow-title">Workflow</h3>];
        if (mergingInstructions.length) {
          workflowDetailsContent.push(
            <h3 key="merging-instructions-title">Merging instructions</h3>
          );
          workflowDetailsContent.push(
            <Merging
              key="merging-instructions"
              mergingInstructions={mergingInstructions}
              canWrite={canWrite}
            />
          );
        }
        if (despatchInstructions.length) {
          workflowDetailsContent.push(
            <h3 key="despatch-instructions-title">Despatch instructions</h3>
          );
          workflowDetailsContent.push(
            <Despatch
              key="despatch-instructions"
              despatchInstructions={despatchInstructions}
              despatchInstructionsFriendly={despatchInstructionsFriendly}
              canWrite={canWrite}
            />
          );
        }
        setWorkflowContent(workflowDetailsContent);
      };

      mainDetails();
      workflowDetails();
    }
  };

  return (
    <MDBModal
      isOpen={detailModalOpen}
      toggle={triggerDetailsModal}
      size="fluid"
      className="listing-detail-modal"
    >
      <MDBModalHeader toggle={triggerDetailsModal} tag="h2">
        {title}
      </MDBModalHeader>
      <MDBModalBody>
        {serviceTemplateContent}
        {workflowContent}
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" onClick={triggerDetailsModal}>
          Close
        </MDBBtn>
        {canWrite ? (
          <MDBBtn color="primary" tag={Link} to={editUrl}>
            <MDBIcon icon="edit" className="mr-2" /> Edit
          </MDBBtn>
        ) : (
          ""
        )}
      </MDBModalFooter>
    </MDBModal>
  );
};

export default ServiceTemplateDetailsModal;

import { v4 as uuidv4 } from "uuid";
import config from "../../../config";

const randomString = (length) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const generateData = (totalRequested, existingRequested, nonMpRequested) => {
  const generateLegacyId = () => {
    return Math.floor(Math.random() * 100) + 1;
  };

  let dummyUsers = [];
  let existingCount = 0;
  let nonMpCount = 0;
  let legacyIds = [];

  for (let index = 0; index < totalRequested; index++) {
    let t1ud_uuid = "";
    let mp_LegacyAgentHash_mp_legacy_agent_hash = randomString(16);

    let legacyId = generateLegacyId();
    if (legacyIds.includes(legacyId)) legacyId = generateLegacyId();

    const fullName =
      randomString(Math.floor(Math.random() * 10) + 1) + " " + randomString(Math.floor(Math.random() * 10) + 1);
    const email = randomString(10) + "@jgroome.com";

    if (existingCount < existingRequested) {
      const isExisting = Math.random() >= 0.5;
      if (isExisting) {
        t1ud_uuid = uuidv4().toString();
        existingCount++;
      }
    }
    if (nonMpCount < nonMpRequested) {
      const isNonMp = Math.random() >= 0.5;
      if (isNonMp) {
        mp_LegacyAgentHash_mp_legacy_agent_hash = "";
        nonMpCount++;
      }
    }

    let isEmployee = Math.round(Math.random());
    const isTypist = Math.round(Math.random());
    const isCaptioner = Math.round(Math.random());
    const isTranslator = Math.round(Math.random());

    let defaultRoles = [];
    if (isTypist === 1) {
      if (config.environmentName === "development") {
        defaultRoles.push("60ebd83e-1cf8-11eb-8589-238e7af8d39e");
      } else if (config.environmentName === "staging") {
        defaultRoles.push("51292839-24d6-11eb-a8ac-d9fb4dc0da9e");
      }
    }
    if (isCaptioner === 1) {
      if (config.environmentName === "development") {
        defaultRoles.push("04a0b0af-f1e0-11ea-bc0c-8f1c5f6b5346");
      } else if (config.environmentName === "staging") {
        defaultRoles.push("0f0c0382-24d6-11eb-9f15-d9fb4dc0da9e");
      }
    }
    if (isTranslator === 1) {
      if (config.environmentName === "development") {
        defaultRoles.push("40d11b0a-1cf8-11eb-9a80-238e7af8d39e");
      } else if (config.environmentName === "staging") {
        defaultRoles.push("2082dc15-24d6-11eb-a924-d9fb4dc0da9e");
      }
    }

    if (isTypist === 0 && isCaptioner === 0 && isTranslator === 0) isEmployee = 1;

    if (isEmployee === 1) {
      if (config.environmentName === "development") {
        defaultRoles.push("545e4e34-1cf8-11eb-8af9-238e7af8d39e");
      } else if (config.environmentName === "staging") {
        defaultRoles.push("42fca630-24d6-11eb-b78c-d9fb4dc0da9e");
      }
    }

    let randomUser = {
      t1ud_uuid: t1ud_uuid,
      EmployeeId_legacy_emp_id: legacyId,
      Email: email,
      EmpFirstNamePlusEmpLastName_full_name: fullName,
      email: email,
      fullName: fullName,
      EmpMobileNo_phone_number: "07793679245",
      mp_LegacyAgentHash_mp_legacy_agent_hash: mp_LegacyAgentHash_mp_legacy_agent_hash,
      EmployeeStatus_status: "Current",
      Notes_notes: "Notes10",
      IsEmployee_aa_is_employee: isEmployee,
      IsTypist_aa_is_typist: isTypist,
      IsTranslator_aa_is_translator: isTranslator,
      IsCaptioner_aa_is_captioner: isCaptioner,
      DateAdded_date_added: "1998-01-01T00:00:00.000Z",
      WorkLocation_work_location: "In our Office",
      NetworkUsername_network_username: "dbourne",
      defaultRoles: defaultRoles,
      properties: [
        { uuid: "017475fc-149e-11eb-8070-45eca2542953", value: "111", inputType: "text" },
        { uuid: "df1cefa7-149d-11eb-8779-45eca2542953", value: "2C819553E8A1541F", inputType: "text" },
        { uuid: "68f738ee-f432-11ea-a8f8-d9650efe86d0", value: "Current", inputType: "text" },
        { uuid: "73470303-f432-11ea-8359-d9650efe86d0", value: "Notes111", inputType: "text" },
        { uuid: "67d0fb37-1e8a-11eb-9cc5-0952ab5ec5d9", value: "From Home", inputType: "text" },
        { uuid: "92f0a004-f432-11ea-a0b8-d9650efe86d0", value: "jsilvey", inputType: "text" },
      ],
    };

    dummyUsers.push(randomUser);
  }

  return {
    statusCode: 200,
    users: dummyUsers,
  };
};

let dummyData = generateData(5, 1, 0);
// dummyData.users[0].t1ud_uuid = '4c57d825-abe1-4cc5-ab27-a6e4c59796af'
// dummyData.users[1].t1ud_uuid = 'bd0e27f4-c97d-450e-b621-f66d9822f54c'
// dummyData.users[2].t1ud_uuid = '7ee3c79d-1bcd-4df9-8a86-6ccf7525cbbb'

export default dummyData;

import React, { Fragment, useState, useEffect } from "react";
import { MDBBtn, MDBIcon } from "mdbreact";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import RecordsListing from "../RecordsListing";
import WorkflowLocationsDetailModal from "./WorkflowLocationsDetailModal";
import WorkflowLocationsNewModal from "./WorkflowLocationsNewModal";

const WorkflowLocationsListing = ({ content_type, setLoading }) => {
  setPageTitle("Workflow connections");

  const [allRecords, setAllRecords] = useState([]);
  const [shortenedRecords, setShortenedRecords] = useState([]);

  const [canEdit, setCanEdit] = useState(false);
  const [canCreate, setCanCreate] = useState(false);

  const filterQueryString = "";

  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [currentWorkflowLocation, setCurrentWorkflowLocation] = useState(null);

  const [newModalOpen, setNewModalOpen] = useState(false);
  const [newModalFields, setNewModalFields] = useState(null);

  let content_config = config[content_type];

  useEffect(() => {
    fetchData(filterQueryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shortenRecords = (records) => {
    let count = 0;
    let shortenedRecords = [];
    const visible_fields = content_config.listing.visible_fields;
    if (records.length > 0) {
      records.map((record) => {
        shortenedRecords[count] = {};
        visible_fields.forEach((field) => {
          shortenedRecords[count][field] = record[field];
          if (shortenedRecords[count][field] == null) {
            shortenedRecords[count][field] = "NULL";
          }
        });
        count++;
        return true;
      });
    }
    return shortenedRecords;
  };

  const fetchData = async () => {
    setLoading(true);
    const jwt = await Auth.currentSession();
    const user_name = jwt.accessToken.payload.username;
    const jwtToken = jwt.idToken.jwtToken;
    const requestOptions = {
      headers: {
        Authorization: jwtToken,
        user_name: user_name,
      },
    };
    await API.get(config.api_name, content_config.listing.api_url, requestOptions).then((response) => {
      if (response.statusCode === 200) {
        const records = response.workflow_locations_friendly;
        setAllRecords(records);
        setCanEdit(response.can_edit);
        setCanCreate(response.can_create);
        // setCanDelete(response.can_delete);
        setShortenedRecords(shortenRecords(records));
        setLoading(false);
      } else {
        alert("Not authorised!");
        window.location.href = "/";
      }
    });
  };

  const triggerDetailsModal = async (e, recordId) => {
    const resetDetailsModalContent = () => {
      setCurrentWorkflowLocation(null);
    };
    if (detailModalOpen === true) {
      setDetailModalOpen(false);
      resetDetailsModalContent();
    } else {
      setDetailModalOpen(true);
      e.preventDefault();

      setLoading(true);

      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
          user_name: jwt.accessToken.payload.username,
        },
      };
      await API.get(config.api_name, content_config.listing.api_url + "/" + recordId, requestOptions).then(
        (response) => {
          setLoading(false);
          response && response.workflow_location
            ? setCurrentWorkflowLocation(response.workflow_location)
            : setCurrentWorkflowLocation({});
        }
      );
    }
  };

  const triggerNewModal = async (e) => {
    if (newModalOpen === true) {
      setNewModalOpen(false);
    } else {
      setNewModalOpen(true);
      e.preventDefault();

      setLoading(true);

      const jwt = await Auth.currentSession();
      const jwtToken = jwt.idToken.jwtToken;
      const requestOptions = {
        headers: {
          Authorization: jwtToken,
          user_name: jwt.accessToken.payload.username,
        },
      };
      await API.get(config.api_name, content_config.listing.api_url + "/fields", requestOptions).then((response) => {
        setLoading(false);
        response && response.form_values ? setNewModalFields(response.form_values) : setNewModalFields(null);
      });
    }
  };

  let recordsListing = "";

  if (allRecords) {
    recordsListing = (
      <>
        <div className="mb-2">
          <MDBBtn color="primary" onClick={(e) => fetchData()}>
            <MDBIcon icon="sync" className="mr-2" /> Refresh
          </MDBBtn>{" "}
          {canCreate ? (
            <MDBBtn
              color="primary"
              onClick={(e) => {
                triggerNewModal(e);
              }}
            >
              <MDBIcon icon="plus-circle" className="mr-2" /> Add new
            </MDBBtn>
          ) : (
            ""
          )}
        </div>
        <RecordsListing
          records={shortenedRecords}
          openDetailModal={triggerDetailsModal}
          idField={content_config.listing.id_field}
        />
        <WorkflowLocationsDetailModal
          detailModalOpen={detailModalOpen}
          triggerDetailsModal={triggerDetailsModal}
          workflowLocation={currentWorkflowLocation}
          canEdit={canEdit}
        />
        <WorkflowLocationsNewModal
          newModalOpen={newModalOpen}
          triggerNewModal={triggerNewModal}
          newModalFields={newModalFields}
          canCreate={canCreate}
          fetchData={fetchData}
        />
      </>
    );
  }

  return (
    <Fragment>
      <h1 className="page-title">{content_config.title}</h1>
      {recordsListing}
    </Fragment>
  );
};

export default withRouter(WorkflowLocationsListing);

import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import { MDBBtn, MDBDataTable, MDBIcon, MDBInput } from "mdbreact";
import WorkflowConnectionsNewModal from "./WorkflowConnectionsNewModal";
import showNotification from "../../helpers/showNotification";

const WorkflowConnectionsListing = ({ content_type, setLoading }) => {
  setPageTitle("Workflow connections");

  const [allRecords, setAllRecords] = useState([]);
  const [fields, setFields] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);

  const [canCreate, setCanCreate] = useState(false);

  let content_config = config[content_type];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/workflow-connections/", "get")
      .then((response) => {
        setAllRecords(formatRows(response.connections));
        setFilteredRecords(() => {
          return formatRows(filterOutArchivedConnections(response.connections));
        });
        setFields(response.fields);
        setCanCreate(response.can_create);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        if (e.statusCode !== undefined && e.statusCode === 400) {
          showNotification("Error", "Something went wrong getting the list of connections.", "warning");
        } else {
          showNotification("Access denied", "You do not have permission to view workflow connections.", "warning");
        }
      });
  };

  const formatRows = (connections) => {
    let rows = [];
    connections.forEach((connection) => {
      connection.clickEvent = () => {
        window.location.href = "/workflow-configuration/connections/" + connection.id;
      };
      rows.push(connection);
    });
    return rows;
  };

  const saveNewRecord = async (newRecord) => {
    let options = {
      body: newRecord,
    };
    await apiGatewayCall(config.api_name, "/workflow-management/workflow-connections/", "post", options)
      .then(() => {
        showNotification("Success", newRecord.name + " was successfully created", "success");
        fetchData();
      })
      .catch((e) => {
        console.log(e);
        showNotification("Access denied", "You do not have permission to create components.", "warning");
      });
  };

  const filterOutArchivedConnections = (records) => {
    return records.filter((connection) => connection.status !== "Archived");
  };

  const onFilterChange = (e) => {
    e
      ? setFilteredRecords(formatRows(allRecords))
      : setFilteredRecords(formatRows(filterOutArchivedConnections(allRecords)));
  };

  let recordsListing;

  if (allRecords && filteredRecords) {
    recordsListing = (
      <>
        <div className="mb-2">
          <MDBBtn color="primary" onClick={(e) => fetchData()}>
            <MDBIcon icon="sync" className="mr-2" /> Refresh
          </MDBBtn>{" "}
          {canCreate && <WorkflowConnectionsNewModal fields={fields} saveNewRecord={saveNewRecord} />}
          <div className="float-right mt-3">
            <MDBInput
              type="checkbox"
              label="Include archived connections"
              id="input-filter-status"
              onChange={(e) => onFilterChange(e.target.checked)}
            />
          </div>
        </div>
        <MDBDataTable
          barReverse
          responsive
          striped
          hover
          searching={false}
          displayEntries={false}
          entries={20}
          entriesOptions={[20, 50, 100]}
          data={{
            columns: [
              // { label: "Connection ID",   field: "id" },
              { label: "Connection key", field: "key" },
              { label: "Name", field: "name" },
              { label: "Workflow", field: "workflow" },
              { label: "Status", field: "status" },
            ],
            rows: filteredRecords,
          }}
          className="clickable-table"
        />
        {/* <RecordsListing
          records={{
            columns: [
              { text: "Connection ID", field: "id" },
              { text: "Name", field: "name" },
              { text: "Workflow", field: "workflow" },
            ],
            rows: allRecords,
          }}
          idField="id"
        /> */}
      </>
    );
  }

  return (
    <Fragment>
      <h1 className="page-title">{content_config.title}</h1>
      {recordsListing}
    </Fragment>
  );
};

export default withRouter(WorkflowConnectionsListing);

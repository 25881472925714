import React, { Fragment, useState, useEffect } from "react";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import showNotification from "../../helpers/showNotification";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import LoadingIcon from "../../components/LoadingIcon";
import PersonalInformation from "./PersonalInformation";
import ConsoleData from "./ConsoleData";
import SetPassword from "./SetPassword";
import ContactDetails from "./ContactDetails";
import Take1Details from "./Take1Details";

const UserProfile = props => {
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState(null);

  const [userData, setUserData] = useState(null);
  const [timezones, setTimezones] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [countries, setCountries] = useState(null);
  const [software, setSoftware] = useState(null);

  setPageTitle("Profile");

  useEffect(() => {
    const { search } = props.location;
    if ("?redirect=true" === search) {
      showNotification(
        "You have been redirected",
        <>
          <p>
            Please kindly update the required fields in your profile (denoted by a red asterisk).
          </p>
          <p>Thank you.</p>
          <p>Take 1</p>
        </>,
        "danger"
      );
    }
  }, [props.location]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/user-portal/", "get")
      .then(response => {
        setLoading(false);
        setUserData(() => {
          if (!response.userData.country) {
            response.countries.forEach(country => {
              if (country.label.includes("United Kingdom")) {
                response.userData.country = country.value;
              }
            });
          }
          if (!response.userData.softwareExperience) {
            response.userData.softwareExperience = [];
          }
          return response.userData;
        });

        setTimezones(response.timezones);
        setLanguages(response.languages);
        setCountries(() => {
          let _countries = [];
          let priorityCountries = ["CL", "GB", "US"];
          response.countries.forEach(country => {
            if (country.value === "GB") _countries.push(country);
          });
          response.countries.forEach(country => {
            if (country.value === "US") _countries.push(country);
          });
          response.countries.forEach(country => {
            if (country.value === "CL") _countries.push(country);
          });
          response.countries.forEach(country => {
            if (!priorityCountries.includes(country.value)) _countries.push(country);
          });
          return _countries;
        });
        setSoftware(response.software);
        if (response.userData.fullName) {
          setTitle(response.userData.fullName);
          setPageTitle(response.userData.fullName);
        }
      })
      .catch(e => {
        setLoading(false);
        console.error(e);
      });
  };

  const onSubmit = async e => {
    e.preventDefault();
    document.getElementById("user-profile-form").classList.remove("was-validated");
    if (validateForm(e)) {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/profile-check?setComplete=true", "get");
      await apiGatewayCall(config.api_name, "/user-portal/", "post", { body: userData })
        .then(() => {
          showNotification("Success", "Your details were successfully updated", "success");
          localStorage.setItem("hasCompleteProfile", "true");
          fetchData();
        })
        .catch(e => {
          console.error(e);
          showNotification("Error", e.errorMessage, "warning");
          setLoading(false);
        });
    }
  };

  const validateForm = e => {
    let valid = true;
    const requiredFields = [
      "fullName",
      "timezone",
      "emailAddress",
      "phoneNumber",
      "streetAddress",
      "postcode",
      "country",
      "birthday"
    ];
    requiredFields.forEach(requiredField => {
      if (requiredField === "birthday") {
        if (userData[requiredField] === "NaN/NaN") {
          valid = true;
        } else if (userData[requiredField].includes("NaN")) {
          valid = false;
          showNotification("Error", "Please choose both Birthday fields or none.", "warning");
        } else {
          valid = true;
        }
      }
      if (
        !(
          (userData[requiredField] && userData[requiredField] !== "") ||
          (userData[requiredField] && userData[requiredField]) !== undefined ||
          (userData[requiredField] && userData[requiredField]) !== null
        )
      )
        valid = false;
    });
    if (!valid) {
      // e.target.className += ' was-validated'
      document.getElementById("user-profile-form").classList.add("was-validated");
      showNotification("Error", "Please make sure you complete all required fields.", "warning");
    }
    return valid;
  };

  const updateUserData = e => {
    let _userData = { ...userData };
    _userData[e.target.id] = e.target.value;
    setUserData(_userData);
  };

  return (
    <Fragment>
      {userData && (
        <>
          {userData.fullName && (
            <>
              <MDBRow>
                <MDBCol>
                  <h1 className="page-title">{title}</h1>
                </MDBCol>
              </MDBRow>
            </>
          )}
          <MDBRow>
            <MDBCol>
              <form onSubmit={e => onSubmit(e)} className="needs-validation" id="user-profile-form">
                <PersonalInformation
                  userData={userData}
                  updateUserData={updateUserData}
                  timezones={timezones}
                  languages={languages}
                />
                <ContactDetails
                  userData={userData}
                  updateUserData={updateUserData}
                  countries={countries}
                />
                {software && (
                  <Take1Details
                    userData={userData}
                    updateUserData={updateUserData}
                    software={software}
                  />
                )}

                <p>
                  <span className="red-text">*</span> Required fields
                </p>
                <MDBBtn type="submit" onClick={e => onSubmit(e)} className="mb-4">
                  Save
                </MDBBtn>
              </form>
            </MDBCol>
            <MDBCol size="12" md="4">
              <ConsoleData userData={userData} />
              <SetPassword setLoading={setLoading} />
            </MDBCol>
          </MDBRow>
        </>
      )}
      {loading === true && <LoadingIcon />}
    </Fragment>
  );
};

export default UserProfile;

import { MDBCard, MDBCardBody, MDBCardTitle, MDBInput, MDBSelect } from "mdbreact";
import React from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import ExternalConfigurationSet from "./ExternalConfigurationSet";
import OoonaProjectPreview from "./OoonaProjectPreview";
import OoonaSettingsExternalGuid from "./OoonaSettingsExternalGuid";

const ConfigurationSetData = ({
  configurationSet,
  setConfigurationSet,
  setLoading,
  onSubmit,
  configurationSets,
  existingOoonaGuidNames,
  canEdit,
  schemas,
}) => {
  const fields = configurationSet.schema.inputs;

  const updateConfigurationSetValues = (field, value) => {
    setConfigurationSet((oldConfigurationSet) => {
      let _configurationSet = { ...oldConfigurationSet };
      _configurationSet.values[field.inputInstance.slug] = value;
      return _configurationSet;
    });
  };
  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle tag="h2" className="h4">
          Inputs
        </MDBCardTitle>
        {fields.map((field) => {
          let fieldContent;
          if (field.type === "element") {
            switch (field.inputTemplate.slug) {
              case "p":
                fieldContent = <p key={field.inputInstance.id}>{field.inputInstance.content}</p>;
                break;
              case "h1":
                fieldContent = <h1 key={field.inputInstance.id}>{field.inputInstance.content}</h1>;
                break;
              case "h2":
                fieldContent = <h2 key={field.inputInstance.id}>{field.inputInstance.content}</h2>;
                break;
              case "h3":
                fieldContent = <h3 key={field.inputInstance.id}>{field.inputInstance.content}</h3>;
                break;
              case "h4":
                fieldContent = <h4 key={field.inputInstance.id}>{field.inputInstance.content}</h4>;
                break;
              default:
                break;
            }
          }
          if (field.type === "input") {
            switch (field.inputTemplate.slug) {
              case "text":
                fieldContent = (
                  <MDBInput
                    key={field.inputInstance.id}
                    type="text"
                    label={field.inputInstance.label}
                    id={field.inputInstance.slug}
                    getValue={(e) => updateConfigurationSetValues(field, e)}
                    value={configurationSet.values[field.inputInstance.slug]}
                    disabled={!canEdit}
                  />
                );
                break;
              case "textarea":
                fieldContent = (
                  <MDBInput
                    key={field.inputInstance.id}
                    type="textarea"
                    label={field.inputInstance.label}
                    id={field.inputInstance.slug}
                    getValue={(e) => updateConfigurationSetValues(field, e)}
                    value={configurationSet.values[field.inputInstance.slug]}
                    disabled={!canEdit}
                  />
                );
                break;
              case "number":
                fieldContent = (
                  <MDBInput
                    key={field.inputInstance.id}
                    type="number"
                    label={field.inputInstance.label}
                    id={field.inputInstance.slug}
                    getValue={(e) => updateConfigurationSetValues(field, e)}
                    value={configurationSet.values[field.inputInstance.slug]}
                    disabled={!canEdit}
                  />
                );
                break;
              case "email":
                fieldContent = (
                  <MDBInput
                    key={field.inputInstance.id}
                    type="email"
                    label={field.inputInstance.label}
                    id={field.inputInstance.slug}
                    getValue={(e) => updateConfigurationSetValues(field, e)}
                    value={configurationSet.values[field.inputInstance.slug]}
                    disabled={!canEdit}
                  />
                );
                break;
              case "telephone-number":
                fieldContent = (
                  <MDBInput
                    key={field.inputInstance.id}
                    type="tel"
                    label={field.inputInstance.label}
                    id={field.inputInstance.slug}
                    getValue={(e) => updateConfigurationSetValues(field, e)}
                    value={configurationSet.values[field.inputInstance.slug]}
                    disabled={!canEdit}
                  />
                );
                break;
              case "select":
                fieldContent = (
                  <MDBSelect
                    options={field.inputInstance.options}
                    label={field.inputInstance.label}
                    key={field.inputInstance.id}
                    id={field.inputInstance.id}
                    getValue={(e) => updateConfigurationSetValues(field, e[0])}
                    disabled={!canEdit}
                  />
                );
                break;
              case "checkbox":
                fieldContent = (
                  <MDBInput
                    key={field.inputInstance.id}
                    type="checkbox"
                    label={field.inputInstance.label}
                    id={field.inputInstance.slug}
                    onChange={(e) => updateConfigurationSetValues(field, e.target.checked)}
                    className="mb-4"
                    containerClass="mb-4"
                    checked={configurationSet.values[field.inputInstance.slug]}
                    disabled={!canEdit}
                  />
                );
                break;
              case "ooona-settings-external-guid":
                fieldContent = (
                  <OoonaSettingsExternalGuid
                    key={field.inputInstance.id}
                    field={field}
                    configurationSet={configurationSet}
                    setLoading={setLoading}
                    updateConfigurationSetValues={updateConfigurationSetValues}
                    onSubmit={onSubmit}
                    existingOoonaGuidNames={existingOoonaGuidNames}
                    canEdit={canEdit}
                  />
                );
                break;
              case "ooona-project-preview-button":
                fieldContent = (
                  <OoonaProjectPreview
                    key={field.inputInstance.id}
                    configurationSet={configurationSet}
                    setLoading={setLoading}
                  />
                );
                break;
              case "configuration-set-data":
                fieldContent = (
                  <ExternalConfigurationSet
                    key={field.inputInstance.id}
                    field={field}
                    configurationSet={configurationSet}
                    configurationSets={configurationSets}
                    updateConfigurationSetValues={updateConfigurationSetValues}
                    canEdit={canEdit}
                  />
                );

                break;
              case "json":
                fieldContent = (
                  <div className="form-group" key={field.inputInstance.id}>
                    <label htmlFor={field.inputInstance.id}>{field.inputInstance.label}</label>
                    <JSONInput
                      id={field.inputInstance.id}
                      placeholder={
                        configurationSet.values[field.inputInstance.slug] !== undefined
                          ? JSON.parse(configurationSet.values[field.inputInstance.slug])
                          : {}
                      }
                      onChange={(e) => {
                        !e.error && updateConfigurationSetValues(field, e.json);
                      }}
                      locale={locale}
                      theme="light_mitsuketa_tribute"
                      colors={{ default: "#495057" }}
                      height="auto"
                      style={{
                        body: {
                          border: "1px solid #ced4da",
                          borderRadius: "0.25rem",
                          color: "#495057",
                          minHeight: "200px",
                        },
                      }}
                      viewOnly={!canEdit}
                    />
                  </div>
                );
                break;
              default:
                console.log(field);
                break;
            }
          }
          return fieldContent;
        })}
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConfigurationSetData;

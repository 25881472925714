import React, { useState } from "react";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from "mdbreact";
import apiGatewayCall from "../../helpers/apiGatewayCall";

export const NewRoleModal = ({ setIsLoading, getRoles }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newRoleData, setNewRoleData] = useState({ canUseConsole: true, groups: [] });
  const [newRoleFormFields, setNewRoleFormFields] = useState([]);

  const buildForm = async () => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/user-management/groups/fields", "get")
      .then((response) => {
        let newNewRoleFormFields = [];
        response.form_values.forEach((field) => {
          if (field.htmlElement === "text") {
            newNewRoleFormFields.push(
              <MDBInput
                label={field.label}
                type="text"
                required={field.required}
                value={newRoleData[field.name]}
                onChange={(e) => onFormChange(e)}
                key={field.name}
                name={field.name}
              />
            );
          }
          if (field.name === "canUseConsole") {
            newNewRoleFormFields.push(
              <div className="form-check mb-4" key={field.name}>
                <input
                  type="checkbox"
                  name={field.name}
                  id={field.name}
                  className="form-check-input"
                  defaultChecked={true}
                  onChange={(e) => onFormChange(e)}
                />
                <label htmlFor={field.name} className="form-check-label">
                  Can use Console
                </label>
              </div>
            );
          }
          if (field.name === "groups") {
            newNewRoleFormFields.push(<legend key="new-role-groups-title">Permissions</legend>);
            field.data.forEach((cognitoRole) => {
              const cognitoRoleKey = "cognito-group-" + cognitoRole;
              newNewRoleFormFields.push(
                <div key={cognitoRoleKey}>
                  <MDBInput
                    label={cognitoRole}
                    type="checkbox"
                    id={cognitoRole}
                    name={field.name}
                    value={cognitoRole}
                    onChange={(e) => onFormChange(e)}
                  />
                </div>
              );
            });
          }
        });
        setNewRoleFormFields(newNewRoleFormFields);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to create roles.", "warning");
      });
  };

  const toggle = () => {
    if (!isOpen) {
      buildForm();
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const onFormChange = (e) => {
    const field_name = e.target.name;
    const field_value = e.target.value;
    const field_status = e.target.checked;

    if (field_name === "groups") {
      setNewRoleData((oldRoleData) => {
        if (oldRoleData.groups === undefined) {
          oldRoleData.groups = [];
        }
        if (field_status === true) {
          oldRoleData.groups.push(field_value);
        } else {
          oldRoleData.groups = oldRoleData.groups.filter((groupName) => groupName !== field_value);
        }
        return oldRoleData;
      });
    } else if (field_name === "canUseConsole") {
      setNewRoleData((oldRoleData) => {
        oldRoleData[field_name] = field_status;
        return oldRoleData;
      });
    } else {
      setNewRoleData((oldRoleData) => {
        oldRoleData[field_name] = field_value;
        return oldRoleData;
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await apiGatewayCall(config.api_name, "/user-management/groups", "put", { body: newRoleData })
      .then((response) => {
        if (response.statusCode === 201) {
          onSuccess(newRoleData.label);
        } else {
          onFailure(response);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to create roles.", "warning");
      });
  };

  const onSuccess = (groupName) => {
    showNotification("Permission created", groupName + " was successfully created.", "success");
    toggle();
    getRoles();
  };
  const onFailure = (response) => {
    console.log(response);
  };

  return (
    <>
      <MDBBtn onClick={toggle}>New role</MDBBtn>
      <MDBModal isOpen={isOpen} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>New role</MDBModalHeader>
        <form id="new-role-form" onSubmit={(e) => onSubmit(e)}>
          <MDBModalBody>{newRoleFormFields}</MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={toggle}>
              Close
            </MDBBtn>
            <MDBBtn color="default" type="submit" onClick={(e) => onSubmit(e)}>
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </>
  );
};

import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from "mdbreact";
import React, { useState } from "react";
import showNotification from "../../helpers/showNotification";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

const WorkflowConnectionsNewModal = ({ fields, saveNewRecord }) => {
  const blankNewConnection = {
    key: "",
    name: "",
    connectionTypeId: "",
    description: "",
    requirements: "",
    metadata: "{}",
    workflowId: null,
    sourceLocationId: "",
    destinationLocationId: "",
    connectionStatusId: '1'
  };
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [newConnection, setNewConnection] = useState(blankNewConnection);

  const requiredFields = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "key",
      label: "Connection key",
    },
    {
      name: "connectionTypeId",
      label: "Connection type",
    },
    {
      name: "sourceLocationId",
      label: "Source location",
    },
    {
      name: "destinationLocationId",
      label: "Destination location",
    },
  ];

  const toggleNewModal = () => {
    setNewModalOpen(!newModalOpen);
  };

  const validateForm = () => {
    let invalidFields = [];
    requiredFields.forEach((requiredField) => {
      if (newConnection[requiredField.name] === "") {
        invalidFields.push(requiredField.label);
      }
    });
    return invalidFields;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const invalidFields = validateForm();
    if (invalidFields.length) {
      const invalidFieldsString = invalidFields.join(", ");
      showNotification(
        "Error",
        "Please make sure the following fields are completed: " + invalidFieldsString,
        "warning"
      );
    } else {
      saveNewRecord(newConnection);
      toggleNewModal();
    }
  };
  const onFieldChange = (field, value) => {
    if (field === "key") {
      const regex = RegExp("^[a-zA-Z0-9]{1,6}$");
      if (regex.test(value)) {
        value = value.toUpperCase();
        setNewConnection(() => {
          let updatedConnection = { ...newConnection };
          updatedConnection[field] = value;
          return updatedConnection;
        });
      }
    } else if (field === "metadata") {
      !value.error &&
        setNewConnection(() => {
          let updatedConnection = { ...newConnection };
          updatedConnection[field] = value.json;
          return updatedConnection;
        });
    } else {
      setNewConnection(() => {
        let updatedConnection = { ...newConnection };
        updatedConnection[field] = value;
        return updatedConnection;
      });
    }
  };

  return (
    <>
      <MDBBtn onClick={(e) => toggleNewModal()}>
        <MDBIcon icon="plus-circle" className="mr-2" />
        Add new
      </MDBBtn>
      <MDBModal isOpen={newModalOpen} toggle={toggleNewModal} size="lg">
        <MDBModalHeader>New workflow connection</MDBModalHeader>
        <form onSubmit={(e) => handleFormSubmit(e)}>
          <MDBModalBody className="new-connection-form">
            <div className="form-group">
              <label htmlFor="input-name">Name (required)</label>
              <input
                type="text"
                id="input-name"
                onChange={(e) => onFieldChange("name", e.target.value)}
                value={newConnection.name}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="input-key">Connection key (required)</label>
              <small className="form-text text-muted">Max six characters, alphanumeric only, no spaces allowed.</small>
              <input
                type="text"
                id="input-key"
                onChange={(e) => onFieldChange("key", e.target.value)}
                value={newConnection.key}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="input-description">Description</label>
              <small className="form-text text-muted">What this is, who uses it, etc.</small>
              <input
                type="text"
                id="input-description"
                onChange={(e) => onFieldChange("description", e.target.value)}
                value={newConnection.description}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="select-connection-type">Connection type (required)</label>
              <select
                className="browser-default custom-select mb-2"
                onChange={(e) => onFieldChange("connectionTypeId", e.target.value)}
                id="select-connection-type"
                defaultValue={0}
              >
                <option value={0} disabled>
                  Select a connection type
                </option>
                {fields.connectionType.map((connectionType) => {
                  return (
                    <option
                      key={connectionType.workflowConnectionTypeId}
                      value={connectionType.workflowConnectionTypeId}
                    >
                      {connectionType.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="input-requirements">Requirements</label>
              <input
                type="text"
                id="input-requirements"
                onChange={(e) => onFieldChange("requirements", e.target.value)}
                value={newConnection.requirements}
                className="form-control"
              />
            </div>
            <div className="form-group">
          <label htmlFor="input-metadata">Metadata</label>
          <JSONInput
            id="input-metadata"
            locale={locale}
            theme="light_mitsuketa_tribute"
            colors={{ default: "#495057" }}
            height="auto"
            placeholder={JSON.parse(newConnection.metadata)}
            style={{ body: { border: "1px solid #ced4da", borderRadius: "0.25rem", color: "#495057" } }}
            onChange={(e) => onFieldChange("metadata", e)}
          />
        </div>
            <div className="form-group">
              <label htmlFor="select-workflow">Workflow</label>
              <select
                className="browser-default custom-select mb-2"
                onChange={(e) => onFieldChange("workflowId", e.target.value)}
                id="select-workflow"
                defaultValue={0}
              >
                <option value={0} disabled>
                  Select a workflow
                </option>
                {fields.workflows.map((workflow) => {
                  return (
                    <option key={workflow.workflowId} value={workflow.workflowId}>
                      {workflow.title}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="select-source-location">Source location (required)</label>
              <select
                className="browser-default custom-select mb-2"
                onChange={(e) => onFieldChange("sourceLocationId", e.target.value)}
                id="select-source-location"
                defaultValue={0}
              >
                <option value={0} disabled>
                  Select a source location
                </option>
                {fields.location.map((location) => {
                  return (
                    <option key={location.locationId} value={location.locationId}>
                      {location.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="select-destination-location">Destination location (required)</label>
              <select
                className="browser-default custom-select mb-2"
                onChange={(e) => onFieldChange("destinationLocationId", e.target.value)}
                id="select-destination-location"
                defaultValue={0}
              >
                <option value={0} disabled>
                  Select a destination location
                </option>
                {fields.location.map((location) => {
                  return (
                    <option key={location.locationId} value={location.locationId}>
                      {location.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={toggleNewModal}>
              Cancel
            </MDBBtn>
            <MDBBtn type="submit" onClick={(e) => handleFormSubmit(e)} color="primary">
              Save
            </MDBBtn>
          </MDBModalFooter>
        </form>
      </MDBModal>
    </>
  );
};

export default WorkflowConnectionsNewModal;

import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const HtmlEditor = ({ defaultText, onValueChange, label, fieldSlug, fieldRequired, toolbar = null }) => {
  const blocksFromHtml = htmlToDraft(defaultText);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  useEffect(() => {
    onValueChange(fieldSlug, editorHtml(), fieldRequired);
    // eslint-disable-next-line
  }, []);

  const editorHtml = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    onValueChange(fieldSlug, editorHtml(), fieldRequired);
  };

  return (
    <div>
      {label ? <label>{label}</label> : null}

      {toolbar ? (
        <Editor
          editorState={editorState}
          wrapperClassName="wysiwyg-wrapper"
          editorClassName="wysiwyg-editor mb-2"
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbar}
        />
      ) : (
        <Editor
          editorState={editorState}
          wrapperClassName="wysiwyg-wrapper"
          editorClassName="wysiwyg-editor mb-2"
          onEditorStateChange={onEditorStateChange}
        />
      )}
    </div>
  );
};

export default HtmlEditor;

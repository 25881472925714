import React from "react";
import SchemaNewCurrentElement from "./SchemaCurrentElement";
import SchemaNewCurrentInput from "./SchemaCurrentInput";
import SchemaNewInput from "../SchemaNewInput";
import MDBSortable from "mdb-react-sortable";

const SchemasInputs = ({ availableInputs, schema, setSchema, configurationSets, canEdit, allSchemas }) => {
  let sortableItems = [];
  if (schema.inputs.length) {
    sortableItems = schema.inputs.map((input) => {
      let schemaCurrentInput;
      switch (input.type) {
        case "input":
          schemaCurrentInput = (
            <SchemaNewCurrentInput
              id={input.inputInstance.id}
              input={input}
              setSchema={setSchema}
              key={input.inputInstance.id}
              configurationSets={configurationSets}
              canEdit={canEdit}
              allSchemas={allSchemas}
            />
          );
          break;
        case "element":
          schemaCurrentInput = (
            <SchemaNewCurrentElement
              id={input.inputInstance.id}
              input={input}
              setSchema={setSchema}
              key={input.inputInstance.id}
              canEdit={canEdit}
            />
          );
          break;
        default:
          break;
      }
      return schemaCurrentInput;
    });
  }

  const onListSort = (obj) => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };

      const move = (arr, old_index, new_index) => {
        while (old_index < 0) {
          old_index += arr.length;
        }
        while (new_index < 0) {
          new_index += arr.length;
        }
        if (new_index >= arr.length) {
          let k = new_index - arr.length;
          while (k-- + 1) {
            arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
      };
      _schema.inputs = move(_schema.inputs, obj.oldIndex, obj.newIndex);
      return _schema;
    });
  };

  return (
    <>
      <h2 className="h3 mb-4">Inputs</h2>
      {schema.inputs.length ? (
        <div className="current-inputs mb-4">
          <MDBSortable
            items={sortableItems}
            listClassName="SortableList list-unstyled"
            onSortEnd={(obj) => onListSort(obj)}
          />
        </div>
      ) : <p>No inputs have been defined for this schema.</p>}

      {canEdit && <SchemaNewInput availableInputs={availableInputs} schema={schema} setSchema={setSchema} />}
    </>
  );
};

export default SchemasInputs;

import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import WorkflowListingToolbar from "../WorkflowListingToolbar";
import RecordsListing from "../RecordsListing";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import showNotification from "../../helpers/showNotification";

const WorkflowListing = ({ content_type, setLoading }) => {
  setPageTitle("Workflow configuration");

  const [allRecords, setAllRecords] = useState([]);
  const [shortenedRecords, setShortenedRecords] = useState([]);

  const [canCreate, setCanCreate] = useState(false);

  const filterQueryString = "";

  let content_config = config[content_type];

  useEffect(() => {
    fetchData(filterQueryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shortenRecords = (records) => {
    let count = 0;
    let shortenedRecords = [];
    const visible_fields = content_config.listing.visible_fields;
    records.map((record) => {
      shortenedRecords[count] = {};
      visible_fields.forEach((field) => {
        if (field === "status") {
          const statusClassName = "status-" + record[field].toLowerCase();
          shortenedRecords[count][field] = <span className={statusClassName}>{record[field]}</span>;
        } else {
          shortenedRecords[count][field] = record[field];
          if (shortenedRecords[count][field] == null) {
            shortenedRecords[count][field] = "NULL";
          }
        }
      });
      count++;
      return true;
    });
    return shortenedRecords;
  };

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/workflows/", "get")
      .then((response) => {
        setAllRecords(response.workflows);
        setCanCreate(response.can_create);
        setShortenedRecords(shortenRecords(response.workflows));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification("Access denied", "You do not have permission to view workflows.", "warning");
      });
  };

  const triggerDetailsModal = async (e, recordId) => {
    window.location = "/workflow-configuration/workflows/" + recordId;
  };

  let recordsListing = "";

  if (allRecords) {
    recordsListing = (
      <>
        <WorkflowListingToolbar
          refreshRecords={fetchData}
          newRecordUrl={content_config.listing.create_url}
          canCreate={canCreate}
        />
        <RecordsListing
          records={shortenedRecords}
          openDetailModal={triggerDetailsModal}
          idField={content_config.listing.id_field}
        />
      </>
    );
  }

  return (
    <Fragment>
      <h1 className="page-title">{content_config.title}</h1>
      {recordsListing}
    </Fragment>
  );
};

export default withRouter(WorkflowListing);

import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import LoadingIcon from "../components/LoadingIcon";
import { API } from "aws-amplify";

const PhoneList = () => {
  const [loading, setLoading] = useState(false);
  const [phoneListTableData, setPhoneListTableData] = useState(null);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await API.get("take-1-console", "/phone-list").then(response => {
      const phoneListTableDataTemp = {
        columns: [
          {
            label: "Name",
            field: "name",
            sort: "asc"
          },
          {
            label: "Extension",
            field: "extension"
          }
        ],
        rows: response.entries
      };
      setPhoneListTableData(phoneListTableDataTemp);
      setLoading(false);
    });
  };

  let pageContent = null;
  if (loading === true) {
    pageContent = <LoadingIcon />;
  } else {
    if (phoneListTableData) {
      pageContent = (
        <>
          <h1 className="page-title">Take 1 phone list</h1>
          <MDBDataTable
            striped
            hover
            data={phoneListTableData}
            paging={false}
          />
        </>
      );
    }
  }
  return pageContent;
};

export default PhoneList;

import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import WorkFlowManagementNav from "./WorkFlowConfigurationNav";
import WorkflowListing from "./WorkflowListing";
import WorkflowNew from "./WorkflowNew";
import WorkflowEdit from "./WorkflowEdit";
import WorkflowConnectionsListing from "./WorkflowConnectionsListing";
import WorkflowConnectionsEdit from "./WorkflowConnectionsEdit";
import WorkflowRecipesListing from "./WorkflowRecipesListing";
import WorkflowRecipesEdit from "./WorkflowRecipesEdit";
import WorkflowLocationsListing from "./WorkflowLocationsListing";
import WorkflowLocationsEdit from "./WorkflowLocationsEdit";
import WorkflowComponentPrototypesListing from "./WorkflowComponentPrototypesListing";
import WorkflowComponentPrototypesEdit from "./WorkflowComponentPrototypesEdit";
import { withRouter } from "react-router-dom";
import LoadingIcon from "../LoadingIcon";
import WorkflowComponentsListing from "./WorkflowComponentsListing";
import WorkflowComponentsEdit from "./WorkflowComponentsEdit";
// import DynamicSortable from "./DynamicSortable";

const WorkflowConfigurationContainer = ({ page, content_type, match }) => {
  const [loading, setLoading] = useState(false);
  let pageComponent = null;
  if (page === "workflow-configuration-workflow-listing") {
    pageComponent = <WorkflowListing content_type={content_type} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-workflow-new") {
    pageComponent = <WorkflowNew content_type={content_type} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-workflow-edit") {
    pageComponent = <WorkflowEdit content_type={content_type} match={match} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-connections-listing") {
    pageComponent = <WorkflowConnectionsListing content_type={content_type} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-connections-edit") {
    pageComponent = (
      <WorkflowConnectionsEdit content_type={content_type} match={match} loading={loading} setLoading={setLoading} />
    );
  }
  if (page === "workflow-configuration-recipes-listing") {
    pageComponent = <WorkflowRecipesListing content_type={content_type} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-recipes-edit") {
    pageComponent = <WorkflowRecipesEdit content_type={content_type} match={match} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-locations-listing") {
    pageComponent = <WorkflowLocationsListing content_type={content_type} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-locations-edit") {
    pageComponent = <WorkflowLocationsEdit content_type={content_type} match={match} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-component-prototypes") {
    pageComponent = (
      <WorkflowComponentPrototypesListing content_type={content_type} match={match} setLoading={setLoading} />
    );
  }
  if (page === "workflow-configuration-component-prototypes-edit") {
    pageComponent = (
      <WorkflowComponentPrototypesEdit content_type={content_type} match={match} setLoading={setLoading} />
    );
  }
  if (page === "workflow-configuration-components") {
    pageComponent = <WorkflowComponentsListing content_type={content_type} setLoading={setLoading} />;
  }
  if (page === "workflow-configuration-components-edit") {
    pageComponent = <WorkflowComponentsEdit content_type={content_type} match={match} setLoading={setLoading} />;
  }

  let loadingContent = null;
  if (loading) loadingContent = <LoadingIcon />;
  return (
    <div>
      {loadingContent}
      <MDBRow>
        <MDBCol xs="12" md="3" xl="2">
          <WorkFlowManagementNav page={page} />
        </MDBCol>
        <MDBCol xs="12" md="9" xl="10">
          {pageComponent}
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default withRouter(WorkflowConfigurationContainer);

import React from "react";
import { useState } from "react";

const SchemaOoonaSettingsExternalGuid = ({ updateInput, input, canEdit }) => {
  const settingsTypes = ["ProjectSettings", "LanguageSettings", "VideoSettings", "ExportSettings", "ImportSettings"];
  const [ooonaSettings, setOoonaSettings] = useState({ settingsType: "" });

  const getSlug = (settingType) => {
    let slug;
    switch (settingType) {
      case "ProjectSettings":
        slug = "configurationGuid";
        break;
      case "LanguageSettings":
        slug = "languageConfigurationGuid";
        break;
      case "VideoSettings":
        slug = "videoConfigurationGuid";
        break;
      case "ExportSettings":
        slug = "exportConfigurationGuid";
        break;
      case "ImportSettings":
        slug = "importConfigurationGuid";
        break;

      default:
        break;
    }
    return slug;
  };

  const onInputChange = (field, value) => {
    if (value !== "0") {
      updateInput(input.inputInstance.id, "slug", getSlug(value));
      setOoonaSettings(() => {
        let _ooonaSettings = { ...ooonaSettings };
        _ooonaSettings[field] = value;
        updateInput(input.inputInstance.id, "values", _ooonaSettings);
        return _ooonaSettings;
      });
    }
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor={input.inputInstance.slug + "-settings-type-dropdown"}>Type of settings to generate</label>
        <select
          className="form-control"
          id={input.inputInstance.slug + "-settings-type-dropdown"}
          onChange={(e) => onInputChange("settingsType", e.target.value)}
          disabled={!canEdit}
          value={"values" in input.inputInstance && input.inputInstance.values.settingsType}
        >
          <option value="0">Select settings type</option>
          {settingsTypes.map((settingsType) => {
            return (
              <option key={settingsType} value={settingsType}>
                {settingsType}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export default SchemaOoonaSettingsExternalGuid;

import React from "react";

const LoadingIcon = ({fullHeight = true}) => {
  let overlayClass = 'loading-overlay full-height'
  if(fullHeight === false) overlayClass = 'loading-overlay'
  return (
    <div className={overlayClass}>
      <div className="loading-container">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingIcon;

import React from 'react';
import Select from 'react-select';

const Address = ({ userData, updateUserData, countries }) => {
  return (
    <fieldset>
      <div className='form-group'>
        <label htmlFor='streetAddress'>
          Address <span className='red-text'>*</span>
        </label>
        <textarea
          name='streetAddress'
          id='streetAddress'
          cols='30'
          rows='3'
          onChange={(e) => updateUserData(e)}
          defaultValue={userData.streetAddress}
          className='form-control'
          required></textarea>
        <div className='invalid-feedback'>Please enter your street address.</div>
      </div>
      <div className='form-group'>
        <label htmlFor='postcode'>
          Post/ZIP code <span className='red-text'>*</span>
        </label>
        <input
          type='text'
          className='form-control'
          id='postcode'
          onChange={(e) => updateUserData(e)}
          defaultValue={userData.postcode}
          required
        />
        <div className='invalid-feedback'>Please enter your postcode.</div>
      </div>
      {countries && (
        <div className='form-group'>
          <label htmlFor='country'>
            Country <span className='red-text'>*</span>
          </label>
          <Select
            options={countries}
            id='country'
            isSearchable={true}
            value={
              // userData.country
              'country' in userData && countries.filter((country) => country.value === userData.country)
            }
            onChange={(value) => {
              updateUserData({ target: { id: 'country', value: value.value } });
            }}
          />
          <div className='invalid-feedback'>Please enter your country.</div>
        </div>
      )}
    </fieldset>
  );
  //   return <div>Address bits can go here</div>;
};

export default Address;

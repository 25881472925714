import { MDBCard, MDBCardBody, MDBCardTitle, MDBInput } from "mdbreact";
import React from "react";

const ConfigurationSetInformation = ({ configurationSet, updateConfigurationSetInformation, canEdit }) => {
  let defaultConfigurationSetStatus = "active";
  if (configurationSet.status !== undefined && configurationSet.status !== "")
    defaultConfigurationSetStatus = configurationSet.status;
  else {
    updateConfigurationSetInformation("status", defaultConfigurationSetStatus);
  }

  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <MDBCardTitle tag="h2" className="h4">
          Configuration set details
        </MDBCardTitle>
        <MDBInput
          label="Name *"
          getValue={(e) => updateConfigurationSetInformation("name", e)}
          value={configurationSet.name}
          disabled={!canEdit}
        />
        <MDBInput type="text" disabled value={configurationSet.schema.label} label="Configuration set schema" />
        <div className="form-group">
          <label htmlFor="configurationSetStatus">Status</label>
          <select
            name="configurationSetStatus"
            id="configurationSetStatus"
            className="form-control"
            defaultValue={defaultConfigurationSetStatus}
            onChange={(e) => updateConfigurationSetInformation("status", e.target.value)}
            disabled={!canEdit}
          >
            <option value="active">Active</option>
            <option value="archived">Archived</option>
          </select>
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default ConfigurationSetInformation;

import React from "react";
import Filtering from "./Filtering";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBBtn,
  MDBIcon
} from "mdbreact";

const Merging = ({
  mergingInstructions,
  showViewButton,
  workflowViewClick,
  newWorkflowClick,
  canWrite,
  showAddButton,
  newWorkflowItemClick
}) => {
  let mergingContent = [];
  mergingInstructions.forEach(mergingInstruction => {
    let key =
      "merging-instruction-" +
      mergingInstruction.serviceTemplateMergingInstructionId;
    let mergingInstructionContent = (
      <MDBCard key={key}>
        <MDBCardBody>
          <MDBCardTitle>
            {mergingInstruction.serviceTemplateMergingInstructionId}:{" "}
            {mergingInstruction.merging_instruction_template.name}
            {showViewButton && canWrite ? (
              <MDBBtn
                onClick={e =>
                  workflowViewClick(
                    "MI",
                    mergingInstruction.serviceTemplateMergingInstructionId
                  )
                }
                className="float-right"
              >
                Edit
              </MDBBtn>
            ) : (
              ""
            )}
          </MDBCardTitle>
          <MDBCardText tag="div">
            <p>
              {mergingInstruction.merging_instruction_template.description !==
              ""
                ? mergingInstruction.merging_instruction_template.description
                : "No description available"}
            </p>
            <h3>Filtering instructions</h3>
          </MDBCardText>
          {mergingInstruction.filtering_instructions ? (
            <Filtering
              filteringInstructions={mergingInstruction.filtering_instructions}
              showViewButton={showViewButton}
              workflowViewClick={workflowViewClick}
              newWorkflowClick={newWorkflowClick}
              canWrite={canWrite}
              showAddButton={showAddButton}
              newWorkflowItemClick={newWorkflowItemClick}
            />
          ) : (
            ""
          )}
          {canWrite && showAddButton ? (
            <div className="new-workflow-item">
              <MDBBtn
                color="primary"
                onClick={e =>
                  newWorkflowClick(
                    "FI",
                    mergingInstruction.serviceTemplateMergingInstructionId,
                    mergingInstruction.serviceTemplateId
                  )
                }
              >
                <MDBIcon icon="plus-circle" className="mr-2" /> Add new
                filtering instruction
              </MDBBtn>
            </div>
          ) : (
            ""
          )}
        </MDBCardBody>
      </MDBCard>
    );
    mergingContent.push(mergingInstructionContent);
  });
  return mergingContent;
};

export default Merging;

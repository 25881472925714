import React from "react";

const ExternalConfigurationSet = ({ updateInput, input, allSchemas, canEdit = true }) => {

  const onParentSchemaChange = (parentSchema) => {
    if (parentSchema !== "none") {
      updateInput(input.inputInstance.id, "parentSchema", parentSchema);
      updateInput(input.inputInstance.id, "slug", parentSchema);
    }
  };
  return (
    <div className="form-group">
      <label htmlFor={input.inputInstance.id + "-select"}>Parent schema</label>
      <select
        className="form-control"
        id={input.inputInstance.id + "-select"}
        disabled={!canEdit}
        value={input.inputInstance.parentSchema}
        onChange={(e) => {
          onParentSchemaChange(e.target.value);
        }}
      >
        <option value="none"></option>
        {allSchemas.length ? (
          allSchemas.map((schema) => {
            return (
              <option key={schema.uuid} value={schema.uuid}>
                {schema.label}
              </option>
            );
          })
        ) : (
          <option disabled>No schemas available</option>
        )}
      </select>
    </div>
  );
};

export default ExternalConfigurationSet;

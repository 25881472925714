import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import config from "../../content_types";
import setPageTitle from "../../helpers/setPageTitle";
import RecordsListing from "../RecordsListing";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import { MDBBtn, MDBIcon } from "mdbreact";
import WorkflowComponentPrototypesNewModal from "./WorkflowComponentPrototypesNewModal";
import showNotification from "../../helpers/showNotification";

const WorkflowComponentPrototypesListing = ({ content_type, setLoading }) => {
  setPageTitle("Component prototypes");

  const [allRecords, setAllRecords] = useState([]);
  const [shortenedRecords, setShortenedRecords] = useState([]);

  const [canCreate, setCanCreate] = useState(false);

  let content_config = config[content_type];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shortenRecords = (records) => {
    let count = 0;
    let shortenedRecords = [];
    const visible_fields = content_config.listing.visible_fields;
    records.map((record) => {
      shortenedRecords[count] = {};
      visible_fields.forEach((field) => {
        if (field === "status") {
          const statusClassName = "status-" + record[field].toLowerCase();
          shortenedRecords[count][field] = <span className={statusClassName}>{record[field]}</span>;
        } else {
          shortenedRecords[count][field] = record[field];
          if (shortenedRecords[count][field] == null) {
            shortenedRecords[count][field] = "NULL";
          }
        }
      });
      count++;
      return true;
    });
    return shortenedRecords;
  };

  const fetchData = async () => {
    setLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-management/component-prototypes/", "get")
      .then((response) => {
        setAllRecords(response.component_prototypes);
        setCanCreate(false);
        setShortenedRecords(shortenRecords(response.component_prototypes));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showNotification("Access denied", "You do not have permission to view component prototypes.", "warning");
      });
  };

  const triggerDetailsModal = async (e, recordId) => {
    window.location = "/workflow-configuration/component-prototypes/" + recordId;
  };

  const saveNewRecord = async (newRecord) => {
    let options = {
      body: newRecord,
    };
    await apiGatewayCall(config.api_name, "/workflow-management/component-prototypes/", "post", options)
      .then((response) => {
        showNotification("Success", newRecord.name + " was successfully created", "success");
        fetchData();
      })
      .catch(() => {
        setLoading(false);
        showNotification("Access denied", "You do not have permission to create component prototypes.", "warning");
      });
  };

  let recordsListing;

  if (allRecords) {
    recordsListing = (
      <>
        <div className="mb-2">
          <MDBBtn color="primary" onClick={(e) => fetchData()}>
            <MDBIcon icon="sync" className="mr-2" /> Refresh
          </MDBBtn>{" "}
          {canCreate && <WorkflowComponentPrototypesNewModal saveNewRecord={saveNewRecord} />}
        </div>
        <RecordsListing
          records={shortenedRecords}
          openDetailModal={triggerDetailsModal}
          idField={content_config.listing.id_field}
        />
      </>
    );
  }

  return (
    <Fragment>
      <h1 className="page-title">{content_config.title}</h1>
      {recordsListing}
    </Fragment>
  );
};

export default withRouter(WorkflowComponentPrototypesListing);

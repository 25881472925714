import React, { Fragment } from "react";
import { MDBDataTable, MDBBadge } from "mdbreact";
import moment from "moment";

const SearchResults = ({ results, searchResultsField, searchResultsValue }) => {
  const buildAccessLevelPill = (fullAccess) => {
    let pillText = null;
    fullAccess === true ? (pillText = "Full access") : (pillText = "Limited access");
    return (
      <MDBBadge pill color="primary" className="access-level-badge">
        {pillText}
      </MDBBadge>
    );
  };
  const buildResultsTable = (records) => {
    let _resultsTableContent = {};

    // Do we have full access?
    if (records[0].subject !== undefined) {
      _resultsTableContent.columns = [
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Subject",
          field: "subject",
        },
        {
          label: "Recipients",
          field: "recipients",
        },
        {
          label: "Status",
          field: "status",
        },
      ];
      _resultsTableContent.rows = [];
      records.forEach((record) => {
        const statusClassName = "search-result-status status-" + record.status.toLowerCase();
        _resultsTableContent.rows.push({
          date: moment.unix(record.date).format("DD-MM-YYYY HH:MM:SS"),
          subject: record.subject,
          recipients: record.recipients.join(", "),
          status: <span className={statusClassName}>{record.status}</span>,
        });
      });
    } else {
      _resultsTableContent.columns = [
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Status",
          field: "status",
        },
      ];
      _resultsTableContent.rows = [];
      records.forEach((record) => {
        const statusClassName = "search-result-status status-" + record.status.toLowerCase();
        _resultsTableContent.rows.push({
          date: moment.unix(record.date).format("DD-MM-YYYY HH:MM:SS"),
          status: <span className={statusClassName}>{record.status}</span>,
        });
      });
    }

    return _resultsTableContent;
  };

  let resultsContent = [];

  if (results.statusCode === 200 && results.items.length > 0) {
    searchResultsField = searchResultsField.replace("_", " ");
    resultsContent.push(
      <h2 key="results-title" className="mb-4">
        Search results for {searchResultsField} {searchResultsValue} {buildAccessLevelPill(results.fullAccess)}
      </h2>
    );
    const resultsTableContent = buildResultsTable(results.items);
    resultsContent.push(<p key="results-count">{resultsTableContent.rows.length} results found</p>);
    resultsContent.push(
      <MDBDataTable
        key="results-table"
        hover
        entriesOptions={[50, 100]}
        entries={100}
        pagesAmount={4}
        data={resultsTableContent}
      />
    );
  } else if (results.statusCode === 200 && results.items.length === 0) {
    resultsContent.push(<h2 key="results-title">No emails found</h2>);
    resultsContent.push(
      <p key="results-no-results">
        Records were found in Hermes, but no emails have been sent to the client. This may indicate that the job has not
        yet been completed.
      </p>
    );
    resultsContent.push(
      <p key="results-no-results-more">
        Note that delivery tracking will only return results for messages sent after June 2020.
      </p>
    );
  } else if (results.statusCode === 404) {
    resultsContent.push(<h2 key="results-title">No results found</h2>);
    resultsContent.push(<p key="results-no-results">Your search resulted in no results.</p>);
    resultsContent.push(
      <p key="results-no-results-more">
        Note that delivery tracking will only return results for messages sent after June 2020.
      </p>
    );
  }

  return <Fragment>{resultsContent}</Fragment>;
};

export default SearchResults;

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdbreact";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import uuid from "react-uuid";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import SchemasImportExisting from "./SchemasImportExisting";

const SchemaNewInput = ({ availableInputs, schema = null, setSchema }) => {
  const [newInput, setNewInput] = useState(null);
  const [existingSchemas, setExistingSchemas] = useState(null);

  useEffect(() => {
    getExistingSchemas();
    // eslint-disable-next-line
  }, []);

  const getExistingSchemas = async () => {
    await apiGatewayCall(config.api_name, "/schemas", "get")
      .then((response) =>
        setExistingSchemas(() => {
          if (schema) {
            let _existingSchemas = [];
            response.schemas.forEach((existingSchema) => {
              if (schema.name !== existingSchema.name) {
                _existingSchemas.push(existingSchema);
              }
            });
            return _existingSchemas;
          } else {
            return response.schemas;
          }
        })
      )
      .catch((e) => console.log(e));
  };

  const onNewElementSelect = (e) => {
    e.preventDefault();
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };

      availableInputs.elements.forEach((availableElement) => {
        if (availableElement.slug === newInput) {
          let newElement = {
            type: "element",
            inputTemplate: availableElement,
            inputInstance: {
              id: uuid(),
              type: availableElement.slug,
              content: "",
            },
          };
          _schema.inputs.push(newElement);
        }
      });

      return _schema;
    });
  };

  const onNewInputSelect = (e) => {
    e.preventDefault();
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };

      availableInputs.inputs.forEach((availableInput) => {
        if (availableInput.slug === newInput) {
          let newInput = {
            type: "input",
            inputTemplate: availableInput,
            inputInstance: {
              id: uuid(),
              type: availableInput.slug,
              label: "",
              default: "",
            },
          };
          if (availableInput.slug === "select") {
            newInput.inputInstance.options = [];
          }
          _schema.inputs.push(newInput);
        }
      });

      return _schema;
    });
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <h3 className="h4 mb-4">Add new input</h3>
        <MDBRow>
          <MDBCol size="9">
            <select onChange={(e) => setNewInput(e.target.value)} className="browser-default custom-select mb-4">
              <option value="0">Select an input to add</option>
              {availableInputs.inputs.map((input) => {
                return (
                  <option key={input.slug} value={input.slug}>
                    {input.label}
                  </option>
                );
              })}
            </select>
          </MDBCol>
          <MDBCol size="3">
            <MDBBtn className="float-right mt-0" onClick={(e) => onNewInputSelect(e)}>
              Add
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <h3 className="h4 mb-4">Add new HTML content</h3>
        <MDBRow>
          <MDBCol size="9">
            <select onChange={(e) => setNewInput(e.target.value)} className="browser-default custom-select">
              <option value="0">Select HTML element to add</option>
              {availableInputs.elements.map((element) => {
                return (
                  <option key={element.slug} value={element.slug}>
                    {element.label}
                  </option>
                );
              })}
            </select>
          </MDBCol>
          <MDBCol size="3">
            <MDBBtn className="float-right mt-0" onClick={(e) => onNewElementSelect(e)}>
              Add
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        {existingSchemas && <SchemasImportExisting existingSchemas={existingSchemas} setSchema={setSchema} />}
      </MDBCardBody>
    </MDBCard>
  );
};

export default SchemaNewInput;

import React, { useState, useEffect } from "react";
import apiGatewayCall from "../../../helpers/apiGatewayCall";
import config from "../../../content_types";
import LoadingIcon from "../../../components/LoadingIcon";
import setPageTitle from "../../../helpers/setPageTitle";
import showNotification from "../../../helpers/showNotification";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import SchemaInformation from "../SchemaInformation";
import SchemasInputs from "./SchemasInputs";
import slugify from "slugify";
import SchemaPreview from "./SchemaPreview";
import SchemaMoreInformation from "../SchemaMoreInformation";
import SchemaPermissions from "../SchemaPermissions";

const SchemasEdit = ({ match }) => {
  const schemaId = match.params.id;

  const [families, setFamilies] = useState(null);
  const [subcategories, setSubcategories] = useState(null);
  const [familyNames, setFamilyNames] = useState(null);
  const [subcategoryNames, setSubcategoryNames] = useState(null);
  const [availableInputs, setAvailableInputs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userFullName, setUserFullName] = useState(null);
  const [schema, setSchema] = useState(null);
  const [configurationSets, setConfigurationSets] = useState(null);
  const [allRoles, setAllRoles] = useState(null);
  const [allSchemas, setAllSchemas] = useState(null)

  const [currentSchemaFamilyName, setCurrentSchemaFamilyName] = useState(null);
  const [currentSchemaSubcategoryName, setCurrentSchemaSubcategoryName] = useState(null);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [unauthorised, setUnauthorised] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canEditConfigurationSets, setCanEditConfigurationSets] = useState(false);

  useEffect(() => {
    getFormData();
    // eslint-disable-next-line
  }, []);

  const getFormData = async () => {
    setLoading(true);
    apiGatewayCall(config.api_name, "/schemas/" + schemaId, "get")
      .then((response) => {
        setLoading(false);
        if (response.schema) {
          setFamilies(response.formData.schemaFamilies);
          setSubcategories(response.formData.schemaSubcategories);
          setAvailableInputs(response.formData.inputs);
          setFamilyNames(() => {
            let _familyNames = [];
            response.formData.schemaFamilies.forEach((family) => {
              _familyNames.push(family.name);
            });
            return _familyNames;
          });
          setSubcategoryNames(() => {
            let _subcategoryNames = [];
            response.formData.schemaSubcategories.forEach((subcategory) => {
              if (subcategory.parent === response.schema.schemaFamily) {
                _subcategoryNames.push(subcategory.name);
              }
            });
            return _subcategoryNames;
          });
          setSchema(() => {
            let _schema = { ...response.schema };
            _schema.owner = response.schema.owner.uuid.S;

            let _schemaFamilyName;
            let _schemaSubcategoryName;
            response.formData.schemaFamilies.forEach((family) => {
              if (family.uuid === response.schema.schemaFamily) {
                _schemaFamilyName = family.name;
              }
            });
            response.formData.schemaSubcategories.forEach((subcategory) => {
              if (subcategory.uuid === response.schema.schemaSubcategory) {
                _schemaSubcategoryName = subcategory.name;
              }
            });
            _schema.label = _schemaFamilyName + "/" + _schemaSubcategoryName + "/" + _schema.name;

            setPageTitle(_schema.label);
            return _schema;
          });
          setUserFullName(response.schema.owner.fullName.S);
          setCurrentSchemaFamilyName(() => {
            let _currentSchemaFamilyName;
            response.formData.schemaFamilies.forEach((family) => {
              if (family.uuid === response.schema.schemaFamily) {
                _currentSchemaFamilyName = family.name;
              }
            });
            return _currentSchemaFamilyName;
          });
          setCurrentSchemaSubcategoryName(() => {
            let _currentSchemaSubcategoryName;
            response.formData.schemaSubcategories.forEach((subcategory) => {
              if (subcategory.uuid === response.schema.schemaSubcategory) {
                _currentSchemaSubcategoryName = subcategory.name;
              }
            });
            return _currentSchemaSubcategoryName;
          });
          setConfigurationSets(response.formData.configurationSets);
          setAllRoles(response.formData.allRoles);
          setAllSchemas(response.formData.allSchemas)
          setCanEdit(response.schema.canEdit);
          setCanDelete(response.schema.canDelete);
          setCanEditConfigurationSets(response.schema.canConfigurationSetsEdit);
        } else {
          setUnauthorised(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      await apiGatewayCall(config.api_name, "/schemas/" + schema.uuid, "post", { body: schema })
        .then((response) => {
          showNotification("Success", schema.name + " was successfully saved.", "success");
          // getFormData();
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      showNotification(
        "Error",
        "Please make sure you enter a schema family, schema subcategory, and a schema name.",
        "warning"
      );
    }
  };

  const validateForm = () => {
    if (schema.schemaFamily && schema.schemaSubcategory && schema.name !== "" && schema.status) return true;
    else return false;
  };
  const updateSchemaData = (field, value) => {
    setSchema((oldSchema) => {
      let _schema = { ...oldSchema };
      let newValue = value;

      if (["schemaFamily", "schemaSubcategory", "name"].includes(field)) {
        newValue = slugify(newValue, { replacement: "_", lower: true, remove: /[*+~.()'"!:@]/g });

        if (field === "schemaFamily") {
          setCurrentSchemaSubcategoryName(newValue);
          let _subcategoryNames = [];
          families.forEach((item) => {
            if (item.name === value) {
              newValue = item.uuid;
              subcategories.forEach((subcategory) => {
                if (subcategory.parent === item.uuid) {
                  _subcategoryNames.push(subcategory.name);
                }
              });
            }
          });
          setSubcategoryNames(_subcategoryNames);
        }
        if (field === "schemaSubcategory") {
          setCurrentSchemaSubcategoryName(newValue);
          subcategories.forEach((item) => {
            if (item.name === value) {
              newValue = item.uuid;
            }
          });
        }
      }

      _schema[field] = newValue;
      return _schema;
    });
  };

  const onConfirmDelete = async (e) => {
    e.preventDefault();
    await apiGatewayCall(config.api_name, "/schemas/" + schema.uuid, "del")
      .then(() => (window.location = "/schemas/"))
      .catch((e) => console.log(e));
  };

  return (
    <article className="page page-edit-schema">
      {unauthorised && (
        <>
          <h1 className="page-title">Permission denied</h1>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>
              <a href="/">Home</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <a href="/schemas">Schemas</a>
            </MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <p>You do not have permission to view this schema.</p>
        </>
      )}
      {schema && (
        <form className="edit-schema-form" onSubmit={(e) => onSubmit(e)}>
          <h1 className="page-title">Schema: {schema.label}</h1>

          <MDBBreadcrumb>
            <MDBBreadcrumbItem>
              <a href="/">Home</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
              <a href="/schemas">Schemas</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>{schema.label}</MDBBreadcrumbItem>
          </MDBBreadcrumb>

          <MDBRow>
            <MDBCol size="7">
              <SchemaInformation
                familyNames={familyNames}
                subcategoryNames={subcategoryNames}
                schema={schema}
                updateSchemaData={updateSchemaData}
                userFullName={userFullName}
                currentSchemaFamilyName={currentSchemaFamilyName}
                currentSchemaSubcategoryName={currentSchemaSubcategoryName}
                canEdit={canEdit}
              />
              {availableInputs && configurationSets && allSchemas && (
                <SchemasInputs
                  availableInputs={availableInputs}
                  schema={schema}
                  setSchema={setSchema}
                  configurationSets={configurationSets}
                  canEdit={canEdit}
                  allSchemas={allSchemas}
                />
              )}

              <footer className="mb-4">
                <MDBBtn tag="a" href="/schemas" color="secondary">
                  Back to Schemas
                </MDBBtn>
                {canDelete && (
                  <MDBBtn onClick={(e) => setDeleteModalOpen(true)} color="danger">
                    Delete
                  </MDBBtn>
                )}
                {canEdit && (
                  <MDBBtn type="submit" onClick={(e) => onSubmit(e)} className="float-right">
                    Save
                  </MDBBtn>
                )}
              </footer>
              <MDBModal isOpen={deleteModalOpen}>
                <MDBModalHeader>Delete schema</MDBModalHeader>
                {schema.canDelete ? (
                  <>
                    <MDBModalBody>Are you sure you want to delete {schema.name}?</MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn color="secondary" onClick={(e) => setDeleteModalOpen(false)}>
                        Cancel
                      </MDBBtn>
                      <MDBBtn onClick={(e) => onConfirmDelete(e)}>Delete</MDBBtn>
                    </MDBModalFooter>
                  </>
                ) : (
                  <>
                    <MDBModalBody>
                      This schema cannot be deleted because it is being used by one or more configuration sets.
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn onClick={(e) => setDeleteModalOpen(false)}>Close</MDBBtn>
                    </MDBModalFooter>
                  </>
                )}
              </MDBModal>
            </MDBCol>
            <MDBCol size="5">
              <SchemaPreview schema={schema} canEditConfigurationSets={canEditConfigurationSets} />
              {allRoles && (
                <SchemaPermissions schema={schema} allRoles={allRoles} setSchema={setSchema} canEdit={canEdit} />
              )}
              <SchemaMoreInformation schema={schema} userFullName={userFullName} />
            </MDBCol>
          </MDBRow>
        </form>
      )}
      {loading && <LoadingIcon />}
    </article>
  );
};

export default SchemasEdit;

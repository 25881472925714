import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBTooltip,
  MDBTypography,
} from "mdbreact";
import React, { useState } from "react";
import HtmlEditor from "../../components/HtmlEditor";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import apiGatewayCall from "../../helpers/apiGatewayCall";
import config from "../../content_types";
import showNotification from "../../helpers/showNotification";

const WorkflowMonitoringInstanceNotes = ({
  instanceId,
  instanceNotes,
  setInstanceNotes,
  setIsLoading,
  canViewUserPages,
}) => {
  const [newNote, setNewNote] = useState("");
  const [newNoteIsResolution, setNewNoteIsResolution] = useState(false);

  const [noteToDelete, setNoteToDelete] = useState({});
  const [deleteNoteModalOpen, setDeleteNoteModalOpen] = useState(false);

  const onNoteInputChange = (fieldName, content, required) => {
    if (content !== "<p></p>") {
      setNewNote(content);
    }
  };
  const onNotesFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-instances/notes/", "post", {
      body: {
        noteText: newNote,
        instanceId: instanceId,
        isResolution: newNoteIsResolution,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response && response.statusCode) {
          let updatedNotes = [];
          let count = 1;
          response.notes.forEach((note) => {
            note.mostRecent = false;
            if (response.notes.length === count) {
              note.mostRecent = true;
            }
            updatedNotes.push(note);
            count++;
          });
          setInstanceNotes(updatedNotes);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showNotification("Access denied", "You do not have permission to add notes.", "warning");
      });
  };

  const onDeleteNoteClick = (note) => {
    toggleDeleteNoteModal();
    setNoteToDelete(note);
  };
  const toggleDeleteNoteModal = () => {
    setDeleteNoteModalOpen(!deleteNoteModalOpen);
  };
  const confirmDeleteNote = async (note) => {
    setIsLoading(true);
    await apiGatewayCall(config.api_name, "/workflow-instances/notes/" + note.uuid, "del").then((response) => {
      if (response.statusCode === 200) {
        setIsLoading(false);
        toggleDeleteNoteModal();
        setInstanceNotes(response.notes);
        setNoteToDelete({});
        showNotification('Deleted', 'Note deleted', 'success')
      }
    });
  };
  const deleteNoteModal = (
    <MDBModal toggle={toggleDeleteNoteModal} isOpen={deleteNoteModalOpen}>
      <MDBModalHeader>Delete note</MDBModalHeader>
      <MDBModalBody>Are you sure you want to delete this note?</MDBModalBody>
      <MDBModalFooter>
        <MDBBtn
          color="secondary"
          onClick={(e) => {
            setNoteToDelete({});
            toggleDeleteNoteModal();
          }}
        >
          Cancel
        </MDBBtn>
        <MDBBtn color="primary" onClick={(e) => confirmDeleteNote(noteToDelete)}>
          OK
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
  let notesContent = [];
  if (instanceNotes.length) {
    instanceNotes.forEach((note) => {
      const authorContent = (
        <MDBTooltip domElement placement="bottom" clickable>
          <span>{note.author.name}</span>
          <MDBCard>
            <MDBCardHeader>{note.author.name}</MDBCardHeader>
            <MDBCardBody>
              <MDBTypography listUnStyled>
                <li>
                  <a href={`mailto:${note.author.email}`}>
                    <MDBIcon icon="envelope" className="mr-2" />
                    {note.author.email}
                  </a>
                </li>
                {canViewUserPages && (
                  <li>
                    <a href={`/user-management/users/${note.author.uuid}`}>
                      <MDBIcon icon="user" className="mr-2" />
                      Full details
                    </a>
                  </li>
                )}
              </MDBTypography>
            </MDBCardBody>
          </MDBCard>
        </MDBTooltip>
      );
      let noteClass = "instance-note";
      if (note.mostRecent) noteClass = "instance-note most-recent";
      notesContent.push(
        <MDBCard key={note.uuid} className={noteClass}>
          <MDBCardBody>
            {note.author.owner ? (
              <MDBRow>
                <MDBCol size="10">
                  {ReactHtmlParser(note.text)}
                  {note.resolution && (
                    <p className="small green-text">
                      <MDBIcon icon="check" className="mr-2" /> Resolved
                    </p>
                  )}
                </MDBCol>
                <MDBCol size="2">
                  <MDBIcon
                    icon="times"
                    className="clickable float-right"
                    title="Delete"
                    onClick={(e) => onDeleteNoteClick(note)}
                  />
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow>
                <MDBCol>
                  {ReactHtmlParser(note.text)}
                  {note.resolution && (
                    <p className="small green-text">
                      <MDBIcon icon="check" className="mr-2" /> Resolved
                    </p>
                  )}
                </MDBCol>
              </MDBRow>
            )}
          </MDBCardBody>
          <MDBCardFooter>
            <MDBRow>
              <MDBCol>
                <p className="small">
                  <small className="clickable">{authorContent}</small>
                </p>
              </MDBCol>
              <MDBCol>
                <p className="small text-right">
                  <small>{moment(note.date).format("ddd MMM D YYYY h:mma")} UTC</small>
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCardFooter>
        </MDBCard>
      );
    });
  }
  return (
    <MDBCard className="mb-4 workflow-instance-notes">
      <MDBCardHeader color="primary-color lighten-1">Notes</MDBCardHeader>
      <MDBCardBody>
        <div className="current-notes">{notesContent}</div>
        {deleteNoteModal}
        <MDBCard>
          <MDBCardBody>
            <form onSubmit={(e) => onNotesFormSubmit(e)}>
              <HtmlEditor
                defaultText=""
                onValueChange={onNoteInputChange}
                label="Add a note"
                fieldSlug="noteText"
                fieldRequired={true}
                toolbar={{
                  options: ["inline", "list", "link", "remove", "history"],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
              <MDBInput
                type="checkbox"
                label="Mark as resolution"
                onChange={(e) => setNewNoteIsResolution(e.target.checked)}
                id="new-note-is-resolution"
              />
              <MDBBtn type="submit" onClick={(e) => onNotesFormSubmit(e)} className="float-right">
                Save
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCardBody>
    </MDBCard>
  );
};

export default WorkflowMonitoringInstanceNotes;
